import { memo } from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, GridCellProps } from '@progress/kendo-react-grid';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

import { DataTable, DateCell, GridColumn, HeaderCell, TextCell } from 'core/ui';

import { ExamGrid } from 'features/exam';
import { DefaultColumnsState, Lateralities, Organs } from 'features/exam/constants';
import { ExamsGridService } from 'features/exam/services';

import { MergeStepExamMigrateProps } from '../types';

export const MergeStepExamMigrate = memo<MergeStepExamMigrateProps>(
  ({ gridSrcData, srcDataState, dataStateChangeSrc, gridDestData, destDataState, dataStateChangeDest }) => {
    //get the column collection

    //get column definition

    return (
      <StyledDiv>
        <StyleDivMessage>
          <Alert variant={'warning'}>
            <FontAwesomeIcon icon={faTriangleExclamation} /> Both exam list will be merged to one.
          </Alert>
        </StyleDivMessage>
        <StyleDivExamList>
          <StyledExamListTitle>First Donor Exam List:</StyledExamListTitle>
          <StyledDataTable data={gridSrcData}>
            <GridColumn cell={TextCell} field="id" filter="text" title="Exam ID" width="100px" />
            <GridColumn cell={TextCell} field="serviceDescription" filter="text" title="Exam Type" width="100px" />
            <GridColumn
              cell={(props: GridCellProps) => <TextCell {...props} valueTransform={ExamsGridService.formatOrgan} />}
              field="organ"
              filter="text"
              title="Organ"
              width="100px"
            />
            <GridColumn
              cell={(props: GridCellProps) => <TextCell {...props} valueTransform={ExamsGridService.formatLaterality} />}
              field="laterality"
              filter="text"
              title="Laterality"
              width="100px"
            />
            <GridColumn cell={DateCell} field="studyDate" filter="text" title="Exam Time" width="100px" />
            <GridColumn cell={DateCell} field="uploadedDate" filter="text" title="Upload Time" width="100px" />
            <GridColumn cell={TextCell} field="statusType" filter="text" title="Status" width="100px" />
            <GridColumn cell={DateCell} field="requestedDate" filter="text" title="Requested Time" width="100px" />
            <GridColumn cell={TextCell} field="sla" filter="text" title="TAT" width="100px" />
            <GridColumn cell={DateCell} field="completedDate" filter="text" title="Completed Time" width="100px" />
          </StyledDataTable>
        </StyleDivExamList>
        <StyleDivExamList>
          <StyledExamListTitle>Second Donor Exam List:</StyledExamListTitle>
          <StyledDataTable data={gridDestData}>
            <GridColumn cell={TextCell} field="id" filter="text" title="Exam ID" width="100px" />
            <GridColumn cell={TextCell} field="serviceDescription" filter="text" title="Exam Type" width="100px" />
            <GridColumn
              cell={(props: GridCellProps) => <TextCell {...props} valueTransform={ExamsGridService.formatOrgan} />}
              field="organ"
              filter="text"
              title="Organ"
              width="100px"
            />
            <GridColumn
              cell={(props: GridCellProps) => <TextCell {...props} valueTransform={ExamsGridService.formatLaterality} />}
              field="laterality"
              filter="text"
              title="Laterality"
              width="100px"
            />
            <GridColumn cell={DateCell} field="studyDate" filter="text" title="Exam Time" width="100px" />
            <GridColumn cell={DateCell} field="uploadedDate" filter="text" title="Upload Time" width="100px" />
            <GridColumn cell={TextCell} field="statusType" filter="text" title="Status" width="100px" />
            <GridColumn cell={DateCell} field="requestedDate" filter="text" title="Requested Time" width="100px" />
            <GridColumn cell={TextCell} field="sla" filter="text" title="TAT" width="100px" />
            <GridColumn cell={DateCell} field="completedDate" filter="text" title="Completed Time" width="100px" />
          </StyledDataTable>
        </StyleDivExamList>
      </StyledDiv>
    );
  },
);

MergeStepExamMigrate.displayName = 'MergeStepExamMigrate';

const StyledExamListTitle = styled.h3`
  padding-bottom: ${(props) => props.theme.space.spacing50};
  padding-top: ${(props) => props.theme.space.spacing50};
`;

const StyledDiv = styled.div`
  overflow: auto;
  text-size: 11px;
  border-radius: 8px;
  border: 1px solid var(--gray-5, #d9d9d9);
  background: var(--gray-2, #fafafa);
`;

const StyledGrid = styled(Grid)`
  .k-grid-table td {
    border-color: 2666a6;
    border-width: 0;
    border-left-width: 0px;
  }
  .k-grid-table th {
    border: none;
    width: 100%;
    background: #2666a6;
    color: white;
    border-left-width: 0px;
    border-inline-start-width: 0px;
  }
  .k-table-thead {
    background: #2666a6;
    color: white;
  }

  .k-table {
    border: none;
    width: 100%;
  }

  .k-grid .k-table-th {
    border-inline-start-width: 0px;
    border: none;
  }

  .k-grid .k-table-th {
    border-left-width: 0px;
    border-inline-start-width: 0px;
    border: none;
  }

  .k-grid .k-grid-header .k-table-th {
    border-left-width: 0px;
    border-inline-start-width: 0px;
    border: none;
    width: 100%;
  }
  th {
    border-left-width: 0px;
    border-inline-start-width: 0px;
    border: none;
  }

  .k-table .k-grid-header-table .k-table-md {
    width: 100%;
  }
`;

const StyledDataTable = styled(DataTable)`
  .k-table-thead {
    height: 30px;
  }
`;

const StyleDivMessage = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`;

const StyleDivExamList = styled.div`
  margin: 10px;
`;
