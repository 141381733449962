import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { PatientRoutes } from '../routes';
import { PatientAdd } from './PatientAdd';
import { PatientEdit } from './PatientEdit';
import { PatientHome } from './PatientHome';

const USER_ROLES = [UserRoles.CUSTOMER_ADMIN, UserRoles.CUSTOMER, UserRoles.ADMIN];

export const Patient = () => {
  return (
    <Routes>
      <Route
        path={PatientRoutes.HOME}
        element={
          <RouteGuard allowedRoles={USER_ROLES}>
            <PatientHome />
          </RouteGuard>
        }
      />
      <Route
        path={PatientRoutes.ADD}
        element={
          <RouteGuard allowedRoles={USER_ROLES} requireSessionLocation>
            <PatientAdd />
          </RouteGuard>
        }
      />
      <Route
        path={PatientRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={USER_ROLES} requireSessionLocation>
            <PatientEdit isEditMode />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
