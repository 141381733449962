import { FunctionComponent, MouseEventHandler } from 'react';

import { Label as KendoLabel } from '@progress/kendo-react-labels';
import styled, { DefaultTheme, css } from 'styled-components';

import { LabelInformationIcon } from '../LabelInformationIcon';
import { LabelProps } from './LabelProps';

// The Kendo Label component doesn't advertise that it supports attaching an onClick handler.  But it does work (at least for now), so this is going to extend the type definition for us.
declare module '@progress/kendo-react-labels' {
  interface LabelProps {
    onClick?: MouseEventHandler<HTMLLabelElement>;
  }
}

export const Label: FunctionComponent<LabelProps> = ({
  children,
  className,
  description,
  editorDisabled,
  editorId,
  editorValid,
  optional,
  required = false,
  onClick,
  editorRef,
}) => {
  // Kendo's Label component renders an empty span even if nothing is passed to it
  // as a children prop.
  // Since we pass the label in the children prop, we added this condition
  // to check if anything is passed down. We noticed that sometimes the prop
  // is an array, so we added a condition for that case.
  if (!children || (Array.isArray(children) && children.every((childNode) => !childNode))) {
    return null;
  }

  return (
    <StyledLabelBase
      className={className}
      editorDisabled={editorDisabled}
      editorId={editorId}
      editorValid={editorValid}
      editorRef={editorRef}
      optional={optional}
      onClick={onClick}
      $required={required}
    >
      {children}
      {description && <LabelInformationIcon description={description} />}
    </StyledLabelBase>
  );
};

Label.displayName = 'Label';

type StyledElementProps = {
  theme: DefaultTheme;
  editorValid?: LabelProps['editorValid'];
  $required: boolean;
};

const resolveColor = ({ theme, editorValid }: StyledElementProps) => {
  if (!editorValid) return `${theme.colors.textPrimary} !important`;

  return theme.colors.textPrimary;
};

const StyledLabelBase = styled(KendoLabel)<{ $required: boolean }>`
  display: flex;
  align-items: center;
  color: ${resolveColor};
  user-select: none;

  &.k-text-disabled {
    filter: none;
    opacity: 1;
  }

  && {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
  }

  & .k-label-optional {
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    margin-left: ${({ theme }) => theme.space.spacing10};
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  ${({ $required }) =>
    $required &&
    css`
      &::before {
        content: '*';
        color: ${({ theme }) => theme.colors.error};
        margin-right: ${({ theme }) => theme.space.spacing10};
      }
    `}
`;
