import { ComponentType, memo } from 'react';

import { ConditionalRenderProps } from './ConditionalRenderProps';

export function withConditionalRender<TBaseProps extends object>(OriginalComponent: ComponentType<TBaseProps>) {
  const newComponent = memo<TBaseProps & ConditionalRenderProps>(({ show, ...rest }) => {
    return show ? <OriginalComponent {...(rest as TBaseProps)} /> : null; // Using type assertion because it's quite difficult to add and then remove the `show` prop from `rest` while avoiding TS errors.
  });

  newComponent.displayName = `withConditionalRender(${OriginalComponent.displayName ?? ''})`;

  return newComponent;
}
