import { datadogLogs } from '@datadog/browser-logs';

import { isPromise } from './isPromise';

export class BatchQueue<TJob extends object> {
  static [Symbol.toStringTag]() {
    return 'BatchQueue';
  }

  private _runLock = false;

  private _timeoutId: number | null = null;

  private _jobs: TJob[] = [];

  private _runner: (jobs: TJob[]) => unknown;

  constructor(options: BatchQueueOptions<TJob>) {
    this.destroy = this.destroy.bind(this);
    this.clear = this.clear.bind(this);
    this.enqueue = this.enqueue.bind(this);
    this.run = this.run.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);

    this._runner = options.run;
  }

  public destroy() {
    this._jobs = [];

    this._runner = () => {
      throw new Error('Cannot execute run callback because the BatchQueue has been destroyed.');
    };

    if (this._timeoutId != null) {
      window.clearTimeout(this._timeoutId);
    }
  }

  public clear() {
    this._jobs = [];
  }

  public enqueue(job: TJob | TJob[]) {
    if (Array.isArray(job)) {
      this._jobs.push(...job);
    } else {
      this._jobs.push(job);
    }
  }

  public run() {
    if (this._runLock) return;

    this._runLock = true;
    this._timeoutId = window.setTimeout(this.handleTimeout, 1000);
  }

  private async handleTimeout() {
    this._timeoutId = null;

    if (this._jobs.length === 0) {
      this._runLock = false;
      return;
    }

    const attemptedJobs: TJob[] = this._jobs;
    this._jobs = [];

    try {
      const result = this._runner(attemptedJobs);

      if (isPromise(result)) {
        await result;
      }
    } catch (ex) {
      datadogLogs.logger.error('Error executing BatchQueue runner.', undefined, ex as Error | undefined);
      this._jobs = [...attemptedJobs, ...this._jobs];
      throw ex;
    } finally {
      this._timeoutId = window.setTimeout(this.handleTimeout, 1000);
    }
  }
}

export type BatchQueueOptions<TJob extends object> = {
  /** Name of the property that contains a uniquely identifying key. */
  key: keyof TJob;
  run: (jobs: TJob[]) => unknown;
};
