import { FunctionComponent, memo, useState } from 'react';

import styled from 'styled-components';
import { ExamForm } from './ExamForm';
import { HippaLogs } from 'features/share/fragments';
import { ShareGrid } from 'features/patient/fragments';
import { TabButton, TabsContainer, Window } from 'core/ui';
import { useEvent } from 'core/hooks';

export const ExamEditWindow: FunctionComponent<{
  examId: number;
  patientId: number;
  show: boolean;
  onExamFormSubmit: () => void;
  onDeleteShareClick: (studyShareId: number) => void;
  onClosed: () => void;
}> = memo(({ onClosed, examId, patientId, onExamFormSubmit, onDeleteShareClick }) => {
  const [tab, setTab] = useState<'EXAM_INFO' | 'HIPPA_LOGS' | 'SHARES_OUT'>('EXAM_INFO');

  const handleExamInfoClick = useEvent(() => {
    const value = 'EXAM_INFO';
    setTab(value);
  });

  const handleHippaLogClick = useEvent(() => {
    const value = 'HIPPA_LOGS';
    setTab(value);
  });

  const handleSharesOutClick = useEvent(() => {
    const value = 'SHARES_OUT';
    setTab(value);
  });

  const mainContent = () => {
    switch (tab) {
      case 'EXAM_INFO':
        return <ExamForm examId={examId} onSubmit={onExamFormSubmit} />;
      case 'HIPPA_LOGS':
        return (
          <StyledTableContainer>
            <HippaLogs examId={examId} />
          </StyledTableContainer>
        );
      case 'SHARES_OUT':
        return (
          <StyledTableContainer>
            <ShareGrid patientId={patientId} examId={examId} onDeleteShareClick={onDeleteShareClick}></ShareGrid>
          </StyledTableContainer>
        );
    }
  };

  return (
    <Window modal title="Edit Exam" onClose={onClosed} initialHeight={425} initialWidth={975}>
      <TabsContainer>
        <TabButton type="button" selected={tab === 'EXAM_INFO'} roundedCorners="left" onClick={handleExamInfoClick}>
          Exams
        </TabButton>
        <TabButton type="button" selected={tab === 'HIPPA_LOGS'} roundedCorners="left" onClick={handleHippaLogClick}>
          HIPPA Logs
        </TabButton>
        <TabButton type="button" selected={tab === 'SHARES_OUT'} roundedCorners="right" onClick={handleSharesOutClick}>
          Shares Out
        </TabButton>
      </TabsContainer>
      <StyledMainContentContainer>{mainContent()}</StyledMainContentContainer>
    </Window>
  );
});

ExamEditWindow.displayName = 'PatientEditWindow';

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 90%;
  padding-top: 10px;
  margin: 0 auto;
  overflow-y: auto;
`;

const StyledTableContainer = styled.div`
  width: 95%;
  height: 100%;
`;
