import { FunctionComponent, useEffect, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { styled } from 'styled-components';
import { FileViewer } from './FileViewer';
import { FileModel } from 'models';
import { useApiClient } from 'features/api';
import Card from 'react-bootstrap/Card';

export const ViewFileWindow: FunctionComponent<{ fileId: number; onClose: () => void }> = ({ fileId, onClose, ...restProps }) => {
  const apiClient = useApiClient();
  const [currentFile, setCurrentFile] = useState<FileModel>({} as FileModel);

  // use effect set file
  useEffect(() => {
    const fetchFile = async () => {
      const file = await apiClient.filesClient.getById(fileId, 'msal-required');
      setCurrentFile(file);
    };
    fetchFile();
  }, []);

  return (
    <StyledWindow title="View Attachments" onClose={onClose} {...restProps} height={900} width={900}>
      <StyledWindowCard>
        <Card.Header>{currentFile?.fileName}</Card.Header>
        <Card.Body>
          <FileViewer file={currentFile} overrideMultiMonitor />
        </Card.Body>
      </StyledWindowCard>
    </StyledWindow>
  );
};

const StyledWindow = styled(Window)`
  .k-window-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const StyledWindowCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;
`;
