import { FunctionComponent, memo, useState } from 'react';

import { Window } from 'core/ui';
import { PatientAgeRangeModel, PatientModel } from 'models';

import { SubmitHandler } from 'core/forms';
import { Card } from 'core/ui';
import { PatientEditService } from 'features/patient/services';
import { PatientFormValues } from 'features/patient/types';
import { PatientFormContent } from 'features/patient/fragments';

export const PatientEditWindow: FunctionComponent<{
  isOpo: boolean | undefined;
  ageRanges: PatientAgeRangeModel[] | undefined;
  handleSubmit: SubmitHandler<PatientFormValues> | undefined;
  patient?: PatientModel | undefined | null;
  show: boolean;
  onClosed: () => void;
  isRequestMode?: boolean;
  showRequiredError?: boolean;
  isEditMode?: boolean;
  isSubmitting?: boolean;
}> = memo(({ isOpo, isRequestMode, ageRanges, onClosed, handleSubmit, patient, show, showRequiredError, isEditMode = true, isSubmitting }) => {
  return (
    <>
      {show && (
        <Window
          modal
          title={`${isEditMode ? 'Edit' : 'Create'} ${isOpo ? 'Donor' : 'Patient'}`}
          onClose={onClosed}
          initialHeight={isOpo ? 460 : 425}
          initialWidth={1000}
        >
          <Card>
            <PatientFormContent
              ageRanges={ageRanges}
              isEditMode={isEditMode}
              isFormDisabled={false}
              onSubmit={handleSubmit}
              initialValues={PatientEditService.copyModelToForm(patient ?? ({} as PatientModel))}
              isOpo={isOpo}
              isRequestMode={isRequestMode}
              showRequiredError={showRequiredError}
              isSubmitting={isSubmitting}
            ></PatientFormContent>
          </Card>
        </Window>
      )}
    </>
  );
});

PatientEditWindow.displayName = 'PatientEditWindow';
