import { LocationModel, SettingOverrideModel } from 'models';

import { FileService } from 'features/file';

import { InitialValues } from '../constants';
import { ContactTabFormValues, GeneralTabFormValues, IntegrationTabFormValues, NotificationsTabFormValues, TatTabFormValues } from '../types';

async function copyModelToForms(location: LocationModel): Promise<{
  generalTabForm: GeneralTabFormValues;
  contactTabForm: ContactTabFormValues;
  integrationTabForm: IntegrationTabFormValues;
  notificationsTabForm: NotificationsTabFormValues;
  tatTabForm: TatTabFormValues;
  settingOverrides: SettingOverrideModel[];
}> {
  const logo = FileService.restoreDataUriToFile(location.logo, 'Logo');

  return {
    generalTabForm: {
      locationType: location.locationType,
      logo: logo == null ? [] : [await FileService.fileToKendoUpload(logo)],
      name: location.name,
      code: location.code,
      user: location.user ?? '',
      contractType: location.contractType ?? '',
      contractStart: location.contractStart ?? '',
      contractStop: location.contractStop ?? '',
      serial: location.serial ?? '',
      comments: location.comments ?? '',
      overreadInstruction: location.overreadInstruction ?? '',
      timeZone: location.timeZone ?? '',
      accountId: location.accountId,
      defaultPhysician_Id: location.defaultPhysician_Id,
      active: location.active,
      isTestLocation: location.isTestLocation ?? false,
    },
    contactTabForm: {
      address1: location.address1 ?? '',
      address2: location.address2 ?? '',
      state: location.state ?? '',
      city: location.city ?? '',
      zip: location.zip ?? '',
      phone: location.phone ?? '',
      phone2: location.phone2 ?? '',
      fax: location.fax ?? '',
      fax2: location.fax2 ?? '',
      email: location.email ?? '',
      email2: location.email2 ?? '',
      mailingAddress: location.mailingAddress ?? '',
      siteContact: location.siteContact ?? '',
      contactTitle: location.contactTitle ?? '',
    },
    integrationTabForm: {
      facilityId: location.facilityId ?? '',
      aeTitle: location.aeTitle ?? '',
      uploadURL: location.uploadURL ?? '',
      integrations: location.integrations,
      defaultViewer: location.defaultViewer ?? '',
      autoComplete: location.autoComplete,
      customerAlias: location.customerAlias ?? '',
      examMatchService: { id: location.examMatchService, name: location.examMatchService ?? null },
    },
    notificationsTabForm: {
      overreadResultsMethod: location.overreadResultsMethod ?? '',
      emailConfirmation: location.emailConfirmation,
      emailConfirmationPDF: location.emailConfirmationPDF,
      autoSendFinalReportFax: location.autoSendFinalReportFax,
      autoSendFinalReportEmail: location.autoSendFinalReportEmail,
      autoSendFinalReportIntegration: location.autoSendFinalReportIntegration,
      emailNotificationSTATExam: location.emailNotificationSTATExam,
      textNotificationSTATExam: location.textNotificationSTATExam,
    },
    tatTabForm: {
      defaultTATDropdown: location.defaultTATDropdown,
      taTs: location.taTs,
      transmissionSLADropdown: location.transmissionSLADropdown,
      abnormalTransmissionSLADropdown: location.abnormalTransmissionSLADropdown,
      allowLocationTATAdminOverride: location.allowLocationTATAdminOverride,
      services: location.services,
    },
    settingOverrides: location.settingOverrides,
  };
}

async function copyFormsToModel(
  initialState: Partial<LocationModel> | null | undefined,
  generalTabForm: GeneralTabFormValues,
  contactTabForm: ContactTabFormValues,
  integrationTabForm: IntegrationTabFormValues,
  notificationsTabForm: NotificationsTabFormValues,
  tatTabForm: TatTabFormValues,
  settingOverrides: SettingOverrideModel[],
): Promise<LocationModel> {
  if (generalTabForm.code == null) throw new Error('code cannot be null.');

  const encodedLogo =
    generalTabForm.logo != null && generalTabForm.logo.length > 0 && generalTabForm.logo[0].getRawFile != null
      ? await FileService.blobToDataUri(generalTabForm.logo[0].getRawFile())
      : null;

  if (initialState == null) {
    return {
      ...InitialValues.nonFormFields,
      ...contactTabForm,
      ...integrationTabForm,
      ...notificationsTabForm,
      ...tatTabForm,
      ...generalTabForm,
      locationType: generalTabForm.locationType,
      code: generalTabForm.code,
      logo: encodedLogo,
      settingOverrides,
      examMatchService: integrationTabForm.examMatchService.id,
    };
  }

  return {
    ...InitialValues.nonFormFields,
    ...initialState,
    ...contactTabForm,
    ...integrationTabForm,
    ...notificationsTabForm,
    ...tatTabForm,
    ...generalTabForm,
    locationType: generalTabForm.locationType,
    code: generalTabForm.code,
    logo: encodedLogo,
    settingOverrides,
    examMatchService: integrationTabForm.examMatchService.id,
  };
}

export const LocationEditService = {
  copyModelToForms,
  copyFormsToModel,
};
