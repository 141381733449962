import { useQuery } from '@tanstack/react-query';

import { PatientAgeRangeModel } from 'models';

import { apiClient } from '../fragments/ApiClientProvider';
import { UseQueryReadResult } from '../types';

export function useQueryPatientAgeRanges(): UseQueryReadResult<PatientAgeRangeModel[], true> {
  const ageRangesQuery = useQuery({
    queryKey: ['patient-age-ranges'],
    queryFn: apiClient.patientClient.getPatientAgeRange,
    staleTime: Infinity,
  });

  return ageRangesQuery;
}
