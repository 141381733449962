import dayjs from 'dayjs';

import { ExamModel, PatientModel, ServiceModel } from 'models';

import { equalsInsensitive, hasText } from 'core/utils';

import { ParsedDicomMetadata } from 'features/file/types/ParsedDicomMetadata';

import { ExamFormValues, PatientFormValues, UploadGroup } from '../types';

function patientDicomToForm(dicomData: ParsedDicomMetadata | null): PatientFormValues {
  if (dicomData == null) {
    return {
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      patientNumber: '',
      unosID: '',
    };
  }

  return {
    firstName: dicomData.PatientName[1] ?? '',
    lastName: dicomData.PatientName[0] ?? '',
    dob: dicomData.PatientBirthDate ?? '',
    gender: dicomData.PatientSex ?? '',
    patientNumber: dicomData.PatientID ?? '',
    unosID: '',
  };
}

function patientModelToForm(model: PatientModel | null): PatientFormValues {
  return {
    firstName: model?.firstName ?? '',
    lastName: model?.lastName ?? '',
    dob: model?.dob ?? '',
    gender: model?.gender ?? '',
    patientNumber: model?.patientNumber ?? '',
    unosID: model?.unosID ?? '',
  };
}

function examDicomToForm(dicomData: ParsedDicomMetadata | null, allServices: ServiceModel[]): ExamFormValues {
  if (dicomData == null) {
    return {
      description: '',
      service: null,
      customerUID: '',
      studyDate: '',
      studyTime: '',
      notes: '',
    };
  }

  return {
    description: dicomData.StudyDescription ?? '',
    service: allServices.find((s) => equalsInsensitive(s.description, dicomData.Modality)) ?? null,
    customerUID: dicomData.AccessionNumber ?? '',
    studyDate: dicomData.StudyDate ?? '',
    studyTime: dicomData.StudyTime ?? '',
    notes: '',
  };
}

function examModelToForm(model: ExamModel | null, allServices: ServiceModel[]): ExamFormValues {
  if (model == null) {
    return {
      description: '',
      service: null,
      customerUID: '',
      studyDate: '',
      studyTime: '',
      notes: '',
    };
  }

  return {
    description: model.description ?? '',
    service: allServices.find((s) => equalsInsensitive(s.description, model.service?.description)) ?? null,
    customerUID: model.customerUID ?? '',
    studyDate: model.studyDate == null ? '' : dayjs(model.studyDate, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY'),
    studyTime: model.studyTime == null ? '' : dayjs(model.studyTime, 'HH:mm:ss').format('HH:mm'),
    notes: model.notes ?? '',
  };
}

function findUploadGroupIndexByFileId(fileId: string, uploadGroups: UploadGroup[]): number {
  for (let uploadGroupIndex = 0; uploadGroupIndex < uploadGroups.length; uploadGroupIndex++) {
    const uploadGroup = uploadGroups[uploadGroupIndex];

    for (const file of uploadGroup.files) {
      if (file.fileId === fileId) {
        return uploadGroupIndex;
      }
    }

    for (const attachment of uploadGroup.attachments) {
      if (attachment.fileId === fileId) {
        return uploadGroups.indexOf(uploadGroup);
      }
    }
  }

  throw new Error(`Could not find uploadGroup for fileId: ${fileId}.`);
}

function copyPatientFormToModel(form: PatientFormValues, locationId: number, originalModel: PatientModel | null): PatientModel {
  if (originalModel == null) {
    return {
      id: 0,
      active: true,
      caseID: null,
      height: null,
      weight: null,
      crossClampDateTime: null,
      notes: null,
      hospital: null,
      locationType: null,
      htn: null,
      htnYrs: null,
      htnCompliant: null,
      diabetes: null,
      diabetesYrs: null,
      diabetesCompliant: null,
      peakCreatinine: null,
      peakCreatinineYrs: null,
      peakCreatinineCompliant: null,
      obesityIndication: null,
      organ: null,
      organAppearanceIndication: null,
      proteinuria: null,
      ageIndication: null,
      etoh: null,
      ageRange: null,
      location: null,
      location_id: locationId,
      ageRange_id: null,
      ...form,
    };
  }

  return {
    ...originalModel,
    ...form,
  };
}

function copyExamFormToModel(
  form: ExamFormValues,
  locationId: number,
  patientId: number | null,
  suid: string | null,
  originalModel: ExamModel | null,
): ExamModel {
  if (form.service == null) throw new Error('Cannot copy form values to an ExamModel without a service.');

  if (originalModel == null) {
    return {
      id: 0,
      otherIndications: null,
      files: [],
      thumbnailPatientFile: null,
      reads: null,
      coordinatorId: null,
      dynamicFormFields: [],
      priorMatch: false,
      source: 'WEB',
      location_Id: locationId,
      patient_Id: patientId,
      description: form.description,
      serviceId: form.service.id,
      customerUID: form.customerUID,
      studyDate: form.studyDate,
      studyTime: form.studyTime,
      notes: form.notes,
      ...(hasText(suid) ? { suid: suid } : {}),
    };
  }

  return {
    ...originalModel,
    location_Id: locationId,
    patient_Id: patientId,
    description: form.description,
    serviceId: form.service.id,
    customerUID: form.customerUID,
    studyDate: form.studyDate,
    studyTime: form.studyTime,
    notes: form.notes,
    ...(hasText(suid) ? { suid: suid } : {}),
  };
}

function validatePatientForm(values: PatientFormValues, isOpo: boolean) {
  // Note: This function duplicates the validation logic in the PatientUploadAccordionItemForm component.  This is necessary
  // because we need to validate the form without rendering the component.  Ideally we could utilize the same validation logic
  // for both the actual form and this function.  However that would likely require us to adopt a form schema library like Zod.

  if (!hasText(values.firstName)) return false;
  if (!hasText(values.lastName)) return false;
  if (!hasText(values.dob) || !dayjs(values.dob).isValid()) return false;
  if (isOpo && !hasText(values.unosID)) return false;

  return true;
}

function validateExamForm(values: ExamFormValues) {
  // Note: This function duplicates the validation logic in the ExamUploadAccordionItem component.  This is necessary
  // because we need to validate the form without rendering the component.  Ideally we could utilize the same validation logic
  // for both the actual form and this function.  However that would likely require us to adopt a form schema library like Zod.

  if (values.service == null) return false;
  if (!hasText(values.studyDate) || !dayjs(values.studyDate).isValid()) return false;
  if (!hasText(values.studyTime) || !dayjs(values.studyTime, 'HH:mm').isValid()) return false;

  return true;
}

export const ExamUploadService = {
  patientDicomToForm,
  patientModelToForm,
  examDicomToForm,
  examModelToForm,
  findUploadGroupIndexByFileId,
  copyPatientFormToModel,
  copyExamFormToModel,
  validatePatientForm,
  validateExamForm,
};
