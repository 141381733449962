import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PatientModel } from 'models';

import { apiClient } from '../fragments/ApiClientProvider';
import { UseQueryReadWriteResult } from '../types';

export function useQueryPatient(patientId: number, required?: true): UseQueryReadWriteResult<PatientModel, true>;
export function useQueryPatient(patientId: number | null | undefined, required: false): UseQueryReadWriteResult<PatientModel, false>;
export function useQueryPatient(patientId: number | null | undefined, required = true): UseQueryReadWriteResult<PatientModel, false> {
  if (patientId == null && required) throw new Error('Cannot fetch patient without an Id when required was set to true.');

  const queryClient = useQueryClient();

  const patientQuery = useQuery({
    queryKey: ['patient', patientId],
    queryFn: () => (patientId == null ? Promise.resolve(null) : apiClient.patientClient.getPatient(patientId, 'msal-required')),
    staleTime: 1000 * 60 * 5,
  });

  const patientMutation = useMutation({
    mutationFn: async (patient: PatientModel) => {
      const newPatient = {
        ...patient,
      };

      if (newPatient.id === 0) {
        newPatient.id = await apiClient.patientClient.createPatient(newPatient);
      } else {
        await apiClient.patientClient.updatePatient(newPatient);
      }

      queryClient.setQueryData(['patient', newPatient.id], newPatient);

      return newPatient;
    },
  });

  return [patientQuery, patientMutation];
}
