import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ExamModel } from 'models';
import { FieldContainer, useFieldValueChangeHandler } from 'core/forms';
import { NotificationsService } from 'core/notifications';
import {
  Button,
  ButtonVariants,
  ComponentSizes,
  Icon,
  InputField,
  MASKED_DATETIME_INPUT_MODES,
  MaskedDateTimeInputField,
  styled,
  SwitchField,
  TextAreaField,
  TextAreaProps,
} from 'core/ui';
import { apiClient } from 'features/api';
import { ExamFormValues } from 'features/exam/types';
import { ExamFormService } from 'features/exam/services';
import { useEvent } from 'core/hooks';
import { Spinner } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

export const ExamForm = ({ examId, onSubmit }: { examId: number; onSubmit: () => void }) => {
  const [examForm, setExamForm] = useState<ExamFormValues>();
  const [exam, setExam] = useState<ExamModel>();
  const [isLoading, setIsLoading] = useState(false);
  const rhfContext = useForm<ExamFormValues>({ defaultValues: examForm });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const fetchExam = useEvent(async () => {
    try {
      setIsLoading(true);
      const exam = await apiClient.exams.getExamById(examId);
      setExamForm(ExamFormService.initializeExamFormValues(exam));
      setExam(exam);
      reset(ExamFormService.initializeExamFormValues(exam));
    } catch (_) {
      NotificationsService.displayError('Failed to fetch exam');
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchExam();
  }, [examId, fetchExam]);

  const onChange = useEvent(() => {
    setExamForm(rhfContext.getValues());
  });

  const handleFormChange = useFieldValueChangeHandler(onChange, rhfContext);

  const handleSubmit = useEvent<SubmitHandler<ExamFormValues>>(async (data) => {
    if (!exam) {
      throw new Error('Exam not found');
    }
    try {
      const updatedExam = ExamFormService.mapFormValuesToExamModel(exam, data);
      await apiClient.exams.updateExam(updatedExam);
      NotificationsService.displaySuccess('Exam updated successfully');
      onSubmit();
    } catch (_) {
      NotificationsService.displayError('Failed to update exam');
    }
  });

  return (
    <>
      <FormProvider {...rhfContext}>
        {isLoading ? (
          <StyledCenteredSpinner size="sm" />
        ) : (
          <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
            <StyledFormDiv>
              <StyledFieldContainer>
                <InputField name="description" label="Description" onChange={handleFormChange} />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <InputField name="customerUid" label="Accession" />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <MaskedDateTimeInputField mode={MASKED_DATETIME_INPUT_MODES.DATE} name="studyDate" label="Exam Date" onChange={handleFormChange} />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <MaskedDateTimeInputField mode={MASKED_DATETIME_INPUT_MODES.TIME} name="studyTime" label="Exam Time" onChange={handleFormChange} />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <SwitchField name="share" label="Share" onChange={handleFormChange} />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <SwitchField name="active" label="Active" onChange={handleFormChange} />
              </StyledFieldContainer>
              <StyledFieldContainer>
                <LargeTextAreaField name="notes" label="Notes" onChange={handleFormChange} />
              </StyledFieldContainer>
            </StyledFormDiv>
            <div>
              <StyledButton variant={ButtonVariants.PRIMARY} size={ComponentSizes.LARGE} type="submit" disabled={isSubmitting} title="Save">
                {isSubmitting && <Icon icon={faSpinner} spin={isSubmitting}></Icon>}Save
              </StyledButton>
            </div>
          </StyledForm>
        )}
      </FormProvider>
    </>
  );
};

const LargeTextAreaField = (props: TextAreaProps & { name: string }) => {
  return <TextAreaField {...props} rows={5} />;
};

const StyledForm = styled.form`
  display: grid;
  max-width: 90vw;
  > * {
    min-width: 300px;
  }
`;
const baseColumnWidth = '220px';
const gapSize = '1rem';

const StyledFormDiv = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: ${gapSize};

  > * {
    flex: 0 1 ${baseColumnWidth};
    min-width: ${baseColumnWidth};
    max-width: ${baseColumnWidth};
  }

  > :last-child {
    flex: 0 1 calc(3 * ${baseColumnWidth} + 2 * ${gapSize});
    max-width: calc(3 * ${baseColumnWidth} + 2 * ${gapSize});
  }
  padding: 5px;
  border: ${({ theme }) => `1px solid ${theme.colors.palette.grayscale[3]}`};
`;

const StyledFieldContainer = styled(FieldContainer)`
  min-height: 0;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;

const StyledCenteredSpinner = styled(Spinner)`
  margin: auto;
`;
