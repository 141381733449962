import { RouteGuard, UserRoles } from 'features/auth';
import { Route, Routes } from 'react-router-dom';
import { FileViewerPage } from 'features/file/fragments';
import { FileRoutes } from 'features/file/routes';

const FILE_VIEWER_ROLES = [UserRoles.ADMIN, UserRoles.SUPPORT, UserRoles.PHYSICIAN, UserRoles.CUSTOMER_ADMIN, UserRoles.EXAM_SHARE, UserRoles.CUSTOMER];

export const FileViewerHome = () => {
  return (
    <Routes>
      <Route
        path={FileRoutes.HOME}
        element={
          <RouteGuard allowedRoles={FILE_VIEWER_ROLES}>
            <FileViewerPage />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
