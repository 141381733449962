import { memo } from 'react';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FlatIconButton } from '../Button';
import { Icon } from '../Icon';
import { FileInputFileItemProps } from './FileInputFileItemProps';

export const FileInputDefaultFileItem = memo<FileInputFileItemProps>(({ file, onRemove }) => {
  return (
    <StyledComponentDiv>
      <StyledFileNameSpan>{file.file.name}</StyledFileNameSpan>
      <FlatIconButton onClick={onRemove}>
        <Icon icon={faXmark} fixedWidth block />
      </FlatIconButton>
    </StyledComponentDiv>
  );
});

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: minmax(200px, 1fr) min-content;
`;

const StyledFileNameSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
