import { FunctionComponent, MouseEventHandler, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { CompositeFilterDescriptor, DataResult, DataSourceRequestState, FilterDescriptor, State } from '@progress/kendo-data-query';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { Stepper } from '@progress/kendo-react-layout';
import debounce from 'awesome-debounce-promise';
import { compare } from 'fast-json-patch';
import styled from 'styled-components';

import { MergeDonorModel } from 'models/MergeDonorModel';
import { PatientModel } from 'models/PatientModel';

import { addFiltersToDataState, getSearchFilter } from 'core/api/services/kendoMultiColumnFilter';
import { useAsyncCallback, useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, ComponentSizes, Dialog, GridDataStateChangeEvent, faUser } from 'core/ui';
import { Card, Window } from 'core/ui';

import { useApiClient } from 'features/api';
import { ExamGridDataStateChangeEvent } from 'features/exam';
import { useSessionLocation } from 'features/location';

import { MergePatientDirectModal, MergePatientWithSelectModal } from '.';
import { MergePatientValueSelectContext } from '../contexts';
import { PatientGridService } from '../services';
import { MergePatientProps, StepsType } from '../types';

const stateTake = 20;

const defaultFilter = (donorId: number): CompositeFilterDescriptor => ({
  logic: 'and',
  filters: [
    {
      field: 'patientId',
      operator: 'eq',
      value: donorId,
    },
    {
      field: 'active',
      operator: 'eq',
      value: true,
    },
  ],
});
const defaultDataState = (filter: CompositeFilterDescriptor): State => ({
  filter,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: stateTake,
});

const dateFormat = 'MM/DD/YYYY';

const calculateAge = (dob: string) => {
  const date = new Date(dob);
  const diffMs = Date.now() - date.getTime();
  const ageDt = new Date(diffMs);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
};

const selectDonorDefaultFilter = (donorId: number, locationId: number | null, source: PatientModel | null | undefined): CompositeFilterDescriptor => {
  const filterDescriptor = {
    logic: 'and',
    filters: [
      {
        field: 'id',
        operator: 'neq',
        value: donorId,
      } as FilterDescriptor,
      {
        field: 'active',
        operator: 'eq',
        value: true,
      } as FilterDescriptor,
    ],
  } as CompositeFilterDescriptor;

  if (locationId) {
    filterDescriptor.filters.push({
      field: 'Location_Id',
      operator: 'eq',
      value: locationId,
    });
  }

  return filterDescriptor;
};

const selectDonorDefaultDataState = (filter: CompositeFilterDescriptor): State => ({
  filter,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: stateTake,
});

export const MergePatientModal = memo<MergePatientProps>(
  ({ isOPO = false, showDonorGrid = false, srcDonorId, destDonorId, toggleDialog, onMerge, targetPatient }) => {
    const mergeSelectSteps = [
      { label: `Select ${isOPO ? 'Donor' : 'Patient'}`, isValid: undefined },
      { label: `Merge ${isOPO ? 'Donor' : 'Patient'}`, isValid: undefined },
      { label: 'Merge Exam', isValid: undefined },
      { label: 'Confirm Merge', isValid: undefined },
      { label: 'Merge Result', isValid: undefined },
    ];

    const mergeDirectSteps = [
      { label: `Merge ${isOPO ? 'Donor' : 'Patient'}`, isValid: undefined },
      { label: 'Merge Exam', isValid: undefined },
      { label: 'Confirm Merge', isValid: undefined },
      { label: 'Merge Result', isValid: undefined },
    ];

    const apiClient = useApiClient();

    const [srcDataState, setSrcDataState] = useState<State>(defaultDataState(defaultFilter(srcDonorId)));
    const [gridSrcData, setGridSrcData] = useState<DataResult | null>(null);

    const [destDataState, setDestDataState] = useState<State>(defaultDataState(defaultFilter(destDonorId)));
    const [gridDestData, setGridDestData] = useState<DataResult | null>(null);

    const [srcDonorIdParam, setSrcDonorIdParam] = useState(0);

    const [selectedSourceFields, setSelectedSourceFields] = useState<Partial<MergeDonorModel> | null>(null);
    const [selectedDestFields, setSelectedDestFields] = useState<Partial<MergeDonorModel>>({});

    const { sessionLocation } = useSessionLocation(true);

    const createMergeDonorObj = useCallback(
      (source: PatientModel | Partial<PatientModel> | MergeDonorModel) =>
        ({
          caseID: source.caseID,
          crossClampDateTime: source.crossClampDateTime,
          dob: source.dob,
          firstName: source.firstName,
          gender: source.gender,
          height: source.height,
          hospital: source.hospital,
          lastName: source.lastName,
          patientNumber: source.patientNumber,
          unosID: source.unosID,
          weight: source.weight,
          ageRange: null,
        }) as MergeDonorModel,
      [],
    );

    const [fetchPatientDataSrc] = useAsyncCallback(async () => {
      const response = await apiClient.patientClient.getPatient(srcDonorId);
      setSrcPatient(response);
      setSrcSelectAll(true);
      setSelectedSourceFields(createMergeDonorObj(response));
    });

    const [fetchPatientDataDest] = useAsyncCallback(async () => {
      const response = await apiClient.patientClient.getPatient(destDonorId);
      setDestPatient(response);
      setDestSelectAll(false);
    });

    const initializeModal = useEvent(async () => {
      if (srcDonorId) {
        fetchPatientDataSrc();
      }
      if (destDonorId) {
        fetchPatientDataDest();
      }
    });

    useEffect(() => {
      if (!showDonorGrid) {
        setSrcDonorIdParam(srcDonorId);
        initializeModal();
      }
    }, [initializeModal, showDonorGrid, srcDonorId]);

    const [fetchGridDataSrc] = useAsyncCallback(async () => {
      const response = await apiClient.exams.getAllForKendoGrid(srcDataState);
      setGridSrcData(response);
    });

    const [fetchGridDataDest] = useAsyncCallback(async () => {
      const response = await apiClient.exams.getAllForKendoGrid(destDataState);
      setGridDestData(response);
    });

    const initialize = useEvent(async () => {
      fetchGridDataSrc();
      fetchGridDataDest();
    });

    useEffect(() => {
      initialize();
    }, [initialize]);

    const [srcPatient, setSrcPatient] = useState<MergeDonorModel | null>(null);
    const [destPatient, setDestPatient] = useState<MergeDonorModel | null>(null);

    const [srcSelectAll, setSrcSelectAll] = useState<boolean>(false);
    const [destSelectAll, setDestSelectAll] = useState<boolean>(false);

    const [isMergeSuccessful, setIsMergeSuccessful] = useState<boolean | null>(null);

    const stepConfig = showDonorGrid ? mergeSelectSteps : mergeDirectSteps;
    const [step, setStep] = useState<number>(0);
    const [steps, setSteps] = useState<StepsType[]>(stepConfig);

    const selectedFields = useMemo(() => {
      return {
        ...selectedSourceFields,
        ...selectedDestFields,
      };
    }, [selectedSourceFields, selectedDestFields]) as MergeDonorModel;

    const lastStepIndex = steps.length - 1;
    const isMergeStep = lastStepIndex - 1 === step;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid = steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;

    const onPrevClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        event.preventDefault();
        setStep(() => Math.max(step - 1, 0));
      },
      [step, setStep],
    );

    const [selectedDonors, setSelectedDonors] = useState({});
    const formatSelectedDonorsForMerge = useEvent(() => {
      return Object.entries(selectedDonors)
        .filter(([, value]) => value === true)
        .map(([key]) => Number(key));
    });

    const onStepSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
      async (event) => {
        const { isValid } = event as unknown as { isValid: boolean }; // TODO: This is confusing.  How do we know that this is safe?  Where does the "isValid" property come from?  Because that does not appear in the React.MouseEvent<HTMLButtonElement> type definition.

        const currentSteps = steps.map((currentStep, index) => ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid,
        }));

        if (showDonorGrid && step === 0 && formatSelectedDonorsForMerge().length === 0) {
          NotificationsService.displayError('Select a donor before moving to the next step.');
          return;
        }

        setSteps(currentSteps);
        setStep(() => Math.min(step + 1, lastStepIndex));

        if (showDonorGrid && lastStepIndex - 3 === 1) {
          const src = await apiClient.patientClient.getPatient(formatSelectedDonorsForMerge()[0]);
          const dest = await apiClient.patientClient.getPatient(destDonorId);
          setSrcPatient(src);
          setDestPatient(dest);
          setSrcDonorIdParam(formatSelectedDonorsForMerge()[0]);

          const response1 = await apiClient.exams.getAllForKendoGrid(defaultDataState(defaultFilter(formatSelectedDonorsForMerge()[0])));
          setGridSrcData(response1);
        }

        if (isMergeStep && isPreviousStepsValid) {
          if (destPatient !== null) {
            const patientDiff = compare(createMergeDonorObj(destPatient), selectedFields);
            try {
              await apiClient.patientClient.mergePatient(srcDonorIdParam, destDonorId, patientDiff);
              setIsMergeSuccessful(true);
              if (onMerge) {
                onMerge();
              }
            } catch (error) {
              setIsMergeSuccessful(false);
            }
          }
        } else if (isLastStep && isPreviousStepsValid) {
          toggleDialog();
        }
      },
      [
        apiClient.exams,
        apiClient.patientClient,
        createMergeDonorObj,
        destDonorId,
        destPatient,
        formatSelectedDonorsForMerge,
        isLastStep,
        isMergeStep,
        isPreviousStepsValid,
        lastStepIndex,
        onMerge,
        selectedFields,
        showDonorGrid,
        srcDonorIdParam,
        step,
        steps,
        toggleDialog,
      ],
    );

    const selectAll = useEvent((event: CheckboxChangeEvent, sourceOrDest: 'src' | 'dest') => {
      if (sourceOrDest === 'src' && event.value) {
        setSrcSelectAll(true);
        setDestSelectAll(false);
        setSelectedDestFields({});
        if (srcPatient !== null) setSelectedSourceFields(createMergeDonorObj(srcPatient));
      } else if (sourceOrDest === 'dest' && event.value) {
        setDestSelectAll(true);
        setSrcSelectAll(false);
        setSelectedSourceFields({});
        if (destPatient !== null) setSelectedDestFields(createMergeDonorObj(destPatient));
      }
    });

    const deleteSelected = useEvent((prev: Partial<MergeDonorModel> | null, fieldName: string) => {
      const updatedData = { ...prev } as Partial<MergeDonorModel>;
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete updatedData[fieldName];
      return updatedData;
    });

    const setSelected = useEvent(
      (prev: Partial<MergeDonorModel> | null, fieldName: string, fieldValue: string | number | null | undefined) =>
        ({ ...prev, [fieldName]: fieldValue }) as Partial<MergeDonorModel>,
    );

    const handleValueChecked = useEvent((fieldName: string, sourceOrDest: string, checked: boolean, fieldValue: string | number | null | undefined) => {
      if (checked && sourceOrDest === 'src') {
        setDestSelectAll(false);
        setSelectedSourceFields((prev) => setSelected(prev, fieldName, fieldValue));
        setSelectedDestFields((prev) => deleteSelected(prev, fieldName));
      } else if (checked && sourceOrDest === 'dest') {
        setSrcSelectAll(false);
        setSelectedDestFields((prev) => setSelected(prev, fieldName, fieldValue));
        setSelectedSourceFields((prev) => deleteSelected(prev, fieldName));
      }
    });

    const dataStateChange = useEvent((e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    });

    const [dataState, setDataState] = useState<DataSourceRequestState>(
      selectDonorDefaultDataState(selectDonorDefaultFilter(destDonorId, sessionLocation ? sessionLocation.id : null, targetPatient)),
    );

    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [donors, setDonors] = useState<DataResult | null>(null);
    const apiCall = useCallback(
      (kendoState: DataSourceRequestState) => {
        return apiClient.patientClient.getAllForKendoGrid(kendoState, '');
      },
      [apiClient.patientClient],
    );

    const debouncedApiCall = useEvent(debounce(apiCall, 275));

    const fetchDonors = useCallback(
      async (kendoState: DataSourceRequestState) => {
        try {
          setIsLoading(true);
          const response = await debouncedApiCall(kendoState);
          setDonors(response);
        } finally {
          setIsLoading(false);
        }
      },
      [debouncedApiCall],
    );

    useEffect(() => {
      if (searchValue) {
        const resultingDataState = addFiltersToDataState(dataState, [getSearchFilter(searchValue, PatientGridService.getPatientColumns())]);
        // if global search, don't throttle
        fetchDonors(resultingDataState);
        // if not filtering, don't throttle
      } else {
        fetchDonors(dataState);
      }
    }, [dataState, searchValue, fetchDonors]);

    const handleDataStateChangeSrc = useEvent((event: ExamGridDataStateChangeEvent) => {
      setSrcDataState(event.dataState);
    });

    const handleDataStateChangeDest = useEvent((event: ExamGridDataStateChangeEvent) => {
      setDestDataState(event.dataState);
    });

    function modalButtonLabel(isNextBtn: boolean, showDonorGrid: boolean, step: number) {
      if (isNextBtn) {
        if (showDonorGrid) {
          switch (step) {
            case 0:
              return 'Next';
            case 1:
              return 'Next';
            case 2:
              return 'Next';
            case 3:
              return 'Yes';
            default:
              return 'Exit';
          }
        } else {
          switch (step) {
            case 0:
              return 'Next';
            case 1:
              return 'Next';
            case 2:
              return 'Yes';
            default:
              return 'Exit';
          }
        }
      } else {
        if (showDonorGrid) {
          switch (step) {
            case 0:
              return 'Back';
            case 1:
              return 'Back';
            case 2:
              return 'Back';
            case 3:
              return 'No';
            default:
              return 'Exit';
          }
        } else {
          switch (step) {
            case 0:
              return 'Back';
            case 1:
              return 'Back';
            case 2:
              return 'No';
            default:
              return 'Exit';
          }
        }
      }
    }

    return (
      <Window title={`${isOPO ? 'Donor' : 'Patient'} Merge`} onClose={toggleDialog} initialHeight={700} initialWidth={975}>
        <StyledDiv>
          <StyledStepper value={step} items={steps} />
          {showDonorGrid ? (
            <MergePatientValueSelectContext.Provider
              value={{
                srcPatient: srcPatient,
                destPatient: destPatient,
                selectedSourceFields: selectedSourceFields,
                selectedDestFields: selectedDestFields,
                onValueChecked: handleValueChecked,
              }}
            >
              <MergePatientWithSelectModal
                dateFormat={dateFormat}
                destDataState={destDataState}
                destPatient={destPatient}
                destSelectAll={destSelectAll}
                gridDestData={gridDestData}
                gridSrcData={gridSrcData}
                isMergeSuccessful={isMergeSuccessful}
                selectAll={selectAll}
                selectedFields={selectedFields}
                dataStateChangeDest={handleDataStateChangeDest}
                dataStateChangeSrc={handleDataStateChangeSrc}
                srcDataState={srcDataState}
                srcPatient={srcPatient}
                srcSelectAll={srcSelectAll}
                step={step}
                calculateAge={calculateAge}
                dataState={dataState}
                dataStateChange={dataStateChange}
                donors={donors}
                isLoading={isLoading}
                searchValue={searchValue}
                selectedDonors={selectedDonors}
                setSelectedDonors={setSelectedDonors}
              />
            </MergePatientValueSelectContext.Provider>
          ) : (
            <MergePatientValueSelectContext.Provider
              value={{
                srcPatient: srcPatient,
                destPatient: destPatient,
                selectedSourceFields: selectedSourceFields,
                selectedDestFields: selectedDestFields,
                onValueChecked: handleValueChecked,
              }}
            >
              <MergePatientDirectModal
                dateFormat={dateFormat}
                destDataState={destDataState}
                destPatient={destPatient}
                destSelectAll={destSelectAll}
                gridDestData={gridDestData}
                gridSrcData={gridSrcData}
                isMergeSuccessful={isMergeSuccessful}
                selectAll={selectAll}
                selectedFields={selectedFields}
                dataStateChangeDest={handleDataStateChangeDest}
                dataStateChangeSrc={handleDataStateChangeSrc}
                srcDataState={srcDataState}
                srcPatient={srcPatient}
                srcSelectAll={srcSelectAll}
                step={step}
              />
            </MergePatientValueSelectContext.Provider>
          )}

          <StyledDialogActionsBar>
            <hr></hr>
            <StyledDivButtons>
              {step > 0 && !(step == 3 && !showDonorGrid) && !(step == 4 && showDonorGrid) ? (
                <StyledPrevBtn size={ComponentSizes.LARGE} onClick={onPrevClick} variant={ButtonVariants.SECONDARY}>
                  {modalButtonLabel(false, showDonorGrid, step)}
                </StyledPrevBtn>
              ) : (
                ''
              )}
              <StyledNextMergeBtn size={ComponentSizes.LARGE} onClick={onStepSubmit}>
                {modalButtonLabel(true, showDonorGrid, step)}
              </StyledNextMergeBtn>
            </StyledDivButtons>
          </StyledDialogActionsBar>
        </StyledDiv>
      </Window>
    );

    // return (
    //   <StyledDialog titleIcon={faUser} title="Merge Donor" onClose={toggleDialog}>
    //     <StyledDiv>
    //       <StyledStepper value={step} items={steps} />
    //       {showDonorGrid ? (
    //         <MergePatientValueSelectContext.Provider
    //           value={{
    //             srcPatient: srcPatient,
    //             destPatient: destPatient,
    //             selectedSourceFields: selectedSourceFields,
    //             selectedDestFields: selectedDestFields,
    //             onValueChecked: handleValueChecked,
    //           }}
    //         >
    //           <MergePatientWithSelectModal
    //             dateFormat={dateFormat}
    //             destDataState={destDataState}
    //             destPatient={destPatient}
    //             destSelectAll={destSelectAll}
    //             gridDestData={gridDestData}
    //             gridSrcData={gridSrcData}
    //             isMergeSuccessful={isMergeSuccessful}
    //             selectAll={selectAll}
    //             selectedFields={selectedFields}
    //             dataStateChangeDest={handleDataStateChangeDest}
    //             dataStateChangeSrc={handleDataStateChangeSrc}
    //             srcDataState={srcDataState}
    //             srcPatient={srcPatient}
    //             srcSelectAll={srcSelectAll}
    //             step={step}
    //             calculateAge={calculateAge}
    //             dataState={dataState}
    //             dataStateChange={dataStateChange}
    //             donors={donors}
    //             isLoading={isLoading}
    //             searchValue={searchValue}
    //             selectedDonors={selectedDonors}
    //             setSelectedDonors={setSelectedDonors}
    //           />
    //         </MergePatientValueSelectContext.Provider>
    //       ) : (
    //         <MergePatientValueSelectContext.Provider
    //           value={{
    //             srcPatient: srcPatient,
    //             destPatient: destPatient,
    //             selectedSourceFields: selectedSourceFields,
    //             selectedDestFields: selectedDestFields,
    //             onValueChecked: handleValueChecked,
    //           }}
    //         >
    //           <MergePatientDirectModal
    //             dateFormat={dateFormat}
    //             destDataState={destDataState}
    //             destPatient={destPatient}
    //             destSelectAll={destSelectAll}
    //             gridDestData={gridDestData}
    //             gridSrcData={gridSrcData}
    //             isMergeSuccessful={isMergeSuccessful}
    //             selectAll={selectAll}
    //             selectedFields={selectedFields}
    //             dataStateChangeDest={handleDataStateChangeDest}
    //             dataStateChangeSrc={handleDataStateChangeSrc}
    //             srcDataState={srcDataState}
    //             srcPatient={srcPatient}
    //             srcSelectAll={srcSelectAll}
    //             step={step}
    //           />
    //         </MergePatientValueSelectContext.Provider>
    //       )}

    //       <StyledDialogActionsBar>
    //         <hr></hr>
    //         <StyledDivButtons>
    //           {step > 0 && !(step == 3 && !showDonorGrid) && !(step == 4 && showDonorGrid) ? (
    //             <StyledPrevBtn size={ComponentSizes.LARGE} onClick={onPrevClick} variant={ButtonVariants.SECONDARY}>
    //               Back
    //             </StyledPrevBtn>
    //           ) : (
    //             ''
    //           )}
    //           <StyledNextMergeBtn size={ComponentSizes.LARGE} onClick={onStepSubmit}>
    //             {(() => {
    //               if (showDonorGrid) {
    //                 switch (step) {
    //                   case 0:
    //                     return 'Next';
    //                   case 1:
    //                     return 'Next';
    //                   case 2:
    //                     return 'Next';
    //                   case 3:
    //                     return 'Begin Merge';
    //                   default:
    //                     return 'Exit';
    //                 }
    //               } else {
    //                 switch (step) {
    //                   case 0:
    //                     return 'Next';
    //                   case 1:
    //                     return 'Next';
    //                   case 2:
    //                     return 'Begin Merge';
    //                   default:
    //                     return 'Exit';
    //                 }
    //               }
    //             })()}
    //           </StyledNextMergeBtn>
    //         </StyledDivButtons>
    //       </StyledDialogActionsBar>
    //     </StyledDiv>
    //   </StyledDialog>
    // );
  },
);

MergePatientModal.displayName = 'MergePatientModal';

const StyledDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 14px;

  .k-actions-stretched > * {
    flex: 0 0 0%;
  }

  .k-dialog-buttongroup {
    margin-top: auto;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .checkbox-selected {
    color: var(--aqua-6, #39c);
  }
`;

const StyledDiv = styled.div`
  width: 94%;
  padding-left: 4%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  // width: 94%;
  // height: 100%;
`;

const StyledStepper = styled(Stepper)`
  padding-bottom: ${(props) => props.theme.space.spacing50};
`;

const StyledDialogActionsBar = styled.div`
  .flex-container {
    display: flex;
    background-color: DodgerBlue;
    flex: 0 0 0%;
  }

  .flex-container > div {
    background-color: #f1f1f1;
    margin: 10px;
    padding: 20px;
    font-size: 30px;
  }
  border-color: white;
`;

const StyledNextMergeBtn = styled(Button)`
  flex-grow: 1; /* The right div will take the remaining space */

  padding: 20px; /* Space inside the div */
  border-left: 2px solid #ccc; /* Optional: border to separate from left div */
`;

const StyledPrevBtn = styled(Button)`
  margin-right: 10px;
`;

const StyledDivButtons = styled.div`
  .container {
    display: flex;
  }

  margin-right: auto;
  text-align: right;
`;
