import { ColumnState } from 'core/ui';

export const DefaultColumnsState: Record<string, ColumnState> = {
  id: { show: true, orderIndex: 1 },
  destinationName: { show: true, orderIndex: 2 },
  patientNumber: { show: true, orderIndex: 3 },
  integrationName: { show: true, orderIndex: 4 },
  previousStatusTypeName: { show: true, orderIndex: 5 },
  statusTypeName: { show: true, orderIndex: 6 },
  physicianName: { show: true, orderIndex: 7 },
  locationName: { show: true, orderIndex: 8 },
  contactName: { show: true, orderIndex: 9 },
  filter: { show: true, orderIndex: 10 },
  isEventTrigger: { show: false, orderIndex: 11 },
  active: { show: false, orderIndex: 12 },
};
