import { DataStream, IDataStreamConsumer } from 'core/utils';

import { BasicUploadStatus, FilesLoadedEvent, UploadProgressEvent } from '../types';
import { UploadPipeline } from './UploadPipeline';

export class BasicUploadView {
  static [Symbol.toStringTag]() {
    return 'BasicUploadView';
  }

  private _cleanupFunctions: (() => void)[] = [];

  private _streams = {
    status: new DataStream<BasicUploadStatus>({ totalFiles: 0, uploadedFiles: 0, attachedFiles: 0, error: 0, totalBytes: 0, uploadedBytes: 0 }),
  };

  public get streams(): {
    status: IDataStreamConsumer<BasicUploadStatus>;
  } {
    return this._streams;
  }

  constructor(uploadPipeline: UploadPipeline) {
    this.handleFilesLoaded = this.handleFilesLoaded.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleFileAttached = this.handleFileAttached.bind(this);

    this._cleanupFunctions.push(uploadPipeline.streams.onFilesLoaded.subscribe(this.handleFilesLoaded));
    this._cleanupFunctions.push(uploadPipeline.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(uploadPipeline.blobUploader.streams.onError.subscribe(this.handleUploadError));
    this._cleanupFunctions.push(uploadPipeline.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress));
    this._cleanupFunctions.push(uploadPipeline.fileAttacher.streams.onFileAttached.subscribe(this.handleFileAttached));
    this._cleanupFunctions.push(uploadPipeline.fileAttacherPathology.streams.onFileAttached.subscribe(this.handleFileAttached));
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
  }

  private handleFilesLoaded(event: FilesLoadedEvent) {
    const prev = this._streams.status.getCurrentValue();

    this._streams.status.emit({
      ...prev,
      totalFiles: prev.totalFiles + event.files.length,
      totalBytes: prev.totalBytes + event.files.reduce((acc, file) => acc + file.file.size, 0),
    });
  }

  private handleUploadProgress(event: UploadProgressEvent) {
    const prev = this._streams.status.getCurrentValue();

    this._streams.status.emit({
      ...prev,
      uploadedBytes: prev.uploadedBytes + event.chunkSize,
    });
  }

  private handleUploadComplete() {
    const prev = this._streams.status.getCurrentValue();

    this._streams.status.emit({
      ...prev,
      uploadedFiles: prev.uploadedFiles + 1,
    });
  }

  private handleUploadError() {
    const prev = this._streams.status.getCurrentValue();

    this._streams.status.emit({
      ...prev,
      error: prev.error + 1,
    });
  }

  private handleFileAttached() {
    const prev = this._streams.status.getCurrentValue();

    this._streams.status.emit({
      ...prev,
      attachedFiles: prev.attachedFiles + 1,
    });
  }
}
