import { FunctionComponent, memo, useEffect, useState } from 'react';
import { ComponentSizes, DataTable, DateCell, DEFAULT_DATA_TABLE_DATA_STATE, HeaderCell, TextCell } from 'core/ui';
import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { DataResult } from 'core/api';
import { HippaLogGridModel } from 'models';
import { useEvent } from 'core/hooks';
import { DateCellProps } from 'core/ui/DataTable/components/table-cells/DateCellProps';
import { apiClient } from 'features/api';

const defaultDataState: State = {
  ...DEFAULT_DATA_TABLE_DATA_STATE,
  sort: [{ field: 'startTime', dir: 'desc' }],
};

export const HippaLogs: FunctionComponent<{ examId: number; size?: ComponentSizes.SMALL | ComponentSizes.MEDIUM | ComponentSizes.LARGE }> = memo(
  ({ examId }) => {
    const [dataState, setDataState] = useState<State>(defaultDataState);
    const [dataResult, setDataResult] = useState<DataResult<HippaLogGridModel[]>>();
    const [isLoading, setIsLoading] = useState(false);
    const [hippaLogs, setHippaLogs] = useState<HippaLogGridModel[]>([]);

    const refreshData = useEvent(async () => {
      if (examId) {
        try {
          setIsLoading(true);
          const hippaLogs = await apiClient.studyShare.getHippaLogs(examId);
          setDataResult(process(hippaLogs, dataState));
          setHippaLogs(hippaLogs);
        } finally {
          setIsLoading(false);
        }
      } else {
        setHippaLogs([]);
      }
    });

    useEffect(() => {
      refreshData();
    }, [refreshData]);

    const handleDataStateChange = useEvent((e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
      setDataResult(process(hippaLogs, e.dataState));
    });

    return (
      <DataTable
        {...dataState}
        data={dataResult}
        total={dataResult?.total}
        filterable
        pageable
        reorderable
        sortable
        onDataStateChange={handleDataStateChange}
        isLoading={isLoading}
        size={ComponentSizes.SMALL}
      >
        <GridColumn cell={DateTimeMsCell} field="visitDate" filterable={false} title="Date" headerCell={HeaderCell} />
        <GridColumn cell={TextCell} field="description" filterable={false} title="Description" headerCell={HeaderCell} />
        <GridColumn cell={TextCell} field="viewDesc" filterable={false} title="View Event" headerCell={HeaderCell} />
        <GridColumn cell={TextCell} field="ipAddress" filterable={false} title="IP Address" headerCell={HeaderCell} />
      </DataTable>
    );
  },
);

HippaLogs.displayName = 'HippaLogs';

const DateTimeMsCell = (props: DateCellProps) => <DateCell {...props} format="MM/DD/YYYY HH:mm:ss.SSS" />;
