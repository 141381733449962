import { CompressCompleteEvent, CompressErrorEvent, CompressStartEvent } from '@repo/web-workers/file-compressor';

import { ConnectionString, ContainerName, ExamModel, PatientModel } from 'models';

import { DataStream, EventStream, IDataStreamConsumer, IEventStreamConsumer, MIME_TYPES, lookupMimeType } from 'core/utils';

import {
  FileAttachedEvent,
  FileScannerJobDicom,
  FileUploadContext,
  FileUploadMetadata,
  FilesLoadedEvent,
  MatchCompleteEvent,
  ParseCompleteEvent,
  UploadBatchStartEvent,
  UploadBatchStartFile,
  UploadCompleteEvent,
  UploadErrorEvent,
  UploadFileJob,
  UploadProgressEvent,
  UploadStartEvent,
} from '../types';
import { AzureBlobUploader } from './AzureBlobUploader';
import { DicomMatcher } from './DicomMatcher';
import { FileAttacher } from './FileAttacher';
import { FileAttacherPathology } from './FileAttacherPathology';
import { FileCompressor, FileCompressorJob } from './FileCompressor';
import { FileScanner } from './FileScanner';
import { DocumentCategories } from 'features/exam';

export type ScanFileDescriptor = {
  fileId: string;
  file: File;
  connectionString: ConnectionString;
  containerName: ContainerName;
  /** `null` indicates that the file will not be attached at all. */
  attachMode: null | 'pathology' | 'other' | 'ekg';
};

export class UploadPipeline {
  static [Symbol.toStringTag]() {
    return 'UploadPipeline';
  }

  /** The key is the fileId. */
  private _fileContexts = new Map<string, FileUploadContext>();

  private _streams = {
    onFilesLoaded: new EventStream<FilesLoadedEvent>('onFilesLoaded'),
    onUploadBatchStart: new EventStream<UploadBatchStartEvent>('onUploadStart'),
    pipelineLength: new DataStream<number>(0), // TODO: This would likely be better if it was a computed value that was derived from the queues.  However implementing that will require touching all of the processing functions.
  };

  public get streams(): {
    onFilesLoaded: IEventStreamConsumer<FilesLoadedEvent>;
    onUploadBatchStart: IEventStreamConsumer<UploadBatchStartEvent>;
    /** Keeps track of how many items are in the upload pipeline (excluding the file scanner).  This counts files that are actively being processed (files are removed from the individual queues during processing). */
    pipelineLength: IDataStreamConsumer<number>;
  } {
    return this._streams;
  }

  constructor(
    public readonly fileScanner: FileScanner,
    public readonly dicomMatcher: DicomMatcher,
    public readonly blobUploader: AzureBlobUploader,
    public readonly fileCompressor: FileCompressor,
    public readonly fileAttacher: FileAttacher,
    public readonly fileAttacherPathology: FileAttacherPathology,
  ) {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.setFixedEntities = this.setFixedEntities.bind(this);
    this.scanDicomFiles = this.scanDicomFiles.bind(this);
    this.scanNonDicomFiles = this.scanNonDicomFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleMatchComplete = this.handleMatchComplete.bind(this);
    this.handleCompressStart = this.handleCompressStart.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleCompressError = this.handleCompressError.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleFileAttached = this.handleFileAttached.bind(this);
    this.handleFileAttachedPathology = this.handleFileAttachedPathology.bind(this);

    this.fileScanner.streams.onParseComplete.subscribe(this.handleParseComplete);
    this.dicomMatcher.streams.onMatchComplete.subscribe(this.handleMatchComplete);
    this.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete);
    this.fileCompressor.streams.onError.subscribe(this.handleCompressError);
    this.blobUploader.streams.onStart.subscribe(this.handleUploadStart);
    this.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress);
    this.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete);
    this.blobUploader.streams.onError.subscribe(this.handleUploadError);
    this.fileAttacher.streams.onFileAttached.subscribe(this.handleFileAttached);
    this.fileAttacherPathology.streams.onFileAttached.subscribe(this.handleFileAttachedPathology);
  }

  public initialize(
    getSasFn: (connectionString: ConnectionString, containerName: ContainerName) => Promise<string>,
    authMode: 'msal-required' | 'share-required',
  ) {
    this.fileScanner.initialize();
    this.dicomMatcher.initialize();
    this.fileCompressor.initialize();
    this.blobUploader.initialize(getSasFn);
    this.fileAttacher.initialize(authMode);
    this.fileAttacherPathology.initialize(authMode);
  }

  public destroy() {
    this._fileContexts.clear();
    this._streams.onFilesLoaded.clear();
    this._streams.pipelineLength.clear();

    this.fileScanner.destroy();
    this.dicomMatcher.destroy();
    this.fileCompressor.destroy();
    this.blobUploader.destroy();
    this.fileAttacher.destroy();
    this.fileAttacherPathology.destroy();
  }

  public setFixedEntities(fixedPatient: PatientModel | null, fixedExam: ExamModel | null, fixedLocationId: number | null) {
    this.dicomMatcher.setFixedEntities(fixedPatient, fixedExam, fixedLocationId);
  }

  public scanDicomFiles(files: File[]) {
    const newScannerJobs: FileScannerJobDicom[] = [];
    const newFileContexts: FileUploadContext[] = [];

    for (const file of files) {
      const newFileContext: FileUploadContext = {
        fileId: crypto.randomUUID(),
        file,
        compress: true,
        connectionString: ConnectionString.DICOMStorageConnectionString,
        containerName: ContainerName.Incoming,
        metadata: null,
        fileType: MIME_TYPES.DCM.extensions[0],
        result: 'in-progress',
        attachMode: null,
      };

      newFileContexts.push(newFileContext);

      newScannerJobs.push({
        type: 'scan-dicom-file',
        fileId: newFileContext.fileId,
        file,
      });

      this._fileContexts.set(newFileContext.fileId, newFileContext);
    }

    this._streams.onFilesLoaded.emit({
      files: newFileContexts.map((file) => ({ fileId: file.fileId, file: file.file })),
    });

    this.fileScanner.enqueueDicomFiles(newScannerJobs);
  }

  public scanNonDicomFiles(files: File[] | ScanFileDescriptor[]) {
    const newFileContexts: FileUploadContext[] = [];

    for (const file of files) {
      const isWrappedFile = 'fileId' in file;

      const fileType = lookupMimeType(isWrappedFile ? file.file.name : file.name);

      const newFileContext: FileUploadContext = {
        fileId: isWrappedFile ? file.fileId : crypto.randomUUID(),
        file: isWrappedFile ? file.file : file,
        compress: false,
        connectionString: isWrappedFile ? file.connectionString : ConnectionString.StorageConnectionString,
        containerName: isWrappedFile ? file.containerName : ContainerName.Files,
        metadata: null,
        fileType: fileType?.extensions?.at?.(0) ?? 'UNKNOWN FILE TYPE',
        result: 'in-progress',
        attachMode: isWrappedFile ? file.attachMode : null,
      };

      newFileContexts.push(newFileContext);
      this._fileContexts.set(newFileContext.fileId, newFileContext);
    }

    this._streams.onFilesLoaded.emit({
      files: newFileContexts.map((file) => ({ fileId: file.fileId, file: file.file })),
    });

    this.fileScanner.enqueueNonDicomFiles(
      newFileContexts.map((fileContext) => ({
        fileId: fileContext.fileId,
        file: fileContext.file,
        type: 'scan-non-dicom-file',
      })),
    );

    return newFileContexts;
  }

  public uploadFiles(fileIds: string | string[]) {
    const compressFileJobs: FileCompressorJob[] = [];
    const nonCompressFileJobs: UploadFileJob[] = [];
    const uploadBatchFiles: UploadBatchStartFile[] = [];

    for (const fileId of Array.isArray(fileIds) ? fileIds : [fileIds]) {
      const fileContext = this._fileContexts.get(fileId);

      if (fileContext == null) throw new Error('Could not find file context for file Id: ' + fileId);

      if (fileContext.compress) {
        compressFileJobs.push({ fileId: fileContext.fileId, file: fileContext.file });
        uploadBatchFiles.push({ fileId: fileContext.fileId, willCompress: true, willAttach: fileContext.attachMode !== null });
      } else {
        nonCompressFileJobs.push({
          fileId: fileContext.fileId,
          fileName: fileContext.file.name,
          fileSize: fileContext.file.size,
          compressedSize: null,
          buffer: fileContext.file.arrayBuffer(),
          compress: false,
          connectionString: fileContext.connectionString,
          containerName: fileContext.containerName,
          metadata: fileContext.metadata,
        });
        uploadBatchFiles.push({ fileId: fileContext.fileId, willCompress: false, willAttach: fileContext.attachMode !== null });
      }
    }

    if (compressFileJobs.length > 0) {
      this.fileCompressor.enqueueFiles(compressFileJobs);
    }
    if (nonCompressFileJobs.length > 0) {
      this.blobUploader.enqueueFiles(nonCompressFileJobs);
    }

    if (compressFileJobs.length > 0 || nonCompressFileJobs.length > 0) {
      this._streams.onUploadBatchStart.emit({
        files: uploadBatchFiles,
      });
    }
  }

  public setFileMetadata(fileId: string, metadata: FileUploadMetadata) {
    const fileContext = this._fileContexts.get(fileId);

    if (fileContext == null) throw new Error('Could not find file context for file Id: ' + fileId);

    fileContext.metadata = metadata;
  }

  private handleParseComplete(event: ParseCompleteEvent) {
    const fileContext = this._fileContexts.get(event.fileId);
    if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

    if (event.dicomData != null) {
      this.dicomMatcher.enqueue({
        fileId: event.fileId,
        dicomData: event.dicomData,
      });
    }
  }

  private handleMatchComplete(_event: MatchCompleteEvent) {
    // No-op.
  }

  private handleCompressStart(_event: CompressStartEvent) {
    // No-op.
  }

  private handleCompressComplete(event: CompressCompleteEvent) {
    const fileContext = this._fileContexts.get(event.fileId);
    if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

    this.blobUploader.enqueueFiles({
      fileId: event.fileId,
      fileName: event.fileName,
      fileSize: event.fileSize,
      compressedSize: event.compressedSize,
      compress: true,
      buffer: event.buffer,
      connectionString: fileContext.connectionString,
      containerName: fileContext.containerName,
      metadata: fileContext.metadata,
    });
  }

  private handleCompressError(_event: CompressErrorEvent) {
    // No-op.
  }

  private handleUploadStart(_event: UploadStartEvent) {
    // Does nothing for now.
  }

  private handleUploadProgress(_event: UploadProgressEvent) {
    // Does nothing for now.
  }

  private handleUploadComplete(event: UploadCompleteEvent) {
    const fileContext = this._fileContexts.get(event.fileId);

    if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

    // We only want to attach non-DICOM files.
    if (fileContext.attachMode != null) {
      const fileUrl = new URL(event.url);

      if (fileContext.metadata?.examId == null) {
        // This is not expected to be able to happen.  So if it does we need to make sure that we are alerted to it.
        throw new Error('Unhandled case: file uploaded without an examId.  This must be fixed!');
      }

      if (fileContext.attachMode === 'other') {
        this.fileAttacher.attach({
          fileId: event.fileId,
          fileType: fileContext.fileType ?? 'ERROR - UNKNOWN FILE TYPE',
          fileName: fileContext.file.name,
          location: `${fileUrl.protocol}://${fileUrl.host}${fileUrl.pathname}`,
          fileSize: event.uploadSize,
          examId: fileContext.metadata.examId,
        });
      } else if (fileContext.attachMode === 'pathology') {
        this.fileAttacherPathology.attach({
          fileId: event.fileId,
          fileName: `${event.fileId}.${fileContext.fileType}`,
          examId: fileContext.metadata.examId,
          originalFileName: fileContext.file.name,
          categoryId: DocumentCategories.EXAM.value,
        });
      } else if (fileContext.attachMode === 'ekg') {
        this.fileAttacher.attach({
          fileId: event.fileId,
          fileType: fileContext.fileType ?? 'ERROR - UNKNOWN FILE TYPE',
          fileName: fileContext.file.name,
          location: `${fileUrl.protocol}://${fileUrl.host}${fileUrl.pathname}`,
          fileSize: event.uploadSize,
          examId: fileContext.metadata.examId,
          categoryId: DocumentCategories.EXAM.value,
        });
      }
    } else {
      this._fileContexts.set(event.fileId, { ...fileContext, result: 'success' });
    }
  }

  private handleUploadError(event: UploadErrorEvent) {
    const fileContext = this._fileContexts.get(event.fileId)!;
    this._fileContexts.set(event.fileId, { ...fileContext, result: 'error' });
  }

  private handleFileAttached(event: FileAttachedEvent) {
    const fileContext = this._fileContexts.get(event.fileId)!;
    this._fileContexts.set(event.fileId, { ...fileContext, result: 'success' });
  }

  private handleFileAttachedPathology(event: FileAttachedEvent) {
    const fileContext = this._fileContexts.get(event.fileId)!;
    this._fileContexts.set(event.fileId, { ...fileContext, result: 'success' });
  }
}
