import { Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { sortBy } from 'lodash';

import { ColumnState, SwitchCell, TextCell } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { ColumnDefinition, DefaultColumnsState } from 'features/exam';

const formColumnCollections: ColumnDefinition[] = [
  {
    cell: TextCell,
    field: 'id',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'System Generated Number',
    title: 'Id',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: true,
  },
  {
    cell: TextCell,
    field: 'name',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Name of the Form',
    title: 'Name',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'description',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Description of the form',
    title: 'Description',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'ui',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'user interface of the form',
    title: 'UI',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'data',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: '',
    title: 'Data',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'reportTemplatePath',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'file location of the template',
    title: 'Report Template Path',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: SwitchCell,
    field: 'generateReport',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Toggle to generate report',
    title: 'Generate Report',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'boolean',
    show: false,
  },
  {
    cell: SwitchCell,
    field: 'autoSendReport',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Settings for sending final report',
    title: 'Auto Send Report',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'boolean',
    show: false,
  },
  {
    cell: SwitchCell,
    field: 'active',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Form is accessible',
    title: 'Active',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'boolean',
    show: false,
  },
];

const formColumns = ['id', 'name', 'description', 'ui', 'data', 'reportTemplatePath', 'generateReport', 'autoSendReport', 'active'];

// get the columns of each features
function getDefineColumn(columns: string[]) {
  const finalColumns: ColumnDefinition[] = [];

  // loop to each columns
  columns.forEach((column) => {
    // find item to the collections
    finalColumns.push(findOrThrow(formColumnCollections, (item) => item.field === column, `Could not find column definition for: "${column}".`));
  });

  return finalColumns;
}

const FORM_COLUMNS = getDefineColumn(formColumns);

function getColumns() {
  return FORM_COLUMNS;
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string): ColumnState {
  const orderIndex = allColumnStates[columnField]?.orderIndex ?? DefaultColumnsState[columnField]?.orderIndex ?? 0;
  const show = allColumnStates[columnField]?.show ?? DefaultColumnsState[columnField]?.show ?? false;

  return { orderIndex, show };
}

function getGridColumns() {
  return sortBy(
    FORM_COLUMNS.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getColumnDefinition(field: string) {
  return findOrThrow(formColumnCollections, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

export const FormGridService = {
  getColumns,
  getColumnState,
  getGridColumns,
  getColumnDefinition,
};
