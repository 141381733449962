import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { DataStream, DataStreamMap, IDataStreamConsumer, IDataStreamMapConsumer, formatFileSize } from 'core/utils';

const IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE = 100 * Math.pow(2, 20); // 100 MiB

export class ImageDataCache {
  static [Symbol.toStringTag]() {
    return 'ImageDataCache';
  }

  private _maxSize;

  private _streams = {
    bitmaps: new DataStreamMap<string, ImageData | null>(),
    size: new DataStream(0),
  };

  public get streams(): {
    bitmaps: IDataStreamMapConsumer<string, ImageData | null>;
    /** Total size used by the cache in bytes. */
    size: IDataStreamConsumer<number>;
  } {
    return this._streams;
  }

  /** Create a new ImageData cache.
   * @param maxSize Maximum size of the cache in bytes.  Default is 100 MiB.
   */
  constructor(maxSize = IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE) {
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.clear = this.clear.bind(this);
    this.getImageCount = this.getImageCount.bind(this);

    this._maxSize = maxSize ?? 32 * Math.pow(2, 20);

    datadogLogs.logger.info('image-data-cache_create', { cacheSize: 0, maxCacheSize: this._maxSize });
  }

  public get(key: string) {
    return this._streams.bitmaps.getCurrentValue(key, false) ?? null;
  }

  /** Add or remove an image from the cache. */
  public set(key: string, image: ImageData | null) {
    const imageSize = image?.data?.byteLength ?? 0;
    const previousImage = this._streams.bitmaps.getCurrentValue(key, false);
    const previousImageSize = previousImage?.data?.byteLength ?? 0;
    const previousCacheSize = this._streams.size.getCurrentValue();
    const newCacheSize = previousCacheSize - (previousImage?.data?.byteLength ?? 0) + (image?.data?.byteLength ?? 0);

    if (newCacheSize > this._maxSize) {
      // This is being logged as an error out of an abundance of caution.  It's probably not a critical error if the cache is full, but we do need
      // to understand why it's happening in case it's indicative of a larger problem.
      datadogLogs.logger.error(
        `image-data-cache_overflow`,
        {
          imageKey: key,
          imageSize,
          imageCount: this.getImageCount(),
          previousImageSize,
          cacheSize: previousCacheSize,
          failedCacheSize: newCacheSize,
          maxCacheSize: this._maxSize,
        },
        new Error('Image data cache maximum size exceeded.'), // Adds a stack trace to the log entry.
      );

      return false;
    }

    this._streams.size.emit(newCacheSize);
    this._streams.bitmaps.emit(key, image);

    datadogLogs.logger.info('image-data-cache_set', {
      imageKey: key,
      imageSize,
      imageCount: this.getImageCount(),
      previousImageSize,
      cacheSize: newCacheSize,
      previousCacheSize,
      maxCacheSize: this._maxSize,
    });

    return true;
  }

  public clear() {
    const previousCacheSize = this._streams.size.getCurrentValue();

    this._streams.size.clear();
    this._streams.bitmaps.clear();

    datadogLogs.logger.info('image-data-cache_clear', {
      imageCount: this.getImageCount(),
      cacheSize: 0,
      previousCacheSize,
      maxCacheSize: this._maxSize,
    });
  }

  /** Calculate the number of non-null bitmaps in the cache. */
  private getImageCount() {
    return Array.from(this._streams.bitmaps.getEntries()).reduce((acc, [_key, value]) => acc + (value.getCurrentValue() == null ? 0 : 1), 0);
  }
}
