import { State } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';

import { WorklistViewModel } from 'models';

import { addFiltersToDataState } from 'core/api/services/kendoMultiColumnFilter';
import { ColumnState } from 'core/ui';
import { equalsInsensitive } from 'core/utils';

import { DefaultColumnsState } from 'features/exam/constants';

import { DEFAULT_PHYSICIAN_WORKLIST_NAME } from '../constants';

function transformQueryDataState(dataState: State, physicianId: number): State {
  const newDataState = addFiltersToDataState(dataState, [
    {
      field: 'physicianId',
      operator: 'eq',
      value: physicianId,
    },
    {
      field: 'active',
      operator: 'eq',
      value: true,
    },
  ]);

  return newDataState;
}

function getDefaultPhysicianExamColumnsState(): Record<string, ColumnState> {
  return cloneDeep(DefaultColumnsState);
}

function pickDefaultWorklist(allWorklists: WorklistViewModel[]): WorklistViewModel | null {
  return allWorklists.find((worklist) => equalsInsensitive(DEFAULT_PHYSICIAN_WORKLIST_NAME, worklist.name)) ?? null;
}

export const PhysicianGridService = {
  transformQueryDataState,
  getDefaultPhysicianExamColumnsState,
  pickDefaultWorklist,
};
