import { ChangeEvent } from 'react';

import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { CheckboxChangeEvent, MaskedTextBoxChangeEvent, NumericTextBoxChangeEvent, SwitchChangeEvent, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { useEvent } from 'core/hooks';
import { DropdownNgChangeEvent } from 'core/ui/DropdownNg';
import { FileInputChangeEvent } from 'core/ui/FileInput';
import { hasText } from 'core/utils';

export function useFieldValueChangeHandler<TFieldValues extends FieldValues>(
  callbackFn: (values: TFieldValues) => void,
  rhfContext: UseFormReturn<TFieldValues>,
) {
  const handleChange = useEvent(
    (
      event:
        | ChangeEvent<HTMLInputElement>
        | DatePickerChangeEvent
        | NumericTextBoxChangeEvent
        | DropDownListChangeEvent
        | TextAreaChangeEvent
        | SwitchChangeEvent
        | MaskedTextBoxChangeEvent
        | DropdownNgChangeEvent
        | FileInputChangeEvent
        | CheckboxChangeEvent,
    ) => {
      let currentField: Partial<TFieldValues>;

      if ('currentTarget' in event) {
        // Handles components that emit on change events similar to a standard HTML input element.
        if (!hasText(event.currentTarget.name)) throw new Error('The onChange emitting component does not have a name.');

        currentField = {
          [event.currentTarget.name]: event.currentTarget.value,
        } as unknown as Partial<TFieldValues>;
      } else if ('target' in event) {
        // For unknown reasons some of the Kendo controls use target instead of currentTarget.
        if (!hasText(event.target.name)) throw new Error('Component requires a name prop.');

        currentField = {
          [event.target.name]: event.value ?? event.target.value, // First check for the value directly on the event.  Components that use this "event.value" structure will use it for things like normalized values, parsed values, etc.
        } as unknown as Partial<TFieldValues>;
      } else if ('name' in event && hasText(event.name) && 'value' in event) {
        currentField = {
          [event.name]: event.value,
        } as unknown as Partial<TFieldValues>;
      } else {
        throw new Error('Unable to propagate the change event because the change event emitted from the component has an unrecognized structure.');
      }

      callbackFn({
        ...rhfContext.getValues(),
        ...currentField,
      });
    },
  );

  return handleChange;
}
