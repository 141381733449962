import styled from 'styled-components';
import { Window as KendoWindow } from '@progress/kendo-react-dialogs';
import { memo } from 'react';
import { WindowProps } from './WindowProps';

export const Window = memo<WindowProps>(({ ...rest }) => {
  return <StyledWindow {...rest} />;
});

Window.displayName = 'Window';

const mediaMaxWidth = '1024px';
const StyledWindow = styled(KendoWindow)`
  @media (max-width: ${mediaMaxWidth}) {
    &&.k-window {
      width: 100% !important;
      height: 75% !important;
    }
  }
  // add border with box shadow
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 15px 15px 15px 15px;
  box-shadow:
    0 0 8px rgba(128, 128, 128, 0.2),
    0 0 4px ${({ theme }) => theme.colors.primary}80;
`;
