import { memo } from 'react';

import styled from 'styled-components';

export const MergeStepConfirm = memo(() => {
  return (
    <StyledDiv>
      <StyleH3>Confirm Merge</StyleH3>
      <StyleSpan>Do you want to proceed in merging the donor records?</StyleSpan>
    </StyledDiv>
  );
});

MergeStepConfirm.displayName = 'MergeStepConfirm';

const StyledDiv = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;

  border-radius: 8px;
  border: 1px solid var(--gray-5, #d9d9d9);
`;

const StyleH3 = styled.h3`
  margin: 20px;
`;

const StyleSpan = styled.span`
  margin: 20px;
`;
