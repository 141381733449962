import { FunctionComponent, useCallback, useMemo } from 'react';

import { DropDownListBlurEvent, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';

import { Dropdown } from './Dropdown';
import { DropdownFieldProps } from './DropdownFieldProps';

export const DropdownField: FunctionComponent<DropdownFieldProps> = ({ name, required, dataItemKey = 'id', validator, onChange, onBlur, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.requiredWithDefaultOption(dataItemKey));
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [dataItemKey, required, validator]);

  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({
    name,
    rules: {
      validate: validatorInternal,
    },
  });

  const handleChange = useCallback(
    (event: DropDownListChangeEvent) => {
      rhfOnChange(event);
      onChange?.(event);
    },
    [rhfOnChange, onChange],
  );

  const handleBlur = useCallback(
    (event: DropDownListBlurEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  return (
    <Dropdown
      {...fieldRest}
      {...rest}
      onChange={handleChange}
      onBlur={handleBlur}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      required={required}
      validationMessage={error?.message}
      dataItemKey={dataItemKey}
    />
  );
};

DropdownField.displayName = 'DropdownField';
