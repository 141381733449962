import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { FilterDescriptor } from '@progress/kendo-data-query';
import { SvgIcon } from '@progress/kendo-react-common';
import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { homeIcon } from '@progress/kendo-svg-icons';
import dayjs from 'dayjs';
import { cloneDeep, pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ChangeExamStatusModel, ExamModel, FileModel, PatientModel } from 'models';
import { ITransplantMergeModel } from 'models/ITransplantMergeModel';
import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { addFiltersToDataState, getSearchFilter } from 'core/api/services/kendoMultiColumnFilter';
import { SubmitHandler } from 'core/forms';
import { useBoolean, useEvent, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, ColumnState, ComponentSizes, Page, PageHeader, TabButton, TabsContainer } from 'core/ui';
import { findOrThrow, hasText } from 'core/utils';

import { ActivityLogModal } from 'features/activity-log';
import { useApiClient } from 'features/api';
import { ExamActions, ExamSelectors } from 'features/exam';
import { ExamStatus } from 'features/exam/constants';
import { ExamsGridService } from 'features/exam/services/exam-grid-service';
import { UploadPipelineProvider } from 'features/file';
import { useSessionLocation } from 'features/location';
import { useAppDispatch } from 'features/main/hooks';
import { useUserSettings } from 'features/settings';
import { QRScanModal } from 'features/upload-exams/fragments/QRScanModal';

import { UploadWindow } from '../../file/fragments';
import { DEFAULT_FINAL_REPORT_VALUES, InitialValues, ShareType } from '../constants';
import { PatientEditService, PatientGridService } from '../services';
import { PatientFileModalFeature } from '../types';
import { PatientEditProps } from '../types/PatientEditProps';
import { PatientFormValues } from '../types/PatientFormValues';
import { ITransplantModal } from './ITransplantModal';
import { MergePatientModal } from './MergePatientModal';
import { PatientEditExamGrid } from './PatientEditExamGrid';
import { PatientEditHeader } from './PatientEditHeader';
import { PatientEditModal } from './PatientEditModal';
import { PatientEditWindow } from './PatientEditWindow';
import { PatientFileViewModal } from './PatientFileViewModal';
import { PatientViewFinalReport } from './PatientViewFinalReport';
import { ShareModal } from './ShareModal';
import { UploadFileModal } from './UploadFileModal';

export const PatientEdit: FunctionComponent<PatientEditProps> = ({ isEditMode, patientId }) => {
  const dispatch = useAppDispatch();
  const { sessionLocation } = useSessionLocation(true);
  const id = useValidatedParam('id', 'integer', true);
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { homePageUrl, reactPatientFormPage, legacyBaseUrl, reactUploadPage } = useUserSettings(true);
  const [patient, setPatient] = useState<PatientModel | null>(null);
  const [donorForm, setDonorForm] = useState(InitialValues.donorForm);
  const [isFormDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('Edit');
  const [iTransplantData, setITransplantData] = useState<ITransplantMergeModel | null>(null);
  const exams = useSelector(ExamSelectors.getAll);

  const total = useSelector(ExamSelectors.getTotal);
  const [dataState, setDataState] = useState(PatientGridService.getDefaultPatientExamDataState(id == null ? patientId || 0 : id));
  const [searchValue, setSearchValue] = useState('');
  const [activeExamToggle, setActiveExamToggle] = useState(true);
  const [shareToggle, setShareToggle] = useState(false);
  const [selectedExams, setSelectedExams] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedStatuses, setSelectedStatuses] = useState<any[]>([]);
  const [columnsState, setColumnsState] = useState<Record<string, ColumnState>>({});
  const [isITransplantVisible, setIsITransplantVisible] = useState(false);
  const [isMergeDonorVisible, setIsMergeDonorVisible] = useState(false);
  const [isDonorViewFilesVisible, setIsDonorViewFilesVisible] = useState(false);
  const [isDicomImagesVisible, setIsDicomImagesVisible] = useState(false);
  const [isDonorViewFinalReportVisible, setIsDonorViewFinalReportVisible] = useState(false);
  const [selectedExamStatus, setSelectedExamStatus] = useState('');
  const [finalReport, setFinalReport] = useState<FileModel>(DEFAULT_FINAL_REPORT_VALUES);
  const [selectedExamId, setSelectedExamId] = useState(0);
  const [gridData, setGridData] = useState(exams);
  const [isShareModalVisible, { setTrue: setShareModalVisible, setFalse: setShareModalHidden }] = useBoolean(false);
  const [isUploadFileModalVisible, { setTrue: setUploadFileModalVisible, setFalse: setUploadFileModalHidden }] = useBoolean(false);
  const [shareExamId, setShareExamId] = useState<number | null>(null);
  const [isActivityLogModalOpen, { setTrue: openActivityLogModal, setFalse: closeActivityLogModal }] = useBoolean(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [readRequestMode, setReadRequestMode] = useState(false);
  const [activeTab, setActiveTab] = useState<'Active' | 'Inactive'>('Active');

  const isOpo = patient?.locationType === 'OPO';
  const patientVerbiage = isOpo ? 'Donor' : 'Patient';

  // events
  const handleChangeStatusSubmit = useEvent(async (data: ChangeExamStatusModel) => {
    await apiClient.exams.changeStatus(data);

    // fetch exams to get the latest status updates
    fetchExams();

    // dispatch(ExamActions.changeStatus(data)).then(() => {
    //   // fetch exams to get the latest status updates
    //   fetchExams();
    // });
  });

  const handleToggleActiveExams = useEvent((tab: 'Active' | 'Inactive') => {
    const clonedDataStateFilter = cloneDeep(dataState);

    if (clonedDataStateFilter.filter == null) {
      throw new Error('Unable to proceed because the exam grid filter is undefined.');
    }

    const activeFilter = clonedDataStateFilter.filter.filters.find((f) => 'field' in f && f.field === 'active') as FilterDescriptor | undefined;

    if (activeFilter == null) {
      throw new Error('Unable to proceed because the "active" filter on the exam grid was not found.');
    }

    const value = tab === 'Active';

    activeFilter.value = value;
    setActiveExamToggle(value);
    setDataState(clonedDataStateFilter);
  });

  const handleActiveTabClick = useEvent(() => {
    const value = 'Active';
    setActiveTab(value);
    handleToggleActiveExams(value);
  });

  const handleInactiveTabClick = useEvent(() => {
    const value = 'Inactive';
    setActiveTab(value);
    handleToggleActiveExams(value);
  });

  // need to deal with the filters.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataStateChange = useEvent((changeEvent: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const checkPatientFilter = changeEvent.dataState.filter.filters.find((e: { field: string }) => e.field === 'patientId');
    if (!checkPatientFilter) {
      // add new filter
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      changeEvent.dataState.filter.filters.push({
        field: 'patientId',
        operator: 'eq',
        value: id,
      });
    }
    setDataState(changeEvent.dataState);
  });

  const handleShareChange = useEvent(async (dataItem: ExamModel) => {
    if (dataItem) {
      const newData = await Promise.all(
        gridData.map(async (item: ExamModel) => {
          if (item.id === dataItem.id) {
            try {
              if (dataItem.id) {
                await apiClient.exams.patchExam(dataItem.id, { Share: !item.share });
                NotificationsService.displaySuccess('Exam updated successfully');
              }
              NotificationsService.displayError('Error updating exam');
            } catch (error) {
              NotificationsService.displayError('Error updating exam');
            }
            return { ...item, share: !item.share };
          }
          return item;
        }),
      );
      setGridData(newData);
    }
  });

  const handleViewAttachments = useEvent((dataItem: ExamModel) => {
    // get the selected exam id
    setSelectedExamId(dataItem.id ?? 0);
    setSelectedExamStatus(dataItem.statusType ?? '');
    // toggle the donor diaglog
    setIsDonorViewFilesVisible(!isDonorViewFilesVisible);
  });

  const handleViewDicomImages = useEvent((dataItem: ExamModel) => {
    window.open(`/file-viewer/${dataItem.id}?feature=exam`, '_blank');
  });

  const handleViewFinalReport = useEvent((dataItem: ExamModel) => {
    // get the final report document
    if (dataItem.finalReportFile) setFinalReport(dataItem.finalReportFile);
    // toggle the donor diaglog
    setIsDonorViewFinalReportVisible(!isDonorViewFinalReportVisible);
  });

  const handleExamUpdate = useEvent(() => {
    console.log('Exam updated');
    fetchExams();
  });

  const fetchExams = useCallback(() => {
    let resultingDataState = dataState;
    if (searchValue) {
      resultingDataState = addFiltersToDataState(dataState, [getSearchFilter(searchValue, ExamsGridService.getColumns())]);
    }

    //filter exams by selected location
    if (resultingDataState?.filter?.filters) {
      resultingDataState.filter.filters = resultingDataState.filter.filters.filter((f) => 'field' in f && f.field !== 'Location_Id');

      if (sessionLocation?.id != null) {
        resultingDataState.filter.filters.push({
          field: 'Location_Id',
          operator: 'eq',
          value: sessionLocation?.id,
        });
      }
    }

    dispatch(
      ExamActions.getAllForKendoGrid({
        dataState: resultingDataState,
        quickFilter: ExamStatus.ALL.name,
        includeFiles: true,
      }),
    );
  }, [dataState, dispatch, searchValue, sessionLocation?.id]);

  useEffect(() => {
    const fetchDonor = async () => {
      const patientResponse = await apiClient.patientClient.getPatient(id);

      setPatient(patientResponse);
      setDonorForm(PatientEditService.copyModelToForm(patientResponse));
    };

    if (id) {
      fetchDonor();
    }
  }, [apiClient, id]);

  useEffect(() => {
    const selected = Object.keys(pickBy(selectedExams, (isSelected) => isSelected));
    const statusTypes = exams.filter((exam) => selected.includes(exam?.id == null ? '' : exam.id.toString())).map((exam) => exam?.statusType);
    setSelectedStatuses(statusTypes);

    setGridData(PatientGridService.initializePatientGrid(exams, isOpo, patient));
  }, [exams, isOpo, patient, selectedExams]);

  useEffect(() => {
    fetchExams();
  }, [dispatch, fetchExams, sessionLocation?.id]);

  const handleFormToggle = () => {
    setShowEditModal(!showEditModal);
  };
  const handleSubmit: SubmitHandler<PatientFormValues> = useEvent(async (data) => {
    if (patient == null) {
      throw new Error('Cannot save patient because the original record was not retrieved.');
    }

    setIsDisabled(!isFormDisabled);
    setButtonText('Edit');

    const updatedPatientModel = PatientEditService.copyFormToModel(data, patient);
    try {
      await apiClient.patientClient.updatePatient(updatedPatientModel);
      // update the patient header
      setPatient(updatedPatientModel);
      setDonorForm(PatientEditService.copyModelToForm(updatedPatientModel));
      NotificationsService.displaySuccess('Donor updated successfully');
      if (selectedExamId && readRequestMode) {
        navigate(`/exam/${selectedExamId}/read-request`);
      }
    } catch (error) {
      NotificationsService.displayError('Error updating donor');
    } finally {
      setShowEditModal(false);
    }
  });

  const toggleITransplantDialog = useEvent(() => {
    setIsITransplantVisible(!isITransplantVisible);
  });

  const toggleMergeDonorDialog = useCallback(() => {
    setIsMergeDonorVisible((prevIsMergeDonorVisible) => !prevIsMergeDonorVisible);
  }, [setIsMergeDonorVisible]);

  const handleITransplantStatusChange = useEvent((event: UploadOnStatusChangeEvent) => {
    const isUploaded = 4;
    if (event.affectedFiles[0].status === isUploaded) {
      const iTransplantModel = event.response?.response.result as ITransplantMergeModel;

      if (iTransplantModel.hasMatch) {
        NotificationsService.displaySuccess('Found existing donor match');
        setITransplantData(null);
        toggleITransplantDialog();
      } else {
        setITransplantData(iTransplantModel);
      }
    }
  });

  const handleITransplantUpdate = useEvent(async () => {
    if (!iTransplantData?.currentPatient) {
      NotificationsService.displayWarning('No donor match found to update');
      return;
    }
    if (iTransplantData?.newPatient && id) {
      try {
        await apiClient.patientClient.updatePatient({
          ...iTransplantData?.newPatient,
          id: iTransplantData?.currentPatient.id,
        });
        NotificationsService.displaySuccess('Donor created successfully');
        setITransplantData(null);
        toggleITransplantDialog();
        setPatient(iTransplantData.newPatient);
        setDonorForm(PatientEditService.copyModelToForm(iTransplantData.newPatient));
      } catch (error) {
        NotificationsService.displayError('Error creating donor');
      }
    }
  });

  const handleShareClick = useEvent(() => {
    setShareModalVisible();
    setShareExamId(null);
  });

  const handleShareGridClick = useEvent((exam: ExamModel | null | undefined) => {
    setShareModalVisible();
    setShareExamId(exam?.id ?? null);
  });

  const handleUploadExamClick = useEvent(() => {
    if (reactUploadPage) {
      navigate(`/upload?PatientId=${id}`);
    } else {
      window.location.href = `${legacyBaseUrl}/DICOM/UploadDICOM?PatientId=${id}`;
    }
    // TODO - implement upload exam per patient
    // navigate(`/upload/patient-2/${patient?.id}`);
  });

  const handleUploadFileClick = useEvent(() => {
    setUploadFileModalVisible();
  });

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'home',
        text: 'Home',
        icon: <StyledIcon icon={homeIcon} />,
        navigateToExternal: homePageUrl,
      },
      {
        id: 'patient',
        text: patient?.locationType === 'OPO' ? 'Donor' : 'Patient',
        ...{
          navigateTo: reactPatientFormPage ? '/patient-2/' : undefined,
          navigateToExternal: reactPatientFormPage ? undefined : '/patient',
        },
      },
    ],
    [homePageUrl, patient?.locationType, reactPatientFormPage],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBreadcrumbClick = useEvent((event: any) => {
    const selectedItem = findOrThrow(breadcrumbs, (item) => item.id === event.id, `Could not find selected item id: ${event.id}.`);

    if ('navigateTo' in selectedItem && hasText(selectedItem.navigateTo)) {
      navigate(selectedItem.navigateTo);
      return;
    } else if (hasText(selectedItem.navigateToExternal)) {
      window.location.href = selectedItem?.navigateToExternal;
    } else {
      throw new Error('Could not navigate because the selected breadcrumb item does not have a valid route.');
    }
  });

  // age range of the patient
  const [ageRanges, setAgeRanges] = useState<PatientAgeRangeModel[] | undefined>();

  useEffect(() => {
    const fetchAgeRanges = async () => {
      const patientAgeRangeResponse = await apiClient.patientClient.getPatientAgeRange();

      setAgeRanges(patientAgeRangeResponse);
    };

    fetchAgeRanges();
  }, [apiClient.patientClient]);

  const handleFormChange = useCallback((values: PatientFormValues) => {
    setDonorForm(values);
  }, []);

  const handleReadRequest = useEvent((exam: ExamModel) => {
    if (isOpo && !PatientEditService.validateDonorForm(patient)) {
      setSelectedExamId(exam.id ?? 0);
      setReadRequestMode(true);
      setShowEditModal(true);
    } else {
      navigate(`/exam/${exam.id}/read-request`);
    }
  });

  const closeEditWindow = () => {
    setReadRequestMode(false);
    setShowEditModal(false);
  };

  const [isLinkCreating, setIsLinkCreating] = useState(false);

  const [isQrModalVisible, setIsQrModalVisible] = useState<boolean>(false);
  const [linkId, setLinkId] = useState<string | null>();

  const handleQrModalClose = useEvent(() => {
    setIsQrModalVisible(false);
    setLinkId(null);
    setIsDonorViewFilesVisible(true);
  });

  const handleQrButtonClick = useEvent(async (event: unknown) => {
    setIsLinkCreating(true);
    setIsDonorViewFilesVisible(!isDonorViewFilesVisible);
    try {
      const linkId = crypto.randomUUID();
      if (selectedExamId && selectedExamId > 0) {
        const expireOn = dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
        await apiClient.studyShare.addStudyShare({
          id: 0,
          linkId: linkId,
          email: null,
          sharePassword: null,
          shareType: ShareType.Link,
          sharePatientId: null,
          studyShareExams: [{ exam_id: selectedExamId }],
          expireOn: expireOn,
          dateCreated: '',
          message: null,
          bypassSplashPage: true,
        });
      }
      setLinkId(linkId);
      setIsQrModalVisible(true);
    } finally {
      setIsLinkCreating(false);
    }
  });

  //set grid row item as expanded or collapsed
  const handleExpandChange = (event: GridExpandChangeEvent) => {
    const newData = gridData?.map((item: ExamModel) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setGridData(newData);
  };

  return (
    <UploadPipelineProvider>
      {id && <ActivityLogModal show={isActivityLogModalOpen} entityType="patient" id={id} onClose={closeActivityLogModal} />}
      <Page>
        <PageHeader showSessionLocation />
        <StyledPatientContainer>
          <StyledPatientEditHeader
            onBreadcrumbClick={handleBreadcrumbClick}
            buttonText={buttonText}
            patient={patient}
            isEditMode={isEditMode}
            patientFormProps={{
              initialValues: donorForm,
              isFormDisabled,
              onFormToggle: handleFormToggle,
              buttonText,
              onSubmit: handleSubmit,
              onChange: handleFormChange,
              ageRanges,
            }}
          />
          {isEditMode && (
            <StyledActionsContainer>
              <Button size={ComponentSizes.LARGE} type="button" variant={ButtonVariants.SECONDARY} iconClass="fa fa-share-nodes" onClick={handleShareClick}>
                Share {patientVerbiage}
              </Button>
              <Button size={ComponentSizes.LARGE} type="button" variant={ButtonVariants.SECONDARY} iconClass="fa fa-upload" onClick={handleUploadExamClick}>
                Upload Exam
              </Button>
              <Button size={ComponentSizes.LARGE} type="button" variant={ButtonVariants.SECONDARY} iconClass="fa fa-upload" onClick={handleUploadFileClick}>
                Upload File
              </Button>
              <Button size={ComponentSizes.LARGE} type="button" variant={ButtonVariants.SECONDARY} onClick={toggleITransplantDialog} iconClass="fa fa-file">
                Upload iTransplant
              </Button>
              <Button
                size={ComponentSizes.LARGE}
                type="button"
                variant={ButtonVariants.SECONDARY}
                onClick={toggleMergeDonorDialog}
                iconClass="fa fa-object-ungroup"
              >
                Merge {patientVerbiage}
              </Button>
              {/*<Button size={ComponentSizes.LARGE} type="button" variant={ButtonVariants.SECONDARY} onClick={openActivityLogModal} iconClass="fa fa-table">*/}
              {/*  Activity Log*/}
              {/*</Button>*/}
              <StyledToggleActiveExamsContainer>
                <TabsContainer>
                  <TabButton type="button" selected={activeTab === 'Active'} roundedCorners="left" onClick={handleActiveTabClick}>
                    Exams
                  </TabButton>
                  <TabButton type="button" selected={activeTab === 'Inactive'} roundedCorners="right" onClick={handleInactiveTabClick}>
                    Deleted Exams
                  </TabButton>
                </TabsContainer>
              </StyledToggleActiveExamsContainer>
            </StyledActionsContainer>
          )}
          <StyledPatientExamGrid
            isEditMode={isEditMode}
            donorToolbar
            columnsState={columnsState}
            dataState={dataState}
            gridData={gridData}
            handleViewFinalReport={handleViewFinalReport}
            handleViewAttachments={handleViewAttachments}
            handleViewDicomImages={handleViewDicomImages}
            handleChangeStatusSubmit={handleChangeStatusSubmit}
            handleDataStateChange={handleDataStateChange}
            activeExamToggle={activeExamToggle}
            handleShareChange={handleShareChange}
            setShareToggle={setShareToggle}
            shareToggle={shareToggle}
            searchValue={searchValue}
            selectedExams={selectedExams}
            selectedStatuses={selectedStatuses}
            setColumnsState={setColumnsState}
            setSelectedExams={setSelectedExams}
            total={total}
            handleShareGridClick={handleShareGridClick}
            handleReadClick={handleReadRequest}
            handleExpandChange={handleExpandChange}
            handleExamUpdate={handleExamUpdate}
          />
          {isITransplantVisible && (
            <ITransplantModal
              mergeDonorData={iTransplantData}
              onUpdateDonor={handleITransplantUpdate}
              onStatusChange={handleITransplantStatusChange}
              key={id}
              donorId={id}
              toggleITransplantDialog={toggleITransplantDialog}
              visible={isITransplantVisible}
            />
          )}

          {isMergeDonorVisible && (
            <MergePatientModal
              isOPO={isOpo}
              showDonorGrid={true}
              key={isMergeDonorVisible.toString()}
              srcDonorId={0}
              destDonorId={id}
              toggleDialog={toggleMergeDonorDialog}
              targetPatient={patient}
            />
          )}
          {patient != null && isUploadFileModalVisible && <UploadFileModal patient={patient} onClose={setUploadFileModalHidden} />}
          {selectedExamId > 0 && linkId && <QRScanModal show={isQrModalVisible} onClose={handleQrModalClose} examId={selectedExamId} linkId={linkId} />}
          {isDonorViewFilesVisible && <UploadWindow onClose={handleViewAttachments} examId={selectedExamId}></UploadWindow>}
          <PatientFileViewModal
            toggleDialog={handleViewDicomImages}
            visible={isDicomImagesVisible}
            examStatus={selectedExamStatus}
            examId={selectedExamId}
            isOPO={isOpo}
            feature={PatientFileModalFeature.IMAGES}
            showEditBtn
            showDeleteBtn
            isQrModalVisible={false}
            linkId=""
          />
          <PatientViewFinalReport toggleDialog={handleViewFinalReport} visible={isDonorViewFinalReportVisible} file={finalReport} />

          {patient && (
            <ShareModal show={isShareModalVisible} patientId={patient.id} examId={shareExamId} onHide={setShareModalHidden} onPatientChange={setPatient} />
          )}
        </StyledPatientContainer>
        <PatientEditWindow
          isOpo={isOpo}
          ageRanges={ageRanges}
          handleSubmit={handleSubmit}
          patient={patient}
          show={showEditModal}
          onClosed={closeEditWindow}
          isRequestMode={readRequestMode}
        />
      </Page>
    </UploadPipelineProvider>
  );
};

PatientEdit.displayName = 'PatientEdit';

const mediaQueryMobileWidth = '600px';

const StyledPatientContainer = styled.div`
  grid-template-rows: min-content min-content min-content min-content 1fr;
  display: grid;

  .accordion-item {
    background-color: transparent;
  }

  img {
    border-radius: 4px;
  }
`;

const StyledIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledPatientExamGrid = styled(PatientEditExamGrid)`
  overflow: auto;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: ${({ theme }) => `${theme.space.spacing20} 0 ${theme.space.spacing20} 1px`};
  background-color: transparent;
  width: 100%;

  .k-button {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: ${mediaQueryMobileWidth}) {
    flex-direction: column;
    width: calc(100vw - 75px);
  }
`;

const StyledPatientEditHeader = styled(PatientEditHeader)`
  margin-right: ${({ theme }) => theme.space.spacing10};
`;

const StyledToggleActiveExamsContainer = styled.div`
  && span {
    margin-right: ${({ theme }) => theme.space.spacing20};
  }
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: 20px;
  }
`;
