import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { PatientGridModel, PatientMatchQueryModel, PatientModel } from 'models';
import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { hasText } from 'core/utils';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class PatientClient {
  constructor(private httpClient: HttpClient) {
    this.getAllForKendoGrid = this.getAllForKendoGrid.bind(this);
    this.getPatient = this.getPatient.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.mergePatient = this.mergePatient.bind(this);
    this.createPatient = this.createPatient.bind(this);
    this.getPatientAgeRange = this.getPatientAgeRange.bind(this);
    this.getPatientMatch = this.getPatientMatch.bind(this);
  }

  public async getAllForKendoGrid(dataState: DataSourceRequestState, filter: string | null, signal?: AbortSignal | null) {
    const options = await this.httpClient.createStandardOptions('GET', null, { signal });
    const queryStr = toDataSourceRequestString(dataState);
    const filterQueryStr = hasText(filter) ? `&keyword=${filter}` : '';
    const result = await this.httpClient.fetchAndParse<DataResult<PatientGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/grid?${queryStr}${filterQueryStr}`, options),
    );
    return result;
  }

  public async getPatient(patientId: number, authMode: 'msal-required' | 'share-required' = 'msal-required', signal?: AbortSignal) {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode,
      signal,
    });
    const patient = await this.httpClient.fetchAndParse<PatientModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/${patientId}`, options));
    return patient;
  }

  public async updatePatient(patientModel: PatientModel) {
    const options = await this.httpClient.createStandardOptions('PUT', patientModel);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/`, options));
  }

  public async mergePatient(sourceId: number, destId: number, patchDoc: unknown[]) {
    const options = await this.httpClient.createStandardOptions('PATCH', patchDoc);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/${sourceId}/merge/${destId}`, options));
  }

  public async createPatient(patientModel: PatientModel) {
    const options = await this.httpClient.createStandardOptions('POST', patientModel);
    const result = await this.httpClient.fetchAndParse<{ id: number }>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/`, options));
    return result.id;
  }

  public async getPatientAgeRange() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<PatientAgeRangeModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/getPatientAgeRange/`, options),
    );
    return result;
  }

  public async getPatientMatch(query: PatientMatchQueryModel) {
    const options = await this.httpClient.createStandardOptions('POST', query);
    const result = await this.httpClient.fetchAndParse<PatientModel | null>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/GetPatientMatch`, options),
    );
    return result;
  }
}
