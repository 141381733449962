import { ComponentType, FunctionComponent, ReactElement, cloneElement, useCallback, useMemo, useState } from 'react';

import { DataResult } from '@progress/kendo-data-query';
import { PagerProps as KendoPagerProps } from '@progress/kendo-react-data-tools';
import {
  GridColumn,
  GridHeaderSelectionChangeEvent,
  GridRowProps,
  GridSelectionChangeEvent,
  Grid as KendoGrid,
  getSelectedState,
} from '@progress/kendo-react-grid';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import styled, { DefaultTheme } from 'styled-components';

import { useEvent } from '../../hooks';
import { Pager } from '../Pager';
import { ProgressSpinnerOverlay } from '../ProgressSpinner';
import { ComponentSizes } from '../constants';
import { theme } from '../theme';
import { DataTableProps } from './DataTableProps';
import { SelectedState } from './SelectedState';
import { DataTableVariants, SELECTABLE_PRESETS } from './constants';
import { DataTableActionsContext, DataTableExtrasContext } from './contexts';

type StyledGridProps = {
  $size: ComponentSizes;
  $variant: DataTableVariants;
  theme: DefaultTheme;
};

export const DataTable: FunctionComponent<DataTableProps> = ({
  className,
  children,
  data,
  dataItemKey = 'id',
  resizable = true,
  selectable = false,
  selectedState,
  selectedField = 'selected',
  variant = DataTableVariants.PRIMARY,
  size = ComponentSizes.SMALL,
  pager = Pager,
  pageable,
  isLoading = false,
  search,
  rowHeight = theme.sizes.dataTableRowHeightMedium,
  onSelectionChange,
  onContextMenuOpen,
  actions = null,
  ...rest
}) => {
  const [gridEle, setGridEle] = useState<HTMLDivElement | null>(null);

  const extrasContext = useMemo(() => ({ search, dataItemKey }), [dataItemKey, search]);

  const transformedData = useMemo(
    () => transformData(data, selectedState, dataItemKey, selectable, selectedField),
    [data, selectedState, dataItemKey, selectable, selectedField],
  );

  const areAllSelected = useMemo(() => {
    if (transformedData == null || dataItemKey == null || selectedState == null) return false;

    const dataArray = isDataResult(transformedData) ? transformedData.data : transformedData;

    return Boolean(dataArray.findIndex((item) => !selectedState[item[dataItemKey]]) === -1 && dataArray.length);
  }, [dataItemKey, selectedState, transformedData]);

  const handleSelectionChange = useEvent((event: GridSelectionChangeEvent) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState ?? {},
      dataItemKey,
    }) as SelectedState;

    onSelectionChange?.(newSelectedState, event);
  });

  const handleHeaderSelectionChange = useEvent((event: GridHeaderSelectionChangeEvent) => {
    const checkboxElement = event.syntheticEvent.target;

    if (checkboxElement instanceof HTMLInputElement) {
      const newChecked = checkboxElement.checked;
      const newSelectedState: SelectedState = {};
      event.dataItems.forEach((item) => {
        newSelectedState[item[dataItemKey]] = newChecked;
      });

      onSelectionChange?.(newSelectedState, event);
    } else {
      throw new Error('Unable to handle selectable header change event because the target element is not an instance of HTMLInputElement.');
    }
  });

  const rowRenderForContextMenu = useCallback(
    (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rowProps: Record<string, any> = {
        ...row.props,
        onContextMenu: (event: MouseEvent) => {
          event.preventDefault();

          // highlight and select row that is right-clicked
          onSelectionChange?.({ [props.dataItem[dataItemKey]]: true }, event);

          onContextMenuOpen?.(event, props.dataItem);
        },
      };

      return cloneElement(row, rowProps, props.children);
    },
    [dataItemKey, onContextMenuOpen, onSelectionChange],
  );

  return (
    <DataTableExtrasContext.Provider value={extrasContext}>
      <DataTableActionsContext.Provider value={actions}>
        {gridEle && createPortal(<ProgressSpinnerOverlay show={isLoading} />, gridEle)}
        <StyledGrid
          {...rest}
          ref={(node: { element: HTMLDivElement } | null) => setGridEle(node?.element ?? null)}
          $variant={variant}
          $size={size}
          className={cn(className, { 'show-loading-indicator': isLoading })}
          data={transformedData}
          dataItemKey={dataItemKey}
          resizable={resizable}
          selectable={selectable ? SELECTABLE_PRESETS.Selectable : SELECTABLE_PRESETS.NoSelect}
          selectedField={selectedField}
          pager={pager as ComponentType<KendoPagerProps>}
          pageable={pageable}
          onSelectionChange={handleSelectionChange}
          onHeaderSelectionChange={handleHeaderSelectionChange}
          rowRender={onContextMenuOpen ? rowRenderForContextMenu : undefined}
          navigatable={false}
          rowHeight={rowHeight}
        >
          {selectable && <GridColumn field={selectedField} filterable={false} headerSelectionValue={areAllSelected} reorderable={false} width="30px" />}
          {children}
        </StyledGrid>
      </DataTableActionsContext.Provider>
    </DataTableExtrasContext.Provider>
  );
};

DataTable.displayName = 'DataTable';

function transformData(
  data: DataTableProps['data'],
  selectedState: SelectedState | null | undefined,
  dataItemKey: DataTableProps['dataItemKey'],
  selectable: boolean,
  selectedField: string | undefined,
) {
  if (data == null || !selectable || dataItemKey == null || selectedField == null) return data;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newData: any[] = (isDataResult(data) ? data.data : data).map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newItem: Record<string, any> = {
      ...item,
    };

    newItem[selectedField] = isSelected(item[dataItemKey], selectedState ?? {}, selectedField);

    return newItem;
  });

  if (isDataResult(data)) {
    const newDataResult: DataResult = {
      ...data,
      data: newData,
    };

    return newDataResult;
  } else if (Array.isArray(data)) {
    return newData;
  }

  throw new Error('Unable to transform data because it doesn\'t match the expected types of either "DataResult" or "any[]".');
}

function isSelected(dataKey: string, selectedState: SelectedState, selectedField: string | undefined) {
  if (selectedField == null) {
    return false;
  }

  return dataKey in selectedState ? selectedState[dataKey] : false;
}

function isDataResult(data: DataTableProps['data']): data is DataResult {
  if (data == null) {
    return false;
  }

  if ('data' in data && Array.isArray(data.data) && 'total' in data && typeof data.total === 'number') {
    return true;
  }

  return false;
}

function resolvePadding({ $size, theme }: StyledGridProps) {
  switch ($size) {
    case ComponentSizes.SMALL: {
      return `${theme.space.dataTableRowPaddingVerticalSmall} ${theme.space.spacing10}`;
    }
    case ComponentSizes.MEDIUM: {
      return `${theme.space.dataTableRowPaddingVerticalMedium} ${theme.space.spacing30}`;
    }
    case ComponentSizes.LARGE: {
      return `${theme.space.dataTableRowPaddingVerticalLarge} ${theme.space.spacing40}`;
    }
    default: {
      return `${theme.space.dataTableRowPaddingVerticalMedium} ${theme.space.spacing30}`;
    }
  }
}

function resolveBackgroundColor({ theme }: StyledGridProps) {
  return theme.colors.palette.white;
}

function resolveHeaderBackgroundColor({ theme, $variant }: StyledGridProps) {
  if ($variant === DataTableVariants.PRIMARY) {
    return theme.colors.palette.blues[5];
  }

  if ($variant === DataTableVariants.SECONDARY) {
    return theme.colors.palette.white;
  }

  return undefined;
}

const StyledGrid = styled(KendoGrid)<StyledGridProps>`
  position: relative; // This is required because the loading indicator overlay uses position: absolute.
  background-color: ${resolveBackgroundColor};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.base};
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto;

  & th[class=' k-sorted'] {
    background-color: white;
  }

  & .k-grid-toolbar {
    background-color: ${resolveBackgroundColor};
    padding: ${({ theme }) => theme.space.spacing10};
  }

  & .k-grid-content {
    overflow: auto;
  }

  & thead > tr {
    background-color: ${resolveHeaderBackgroundColor};

    & > th {
      box-shadow: -10px 0 0px -9px rgba(0, 0, 0, 0.06);
    }
  }

  && .k-grid-header {
    background-color: ${resolveBackgroundColor};
    border-bottom: ${({ theme }) => theme.borderWidths.base} solid rgba(0, 0, 0, 0.06);
    padding-inline-end: 1px;

    th {
      border-left: none;
      padding: 0;
      white-space: normal;
      vertical-align: middle;
      text-align: center;
    }

    & th:first-child {
      padding-left: ${({ selectable, theme }) => (selectable ? theme.space.spacing30 : 0)};
    }

    & .k-filtercell {
      padding-left: 0;
    }

    & .k-filter-row {
      background-color: ${resolveBackgroundColor};
      & th {
        padding: ${({ theme }) => theme.space.spacing10};
      }
    }

    & .k-filtercell-operator {
      display: none;
    }

    & .k-filtercell input {
      font-size: 0.8rem;
      padding: 0;
    }

    & .k-filtercell .k-select {
      display: none;
    }

    & .k-filtercell .k-button {
      display: none;
    }
  }

  & .k-master-row {
    & td,
    &:last-child > td:not(.k-grid tr.k-selected > td) {
      border-left: none;
      border-bottom: ${({ theme }) => theme.borderWidths.base} solid rgba(0, 0, 0, 0.06);
      font-size: ${({ theme }) => theme.fontSizes.body};
      line-height: ${({ theme }) => theme.lineHeights.body};
      padding: ${resolvePadding};
      text-overflow: ellipsis;
      white-space: normal;
    }

    &.k-alt {
      background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
    }

    &:hover td {
      background-color: ${({ theme }) => theme.colors.palette.blues[3]};
      color: ${({ theme }) => theme.colors.palette.white};
    }

    & .k-checkbox {
      margin-left: ${({ theme }) => theme.space.spacing20};
    }
  }

  & .k-grid-container {
    & .k-sorted {
      background-color: inherit;
    }
  }
  & .k-header {
    color: ${({ theme }) => theme.colors.palette.white};
    justify-content: center;

    border-left: ${({ theme }) => theme.borderWidths.base} solid rgba(0, 0, 0, 0.06);
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.body};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: ${({ theme }) => theme.lineHeights.body};
    padding-bottom: ${({ theme }) => theme.space.spacing10};
    padding-left: 0;
    padding-top: ${({ theme }) => theme.space.spacing10};

    & .k-sort-icon {
      color: ${({ theme }) => theme.colors.palette.white};
    }
  }
`;
