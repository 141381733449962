import { memo } from 'react';

import { useEvent } from 'core/hooks';

import { FileInputFileItemContainerProps } from './FileInputFileItemContainerProps';

export const FileInputFileItemContainer = memo<FileInputFileItemContainerProps>(({ disabled, file, fileItemAs: FileItemAs, onRemove }) => {
  const handleRemove = useEvent(() => {
    onRemove(file.fileId);
  });

  return <FileItemAs disabled={disabled} file={file} onRemove={handleRemove}></FileItemAs>;
});

FileInputFileItemContainer.displayName = 'FileInputFileItemContainer';
