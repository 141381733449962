import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Button } from './Button';
import { ComponentSizes } from '../constants';

export const TabButton: FunctionComponent<{
  selected: boolean;
  roundedCorners: 'left' | 'right';
  children: any;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: any;
}> = ({ selected, roundedCorners, children, type, onClick }) => {
  return (
    <StyledTabButton size={ComponentSizes.LARGE} $selected={selected} $roundedCorners={roundedCorners} type={type} onClick={onClick}>
      {children}
    </StyledTabButton>
  );
};

export const TabButtonSeparator: FunctionComponent = () => {
  return <StyledTabButtonSeparator />;
};

export const TabsContainer: FunctionComponent<{ children: any }> = ({ children }) => {
  return <StyledTabsContainer>{children}</StyledTabsContainer>;
};

const StyledTabButton = styled(Button)<{
  $selected: boolean;
  $roundedCorners: 'left' | 'right';
}>`
  background-color: inherit;
  border-bottom-color: ${({ theme, $selected }) => ($selected ? theme.colors.primary : theme.colors.palette.grayscale[4])};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.primary : theme.colors.palette.grayscale[9])};
  z-index: ${({ $selected }) => ($selected ? '1' : '0')};
  border-radius: 0;
  &&:hover,
  &&:focus {
    background-color: inherit;
    border-bottom-color: ${({ theme, $selected }) => ($selected ? theme.colors.primary : theme.colors.palette.grayscale[4])};
  }
`;

const StyledTabButtonSeparator = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const StyledTabsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
