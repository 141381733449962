import { forwardRef, memo, useImperativeHandle, useRef } from 'react';

import { Button as KendoButton } from '@progress/kendo-react-buttons';
import styled from 'styled-components';

import { Button } from '../Button';
import { FileInputSelectButtonHandle } from './FileInputSelectButtonHandle';
import { FileInputSelectButtonProps } from './FileInputSelectButtonProps';

export const FileInputDefaultSelectButton = memo(
  forwardRef<FileInputSelectButtonHandle, FileInputSelectButtonProps>(({ tabIndex, multiple, onClick }, ref) => {
    const buttonRef = useRef<KendoButton | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          buttonRef.current?.element?.focus();
        },
      }),
      [],
    );

    return (
      <StyledDefaultSelectButton ref={buttonRef} tabIndex={tabIndex} onClick={onClick}>
        Select {multiple ? 'Files' : 'File'}
      </StyledDefaultSelectButton>
    );
  }),
);

FileInputDefaultSelectButton.displayName = 'FileInputDefaultSelectButton';

const StyledDefaultSelectButton = styled(Button)``;
