import { DEFAULT_HTTP_REQUEST_OPTIONS } from '../constants';
import { AnonymousUserError, ApiResponse, HttpRequestOptionsType } from '../types';
import { ApiRequestError } from '../types/ApiRequestError';
import { AdditionalRequestOptions } from '../types/AdditionalRequestOptions';

export class HttpClient {
  public getAccessToken: null | (() => Promise<string>) = null;

  public getShareToken: null | (() => Promise<string>) = null;

  constructor() {
    this.createStandardOptions = this.createStandardOptions.bind(this);
    this.fetchAndParse = this.fetchAndParse.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchBlob = this.fetchBlob.bind(this);
    this.setAccessTokenFunction = this.setAccessTokenFunction.bind(this);
    this.setShareTokenFunction = this.setShareTokenFunction.bind(this);
  }

  public setAccessTokenFunction(newAccessTokenFunction: null | (() => Promise<string>)) {
    this.getAccessToken = newAccessTokenFunction;
  }

  public setShareTokenFunction(newShareTokenFunction: null | (() => Promise<string>)) {
    this.getShareToken = newShareTokenFunction;
  }

  public async createStandardOptions(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    data: unknown,
    options?: HttpRequestOptionsType,
    additionalRequestOptions?: AdditionalRequestOptions,
  ) {
    const { isFormData } = additionalRequestOptions || {};
    const normalizedOptions: HttpRequestOptionsType = options == null ? DEFAULT_HTTP_REQUEST_OPTIONS : { ...DEFAULT_HTTP_REQUEST_OPTIONS, ...options };

    // Stop if the request has been aborted.
    normalizedOptions.signal?.throwIfAborted?.();

    // Initialize headers with default values.
    const headers: HeadersInit = isFormData
      ? { Accept: 'application/json' }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };

    // Add the authorization header.
    if (normalizedOptions.authMode === 'msal-required') {
      if (this.getAccessToken == null) throw new Error('getAccessToken has not been set.');

      const accessToken = await this.getAccessToken();
      headers.Authorization = `Bearer ${accessToken}`;
    } else if (normalizedOptions.authMode === 'msal-optional' && this.getAccessToken != null) {
      try {
        const accessToken = await this.getAccessToken();
        headers.Authorization = `Bearer ${accessToken}`;
      } catch (ex) {
        if (!(ex instanceof AnonymousUserError)) {
          throw ex;
        }
      }
    } else if (normalizedOptions.authMode === 'share-required') {
      if (this.getShareToken == null) throw new Error('getShareToken has not been set.');

      const accessToken = await this.getShareToken();
      headers.Authorization = `Bearer ${accessToken}`;
    } else if (normalizedOptions.authMode === 'share-optional' && this.getShareToken != null) {
      try {
        const accessToken = await this.getShareToken();
        headers.Authorization = `Bearer ${accessToken}`;
      } catch (ex) {
        if (!(ex instanceof AnonymousUserError)) {
          throw ex;
        }
      }
    }

    // Stop if the request has been aborted.
    normalizedOptions.signal?.throwIfAborted?.();

    const requestOptions: RequestInit = {
      method,
      headers,
    };

    if (data != null) {
      requestOptions.body = isFormData ? (data as BodyInit) : JSON.stringify(data);
    }

    if (normalizedOptions.signal != null) {
      requestOptions.signal = normalizedOptions.signal;
    }

    return requestOptions;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async fetchAndParse<T>(request: Promise<Response>, reviver?: (key: string, value: any) => void): Promise<T> {
    const response = await request;

    if (!response.ok) {
      if (response.status === 401) {
        throw new AnonymousUserError();
      }
      throw new ApiRequestError(response.statusText, response);
    }

    // Return null if the response is No Content.
    if (response.status === 204) return null as unknown as T;

    const parsed = JSON.parse(await response.text(), reviver);
    return (parsed as ApiResponse<T>).result;
  }

  /** For endpoints that return NoContent() */
  public async fetch(request: Promise<Response>) {
    const response = await request;
    if (!response.ok) {
      throw new ApiRequestError(response.statusText, response);
    }
  }

  public async fetchBlob(request: Promise<Response>): Promise<Blob> {
    const response = await request;
    if (!response.ok) {
      throw new ApiRequestError(response.statusText, response);
    }

    return response.blob();
  }
}
