const DEFAULT_ASYNC_QUEUE_OPTIONS = {
  debugName: null,
  maxRunners: 5,
  run: () => {
    throw new Error('ParallelQueueOptions.run must be set before calling run.');
  },
} satisfies Omit<ParallelQueueOptions<object>, 'key'>;

/** Queue to execute multiple async tasks up to a configurable number of parallelism. */
export class ParallelQueue<TJob extends object> {
  static [Symbol.toStringTag]() {
    return 'ParallelQueue';
  }

  private _debugName: string | null = null;

  /** Maximum number of simultaneous runners to execute at a time. */
  private _maxRunners: number;

  /** Caller's queue runner callback. */
  private _runner: (job: TJob) => Promise<void>;

  /** Lock to prevent the queue runner from being recursively executed. */
  private _runLock = false;

  /** Counter to help keep track of the number of runners that are executing simultaneously. */
  private _runningCount = 0;

  /** Promise and resolve function to allow for waiting until a slot opens up in the concurrency limiter logic. */
  private _throttle: { promise: Promise<void>; resolve: () => void } | null = null;

  /** Queue for the jobs. */
  private _jobs: TJob[] = [];

  constructor(options: ParallelQueueOptions<TJob>) {
    this.enqueue = this.enqueue.bind(this);
    this.run = this.run.bind(this);
    this.runInternal = this.runInternal.bind(this);
    this.startThrottle = this.startThrottle.bind(this);
    this.reduceThrottle = this.reduceThrottle.bind(this);
    this.runnerWrapper = this.runnerWrapper.bind(this);
    this.clear = this.clear.bind(this);
    this.destroy = this.destroy.bind(this);

    const normalizedOptions = {
      ...DEFAULT_ASYNC_QUEUE_OPTIONS,
      ...options,
    };

    if (normalizedOptions.run === DEFAULT_ASYNC_QUEUE_OPTIONS.run) {
      throw new Error('ParallelQueueOptions.run must be set.');
    }

    this._debugName = normalizedOptions.debugName;
    this._maxRunners = normalizedOptions.maxRunners;
    this._runner = normalizedOptions.run;
  }

  public enqueue(job: TJob | TJob[]) {
    if (Array.isArray(job)) {
      this._jobs.push(...job);
    } else {
      this._jobs.push(job);
    }
  }

  public clear() {
    this._jobs = [];
  }

  public destroy() {
    this._jobs = [];
    this._runner = DEFAULT_ASYNC_QUEUE_OPTIONS.run;
  }

  public get length() {
    return this._jobs.length;
  }

  public run() {
    // Hide the fact that the queue loop is asynchronous from the caller.  It's likely better for the caller to utilize the onQueueStart and onQueueStop
    // callbacks if they need to perform any initialization or cleanup.
    this.runInternal();
  }

  private async startThrottle() {
    if (this._throttle) {
      await this._throttle.promise;
    }

    this._runningCount++;

    if (this._runningCount >= this._maxRunners) {
      let newThrottleResolver: () => void;
      const newThrottlePromise = new Promise<void>((resolve) => {
        newThrottleResolver = resolve;
      }).finally(() => {
        this._throttle = null;
      });

      this._throttle = {
        promise: newThrottlePromise,
        resolve: newThrottleResolver!, // Non-null assertion is safe because we just initialized the value.  TS just doesn't infer that correctly yet (TS 5.3.3).
      };
    }
  }

  private reduceThrottle() {
    this._runningCount--;

    if (this._throttle && (this._runningCount < this._maxRunners || this._runningCount === 0)) {
      this._throttle.resolve();
    }
  }

  private async runInternal() {
    if (this._runLock) return;

    try {
      this._runLock = true;

      while (this._jobs.length > 0) {
        const job = this._jobs.shift();

        if (job == null) {
          throw new Error('Queue item is null.');
        }

        // Wait for a concurrency slot to open up.
        await this.startThrottle();

        // Execute the job.  This intentionally is NOT awaiting the result.  We want to allow the queue to continue processing while the job is running.
        this.runnerWrapper(job);
      }
    } finally {
      this._runLock = false;
    }
  }

  private async runnerWrapper(job: TJob) {
    try {
      await this._runner(job);
    } finally {
      this.reduceThrottle();
    }
  }
}

export type ParallelQueueOptions<TJob extends object> = {
  debugName?: string | null;
  /** Maximum number of simultaneous runners to execute at a time.  If the `run` callback is *not* `async` then this won't have any effect.  Default set to `5`. */
  maxRunners?: number;
  /** Name of the property that contains a uniquely identifying key. */
  key?: keyof TJob;
  /** Primary callback to execute for each job in the queue. */
  run: (job: TJob) => Promise<void>;
};
