import { ExamModel } from 'models';
import { ExamFormValues } from 'features/exam/types';
import dayjs from 'dayjs';

const initializeExamFormValues = (exam: ExamModel): ExamFormValues => {
  return {
    active: exam.active,
    description: exam.description,
    customerUid: exam.customerUID,
    notes: exam.notes,
    share: exam.share,
    studyDate: exam.studyDate ? dayjs(exam.studyDate).format('MM/DD/YYYY') : '',
    studyTime: exam.studyTime ? dayjs(exam.studyTime).format('HH:mm:ss') : '',
  };
};

const mapFormValuesToExamModel = (exam: ExamModel, formValues: ExamFormValues): ExamModel => {
  return {
    ...exam,
    ...formValues,
    customerUID: formValues.customerUid,
  };
};

export const ExamFormService = {
  initializeExamFormValues,
  mapFormValuesToExamModel,
};
