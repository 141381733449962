import { FunctionComponent } from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';

import { Checkbox, DataTable, GridColumn, HeaderCell } from 'core/ui';

import { MergeStepDonorConfirm, MergeStepExamMigrate, MergeStepResult } from '.';
import { PatientGridService } from '../services';
import { MergePatientWithSelectModalProps } from '../types';
import { MergeStepConfirm } from './MergeStepConfirm';

export const MergePatientWithSelectModal: FunctionComponent<MergePatientWithSelectModalProps> = ({
  step,
  dateFormat,
  destPatient,
  selectAll,
  selectedFields,
  srcPatient,
  srcSelectAll,
  destSelectAll,
  destDataState,
  gridDestData,
  gridSrcData,
  dataStateChangeDest,
  dataStateChangeSrc,
  srcDataState,
  isMergeSuccessful,
  donors,
  dataStateChange,
  selectedDonors,
  setSelectedDonors,
  isLoading,
  dataState,
  searchValue,
  calculateAge,
}) => {
  const SELECTED_FIELD = 'selected';

  const ConditionalCellRender = (td: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => {
    if (selectedDonors) {
      const selectedItemsHere = Object.entries(selectedDonors)
        .filter(([, value]) => value)
        .map(([key]) => Number(key));

      if (props.field == SELECTED_FIELD && !props.dataItem.selected && selectedItemsHere.length === 1) {
        return (
          <td>
            <Checkbox disabled={true} />
          </td>
        );
      } else {
        return td;
      }
    } else {
      return td;
    }
  };

  return (
    <>
      {step === 0 && (
        <>
          <DataTable
            data={
              (donors?.data?.map((d) => ({
                ...d,
                age: calculateAge(d.dob),
              })) as unknown[]) || []
            }
            cellRender={ConditionalCellRender}
            sortable
            filterable
            onDataStateChange={dataStateChange}
            selectable
            selectedField={SELECTED_FIELD}
            pageable={{ pageSizes: true }}
            total={donors?.total || 0}
            selectedState={selectedDonors}
            onSelectionChange={setSelectedDonors}
            isLoading={isLoading}
            {...dataState}
          >
            {PatientGridService.getPatientColumns().map((column) => {
              if (column.field == 'notes') {
                return null;
              }
              return (
                <GridColumn
                  key={column.field}
                  headerCell={(headerCellProps) => <HeaderCell {...headerCellProps} description={column.headerCellDescription} />}
                  {...column}
                  cell={(props) => <column.cell {...props} searchValue={searchValue} />}
                  filter={column.columnFilter}
                />
              );
            })}
          </DataTable>
        </>
      )}
      {step === 1 && (
        <MergeStepDonorConfirm
          dateFormat={dateFormat}
          destPatient={destPatient}
          destSelectAll={destSelectAll}
          onSelectAll={selectAll}
          selectedFields={selectedFields}
          srcPatient={srcPatient}
          srcSelectAll={srcSelectAll}
        />
      )}
      {step === 2 && (
        <MergeStepExamMigrate
          destDataState={destDataState}
          gridDestData={gridDestData}
          gridSrcData={gridSrcData}
          dataStateChangeDest={dataStateChangeDest}
          dataStateChangeSrc={dataStateChangeSrc}
          srcDataState={srcDataState}
        />
      )}
      {step === 3 && <MergeStepConfirm />}
      {step === 4 && <MergeStepResult isMergeSuccessful={isMergeSuccessful} />}
    </>
  );
};
