import { SettingOverrideModel } from 'models';

import { ContactTabFormValues, GeneralTabFormValues, IntegrationTabFormValues, NotificationsTabFormValues, TatTabFormValues } from './types';

export const LOCAL_STORAGE_KEY_SESSION_LOCATION_ID = 'sessionLocationId';

export const LocationStatus = {
  PENDING: 'location-pending',
  ADDED: 'location-created',
  UPDATED: 'location-updated',
  FETCHED: 'location-fetched',
  ERROR: 'location-error',
};

export const DefaultViewerOptions = {
  BRIT: {
    name: 'BRIT',
    value: 'BRIT',
  },
  AMBRA: {
    name: 'Ambra',
    value: 'Ambra',
  },
  MEDDREAM: {
    name: 'MedDream',
    value: 'MedDream',
  },
};

export const InitialValues = {
  nonFormFields: {
    id: 0,
    pfwClass: null,
    contAmt: 0,
    magicPFWImport: null,
    allTxmtECGOverread: null,
    overreadRequire: null,
    alias: null,
    aliasShort: null,
    customerAlias: null,
    customerAliasShort: null,
    locationStatType: 0,
    parentLocation_Id: null,
    defaultTAT: null,
    provider: null,
  },
  contactTab: {
    address1: '',
    address2: '',
    state: '',
    city: '',
    zip: '',
    phone: '',
    phone2: '',
    fax: '',
    fax2: '',
    email: '',
    email2: '',
    mailingAddress: '',
    siteContact: '',
    contactTitle: '',
  } as ContactTabFormValues,
  generalTab: {
    locationType: null,
    name: '',
    code: null,
    user: '',
    contractType: '',
    contractStart: '',
    contractStop: '',
    serial: '',
    comments: '',
    overreadInstruction: '',
    timeZone: '',
    accountId: null,
    defaultPhysician_Id: null,
    active: true,
    isTestLocation: false,
  } as GeneralTabFormValues,
  integrationTab: {
    facilityId: '',
    aeTitle: '',
    uploadURL: '',
    integrations: [],
    defaultViewer: '',
    autoComplete: false,
    customerAlias: '',
    examMatchService: { id: null, name: null },
  } as IntegrationTabFormValues,
  notificationsTab: {
    overreadResultsMethod: '',
    emailConfirmation: false,
    emailConfirmationPDF: false,
    autoSendFinalReportEmail: false,
    autoSendFinalReportFax: false,
    autoSendFinalReportIntegration: false,
    emailNotificationSTATExam: false,
    textNotificationSTATExam: false,
  } as NotificationsTabFormValues,
  tatTab: {
    defaultTATDropdown: null,
    taTs: [],
    transmissionSLADropdown: null,
    abnormalTransmissionSLADropdown: null,
    allowLocationTATAdminOverride: false,
    services: [],
  } as TatTabFormValues,
  settingOverrides: [] as SettingOverrideModel[],
};
