import { memo } from 'react';

import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { RhfValidators, useFieldValueChangeHandler } from 'core/forms';
import { useEvent } from 'core/hooks';
import { CheckboxField, DropdownField, InputField } from 'core/ui';

import { useSessionLocation } from 'features/location';

import { SHARE_EXPIRATION_OPTIONS } from '../constants';
import { ShareEmailFormProps } from '../types/ShareEmailFormProps';
import { ShareEmailFormValues } from '../types/ShareEmailFormValues';

export const ShareEmailForm = memo<ShareEmailFormProps>(({ className, formId, initialValues, onChange, onSubmit }) => {
  const { sessionLocation } = useSessionLocation(true);

  const rhfContext = useForm<ShareEmailFormValues>({
    defaultValues: initialValues,
  });

  const { trigger } = rhfContext;

  const isUnosUnavailable = useWatch({ control: rhfContext.control, name: 'isUnosUnavailable' });

  const handleFormChange = useFieldValueChangeHandler(onChange, rhfContext);

  const handleIsUnosUnavailableChange = useEvent((event: CheckboxChangeEvent) => {
    handleFormChange(event);

    // Because the validation rules are defined on the component tree, we need to wait for the component tree to update before we can trigger the re-validation.
    // This is a bit of a hack.  A more "clean" solution could probably be implemented by using a validation library like Zod or Yup.
    setTimeout(() => {
      trigger('unosId');
    });
  });

  return (
    <FormProvider {...rhfContext}>
      <StyledForm
        id={formId}
        className={className}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        noValidate
        onSubmit={rhfContext.handleSubmit(onSubmit)}
      >
        {sessionLocation.isOpo && (
          <StyledFormFieldDiv>
            <InputField label="UNOS ID" name="unosId" onChange={handleFormChange} required={isUnosUnavailable !== true} />
            <StyledCheckboxField label="UNOS ID Unavailable" name="isUnosUnavailable" onChange={handleIsUnosUnavailableChange} />
          </StyledFormFieldDiv>
        )}

        <StyledFormFieldDiv>
          <InputField label="Description" name="description" required onChange={handleFormChange} />
        </StyledFormFieldDiv>

        <StyledFormFieldDiv>
          <InputField label="Email" name="email" required validator={RhfValidators.email} onChange={handleFormChange} />
        </StyledFormFieldDiv>

        <StyledFormFieldDiv>
          <InputField label="Access Code" name="accessCode" validator={RhfValidators.accessCode} onChange={handleFormChange} required />
        </StyledFormFieldDiv>

        <StyledFormFieldDiv>
          <DropdownField
            label="Expires In"
            name="expiration"
            data={SHARE_EXPIRATION_OPTIONS}
            dataItemKey="value"
            textField="name"
            valueField="value"
            onChange={handleFormChange}
            required
          />
        </StyledFormFieldDiv>

        <StyledFormFieldDiv>
          <InputField label="Message" name="message" onChange={handleFormChange} />
        </StyledFormFieldDiv>
      </StyledForm>
    </FormProvider>
  );
});

ShareEmailForm.displayName = 'ShareEmailForm';

const StyledForm = styled.form`
  display: grid;
  overflow: hidden;
  grid-template-columns: 300px 100px;
`;

const StyledFormFieldDiv = styled.div<{ $span?: number }>`
  grid-column: 1 / span ${({ $span }) => $span ?? 1};
`;

const StyledCheckboxField = styled(CheckboxField)`
  display: inline-flex;
  gap: 5px;
  padding-bottom: 20px;
`;
