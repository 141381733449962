import { Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { sortBy } from 'lodash';

import { ColumnState, SwitchCell, TextCell } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { ColumnDefinition } from 'features/exam';

import { DefaultColumnsState } from '../types/constants';

const locationFinalReportTemplateColumnCollections: ColumnDefinition[] = [
  {
    cell: TextCell,
    field: 'id',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'System Generated Number',
    title: 'Id',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: true,
  },
  {
    cell: TextCell,
    field: 'templatePath',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Path of the template file',
    title: 'Template Path',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'locationName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Location related to the template',
    title: 'Location Name',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'version',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Version of the template file',
    title: 'Version',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'organ',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Full name of physician',
    title: 'Organ',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'service',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Service replated to the template',
    title: 'Service',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: SwitchCell,
    field: 'active',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Destination Route is accessible',
    title: 'Active',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
    show: false,
  },
];

const locationFinalReportTemplateColumns = ['id', 'templatePath', 'locationName', 'version', 'organ', 'service', 'active'];

// get the columns of each features
function getDefineColumn(columns: string[]) {
  const finalColumns: ColumnDefinition[] = [];

  // loop to each columns
  columns.forEach((column) => {
    // find item to the collections
    finalColumns.push(
      findOrThrow(locationFinalReportTemplateColumnCollections, (item) => item.field === column, `Could not find column definition for: "${column}".`),
    );
  });

  return finalColumns;
}
const LOCATIONFINALREPORTTEMPLATE_COLUMNS = getDefineColumn(locationFinalReportTemplateColumns);

function getColumns() {
  return LOCATIONFINALREPORTTEMPLATE_COLUMNS;
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string): ColumnState {
  const orderIndex = allColumnStates[columnField]?.orderIndex ?? DefaultColumnsState[columnField]?.orderIndex ?? 0;
  const show = allColumnStates[columnField]?.show ?? DefaultColumnsState[columnField]?.show ?? false;

  return { orderIndex, show };
}

function getGridColumns() {
  return sortBy(
    LOCATIONFINALREPORTTEMPLATE_COLUMNS.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getColumnDefinition(field: string) {
  return findOrThrow(locationFinalReportTemplateColumnCollections, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

export const LocationFinalReportTemplateGridService = {
  getColumns,
  getColumnState,
  getGridColumns,
  getColumnDefinition,
};
