import { FunctionComponent, useCallback } from 'react';

import styled from 'styled-components';

import { Field, FieldContainer, Form, GridColumn, ValidatorBag, createStyledFormElement, maxLength } from 'core/forms';
import { Button, Label, Switch, TextArea } from 'core/ui';

import { NotificationsTabContentProps, NotificationsTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  examUploadWarning: maxLength(255),
};

const NotificationsTabContentInner: FunctionComponent<{
  onChange: (values: NotificationsTabFormValues) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof NotificationsTabFormValues) => any;
}> = ({ onChange, valueGetter }) => {
  const handleChange = useCallback(() => {
    onChange({
      examUploadWarning: valueGetter('examUploadWarning'),
      examCompleteNotification: valueGetter('examCompleteNotification'),
      emailNotificationSTATExam: valueGetter('emailNotificationSTATExam'),
      textNotificationSTATExam: valueGetter('textNotificationSTATExam'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement>
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="examUploadWarning">Exam Upload Warning</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="examUploadWarning" editorId="examUploadWarning" component={TextArea} validator={validators.examUploadWarning} onChange={handleChange} />
          <StyledSpacer />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="examCompleteNotification">Exam Complete Notification</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="examCompleteNotification" editorId="examCompleteNotification" component={Switch} onChange={handleChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="emailNotificationSTATExam"> STAT Exam Email Notification</Label>
      </GridColumn>
      <GridColumn>
        <FieldContainer $hideLabel>
          <Field name="emailNotificationSTATExam" editorId="emailNotificationSTATExam" component={Switch} onChange={handleChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="textNotificationSTATExam"> STAT Exam Text Notification</Label>
      </GridColumn>
      <GridColumn>
        <FieldContainer $hideLabel>
          <Field name="textNotificationSTATExam" editorId="textNotificationSTATExam" component={Switch} onChange={handleChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

NotificationsTabContentInner.displayName = 'NotificationsTabContentInner';

export const NotificationsTabContent: FunctionComponent<NotificationsTabContentProps> = ({ initialValues, onSubmit, onChange }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => <NotificationsTabContentInner onChange={onChange} valueGetter={valueGetter} />}
    />
  );
};

NotificationsTabContent.displayName = 'NotificationsTabContent';

const StyledSpacer = styled.div`
  height: 17px;
`;
