import { FunctionComponent, useCallback, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { Accordion, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { useBoolean } from 'core/hooks';
import { Breadcrumb } from 'core/ui';
import { HeaderButton, HeaderCollapseIcon, HeaderDiv } from 'core/ui/Accordion';
import { hasText } from 'core/utils';

import { usePatientVerbiage } from '../hooks';
import { PatientUtils } from '../services';
import { PatientEditHeaderProps } from '../types';
import { PatientDobLabel } from './PatientDobLabel';
import { PatientFormContent } from './PatientFormContent';

export const PatientEditHeader: FunctionComponent<PatientEditHeaderProps> = ({
  className,
  breadcrumbsData,
  onBreadcrumbClick,
  patient,
  buttonText,
  isEditMode,
  patientFormProps,
}) => {
  const patientVerbiage = usePatientVerbiage();

  const [isExpanded, setIsExpanded] = useState(false);
  const showUnosId = patientVerbiage === 'Donor' || hasText(patient?.unosID);
  const genderInfoValue = patient?.gender ? `(${patient?.gender})` : '';
  const dobDate = patient?.dob ? dayjs(patient?.dob).toString() : null;

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <>
      {isEditMode && breadcrumbsData && (
        <Row>
          <Breadcrumb data={breadcrumbsData} onItemSelect={onBreadcrumbClick} onKeyDown={onBreadcrumbClick} />
        </Row>
      )}

      <StyledAccordian activeKey={isExpanded ? '0' : undefined} flush className={className}>
        <Accordion.Item eventKey="0">
          <StyledHeaderDiv>
            <HeaderCollapseIcon variant="primary" eventKey="0" onClick={toggleIsExpanded} />
            <StyledInfoButton type="button" className="testing-patient-edit-header-expand-button-1" onClick={toggleIsExpanded} $showUnosId={showUnosId}>
              <StyledInfoBlock>
                <StyledInfoLabel>NAME (GENDER):</StyledInfoLabel>
                <StyledInfoValue>{`${PatientUtils.formatName(patient?.firstName, patient?.lastName) ?? ''} ${genderInfoValue}`}</StyledInfoValue>
              </StyledInfoBlock>
              {showUnosId && (
                <>
                  <StyledInfoBlock>
                    <StyledInfoLabel>UNOS ID:</StyledInfoLabel>
                    <StyledInfoValue>{patient?.unosID}</StyledInfoValue>
                  </StyledInfoBlock>
                </>
              )}
              <StyledInfoBlock>
                <StyledInfoLabel>MRN/{patientVerbiage.toUpperCase()} ID:</StyledInfoLabel>
                <StyledInfoValue>{patient?.patientNumber}</StyledInfoValue>
              </StyledInfoBlock>
              <StyledInfoBlock>
                <StyledInfoLabel>CASE ID:</StyledInfoLabel>
                <StyledInfoValue>{patient?.caseID}</StyledInfoValue>
              </StyledInfoBlock>
              <StyledInfoBlock>
                <StyledInfoLabel>DOB (AGE):</StyledInfoLabel>
                <StyledInfoValue>
                  <PatientDobLabel dob={dobDate} showAge />
                </StyledInfoValue>
              </StyledInfoBlock>
              <StyledInfoBlock>
                <StyledInfoLabel>HOSPITAL:</StyledInfoLabel>
                <StyledInfoValue>{patient?.hospital}</StyledInfoValue>
              </StyledInfoBlock>
            </StyledInfoButton>
            <StyledExpandButton type="button" className="testing-patient-edit-header-expand-button-2" onClick={toggleIsExpanded}></StyledExpandButton>
          </StyledHeaderDiv>
          <StyledFormAccordionBody>
            {patient != null && (
              <PatientFormContent
                initialValues={patientFormProps.initialValues}
                isFormDisabled={true}
                onFormToggle={patientFormProps.onFormToggle}
                buttonText={buttonText}
                onSubmit={patientFormProps.onSubmit}
                onChange={patientFormProps.onChange}
                ageRanges={patientFormProps.ageRanges}
              />
            )}
          </StyledFormAccordionBody>
        </Accordion.Item>
      </StyledAccordian>
    </>
  );
};

PatientEditHeader.displayName = 'PatientEditHeader';

const StyledHeaderDiv = styled(HeaderDiv)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: ${({ theme }) => theme.space.spacing40};

  && .icon-container {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const StyledInfoButton = styled(HeaderButton)<{ $showUnosId: boolean }>`
  display: grid;
  column-gap: ${({ theme }) => theme.space.spacing60};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoBlock = styled.div`
  text-align: left;
  grid-row: 1;
  padding-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoLabel = styled.div`
  text-align: left;
`;

const StyledInfoValue = styled.div`
  font-size: 12px;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledExpandButton = styled(HeaderButton)`
  padding: 0 ${({ theme }) => theme.space.spacing40};
`;

const StyledFormAccordionBody = styled(Accordion.Body)`
  && {
    background: ${({ theme }) => theme.colors.palette.white};
    border-radius: 0 0 15px 15px;
    border: 1px solid ${({ theme }) => theme.colors.palette.blues[5]};
  }
`;

const StyledAccordian = styled(Accordion)`
  .container {
    margin-left: 0;
  }

  && .accordion-button {
  }

  .accordion-button:after {
    order: -1;
    margin-left: 0;
    margin-right: 4em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f3f6f4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  @media (max-width: 600px) {
    width: calc(100vw - 75px);
  }
`;
