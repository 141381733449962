import { BulkAttachFilesModel, CompletePathologyUploadModel, ConnectionString, ContainerName, FileModel } from 'models';

import { ApiRouteService } from 'core/api';

import { HttpClient } from './HttpClient';

export class FilesClient {
  constructor(private httpClient: HttpClient) {
    this.attachFiles = this.attachFiles.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renameFile = this.renameFile.bind(this);
    this.generateAzureBlobDownloadSasUrl = this.generateAzureBlobDownloadSasUrl.bind(this);
    this.generateAzureBlobUploadSas = this.generateAzureBlobUploadSas.bind(this);
    this.add = this.add.bind(this);
    this.getById = this.getById.bind(this);
    this.completePathologyUpload = this.completePathologyUpload.bind(this);
  }

  public async getFilesByExamId(examId: number, authMode: 'msal-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });

    return await this.httpClient.fetchAndParse<FileModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files?examId=${examId}`, options));
  }
  public async attachFiles(files: BulkAttachFilesModel[], authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', files, {
      authMode,
    });

    const response = await this.httpClient.fetchAndParse<Record<string, boolean>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/Attach`, options),
    );
    return response;
  }

  public async deleteFile(fileId: string, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('DELETE', null, { authMode });

    const response = await this.httpClient.fetchAndParse(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/${fileId}`, options));
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async renameFile(patientFileId: number, patchDoc: any[]) {
    const options = await this.httpClient.createStandardOptions('PATCH', patchDoc);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/rename/${patientFileId}`, options));
  }

  public async generateAzureBlobDownloadSasUrl(containerName: string, blobName: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const response = await this.httpClient.fetchAndParse<string>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/GenerateAzureBlobDownloadSas/${containerName}/${blobName}`, options),
    );
    return response;
  }

  public async generateAzureBlobUploadSas(connectionString: ConnectionString, containerName: ContainerName, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });

    const queryUrl = `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/GenerateAzureBlobUploadSas/${encodeURIComponent(containerName)}?connectionString=${encodeURIComponent(connectionString)}`;

    const response = await this.httpClient.fetchAndParse<string>(fetch(queryUrl, options));

    return response;
  }

  public async add(formData: FormData, shouldSplitFiles: boolean, examId: number, attachments: boolean, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', formData, { authMode }, { isFormData: true });
    return await this.httpClient.fetchAndParse<Record<string, boolean>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files?splitFiles=${shouldSplitFiles}&examId=${examId}&attachments=true`, options),
    );
  }

  public async getById(fileId: number, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });
    return await this.httpClient.fetchAndParse<FileModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/get-by-id/${fileId}`, options));
  }

  public async completePathologyUpload(model: CompletePathologyUploadModel, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', model, { authMode });
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/CompletePathologyUpload`, options));
  }
}
