import dayjs, { Dayjs } from 'dayjs';
import { get } from 'lodash';
import { FieldPath, FieldValues, Validate, ValidateResult } from 'react-hook-form';

import { hasText, isPromise } from 'core/utils';

const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const phoneRegex = new RegExp('^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$');

const zipRegex = new RegExp('^\\d{5}(-\\d{4})?$');

function combine<TFieldValue, TFormValues extends FieldValues>(validators: Validate<TFieldValue, TFormValues>[]): Validate<TFieldValue, TFormValues> {
  return (value, formValues) => {
    for (const validator of validators) {
      const result = validator(value, formValues);

      if (isPromise(result)) {
        throw new Error('TODO: Implement async validator support.'); // TODO: Implement this when it becomes necessary.
      } else if (result !== true && typeof result !== 'undefined') {
        return result; // We can stop evaluating validators as soon as the first one fails.
      }
    }

    return true;
  };
}

function required<TFieldValue>(value: TFieldValue): ValidateResult {
  if (Array.isArray(value)) return value.length > 0 ? undefined : 'This field is required.';

  const isValid = typeof value === 'string' ? hasText(value) : Boolean(value);
  return isValid ? undefined : 'This field is required.';
}

function requiredWithDefaultOption<TFieldValue>(dataItemKey: string): Validate<TFieldValue, unknown> {
  // This is a special case for dropdowns that have a default option which is an object with a null or undefined value for the dataItemKey.

  return (value) => {
    return (value as Record<string, unknown>)?.[dataItemKey] == null ? 'This field is required.' : undefined;
  };
}

function requireValue<TFieldValue>(expectedValue: TFieldValue, errorMessage: string): Validate<TFieldValue, unknown> {
  return (value: TFieldValue) => (value === expectedValue ? undefined : errorMessage);
}

function richEditorRequired<TFieldValue extends string | null | undefined>(value: TFieldValue): ValidateResult {
  return value !== '<p></p>' ? undefined : 'This field is required.';
}

function email(value: string | null | undefined): ValidateResult {
  if (value) {
    return emailRegex.test(value) ? undefined : 'Please enter a valid email.';
  }
  return undefined;
}

function phone(value: string | null | undefined): ValidateResult {
  if (value) {
    return phoneRegex.test(value) ? undefined : 'Please enter a valid phone number.';
  }

  return undefined;
}

function maxLength<TFieldValue>(lengthToCompare: number): Validate<TFieldValue, unknown> {
  return (fieldValue) => {
    if (typeof fieldValue !== 'string') return undefined;

    if (fieldValue != null && fieldValue.length > 0 && fieldValue.length > lengthToCompare) {
      return `This field cannot have more than ${lengthToCompare} characters.`;
    }

    return undefined;
  };
}

function minLength<TFieldValue>(lengthToCompare: number): Validate<TFieldValue, unknown> {
  return (fieldValue) => {
    if (typeof fieldValue !== 'string') return undefined;

    if (fieldValue != null && fieldValue.length > 0 && fieldValue.length < lengthToCompare) {
      return `This field requires a minimum of ${lengthToCompare} characters.`;
    }

    return undefined;
  };
}

function greaterThanOrEqualTo<TFieldValue>(valueToCompare: number): Validate<TFieldValue, unknown> {
  return (fieldValue) => {
    if (fieldValue == null) return undefined;

    if (typeof fieldValue === 'string') {
      const parsed = parseFloat(fieldValue);
      return isNaN(parsed) || parsed >= valueToCompare ? undefined : `Value must be greater or equal to ${valueToCompare}.`;
    }

    if (typeof fieldValue === 'number') {
      return isNaN(fieldValue) || fieldValue >= valueToCompare ? undefined : `Value must be greater or equal to ${valueToCompare}.`;
    }

    return undefined;
  };
}

function lowerValueThanField<TFieldValue extends number | string, TFormValues extends FieldValues>(
  /** Lodash compatible field name path.  Typically this will be exactly the name of the field that will be compared.  Forms that have nested objects or arrays will have to use more advanced syntax.
   * @see {@link https://lodash.com/docs/4.17.15#get}.
   * */
  fieldPath: FieldPath<TFormValues>,
  errorMessage: string,
): Validate<TFieldValue, TFormValues> {
  return (fieldValue, formValues) => {
    if (fieldValue == null) return undefined;

    const rawCompareValue = get(formValues, fieldPath, undefined);
    const parsedFieldValue = typeof fieldValue === 'string' ? parseFloat(fieldValue) : fieldValue;
    const parsedCompareValue = typeof rawCompareValue === 'string' ? parseFloat(rawCompareValue) : rawCompareValue;

    const isValid = parsedFieldValue === parsedCompareValue;

    return isValid ? undefined : hasText(errorMessage) ? errorMessage : `Value must be lower than ${parsedCompareValue}.`;
  };
}

function zip(fieldValue: string | null | undefined): ValidateResult {
  return typeof fieldValue !== 'string' || zipRegex.test(fieldValue) ? undefined : 'Please enter a zip.';
}

function maskedDateTimeInputFormat<TFieldValue>(errorMessage: string, parseFormats: string | string[]): Validate<TFieldValue, unknown> {
  return (value) => {
    if (!hasText(value)) return undefined;

    return dayjs(value, parseFormats, true).isValid() ? undefined : errorMessage;
  };
}

function datetime(fieldValue: string | null | undefined | Date | Dayjs): ValidateResult {
  return dayjs(fieldValue).isValid() ? undefined : 'Invalid date/time.';
}

function pattern<TFieldValue extends string | null | undefined>(
  regularExpression: string | RegExp,
  errorMessage = `Value must match pattern ${regularExpression}.`,
): Validate<TFieldValue, unknown> {
  const reg = regularExpression instanceof RegExp ? regularExpression : new RegExp(regularExpression);
  return (fieldValue) => (fieldValue == null || reg.test(fieldValue) ? undefined : errorMessage);
}

function hostname(fieldValue: string | null | undefined): ValidateResult {
  if (!hasText(fieldValue)) return undefined;

  try {
    const parsed = new URL(`http://${fieldValue}`);
    return parsed == null ? 'Invalid hostname.' : undefined;
  } catch {
    return 'Invalid hostname.';
  }
}

function accessCode(fieldValue: string | null | undefined): ValidateResult {
  if (!hasText(fieldValue)) return undefined;
  // must be greater than 6 characters long
  if (fieldValue.length < 6) return 'Access code must be at least 6 characters long.';

  return undefined;
}

function url<TFieldValue extends string | null | undefined>(fieldValue: TFieldValue): ValidateResult {
  if (!hasText(fieldValue)) return undefined;

  try {
    const parsed = new URL(fieldValue);
    return parsed == null ? 'Invalid URL.' : undefined;
  } catch {
    return 'Invalid URL.';
  }
}

function port(fieldValue: string | number | null | undefined): ValidateResult {
  if (fieldValue == null) return undefined;

  if (typeof fieldValue === 'string') {
    if (!hasText(fieldValue)) return undefined;
    const parsed = parseFloat(fieldValue);
    return Number.isSafeInteger(parsed) && parsed > 0 && parsed < 65535 ? undefined : 'Invalid port (1 - 65535).';
  } else {
    if (Number.isNaN(fieldValue)) return undefined;

    return Number.isSafeInteger(fieldValue) && fieldValue > 0 && fieldValue < 65535 ? undefined : 'Invalid port (1 - 65535).';
  }
}

export const RhfValidators = {
  combine,
  required,
  requiredWithDefaultOption,
  requireValue,
  richEditorRequired,
  email,
  phone,
  minLength,
  maxLength,
  greaterThanOrEqualTo,
  lowerValueThanField,
  zip,
  maskedDateTimeInputFormat,
  datetime,
  pattern,
  hostname,
  port,
  url,
  accessCode,
};
