import { memo } from 'react';

import { GridDetailRowProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { PatientGridModel } from 'models';

import { ExpandableRow } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { useQueryPatientAgeRanges } from 'features/api/hooks/useQueryAgeRanges';
import { useQueryPatient } from 'features/api/hooks/useQueryPatient';

import { PatientUtils } from '../services';

export const PatientGridDetail = memo<Omit<GridDetailRowProps, 'dataItem'> & { dataItem: ExpandableRow<PatientGridModel> }>(({ dataItem }) => {
  const { data: ageRanges, isSuccess: isQueryAgeRangesSuccess } = useQueryPatientAgeRanges();
  const [{ data: patient, isSuccess: isQueryPatientSuccess }] = useQueryPatient(dataItem.id);

  if (!isQueryPatientSuccess || !isQueryAgeRangesSuccess) return null;

  return (
    <StyledComponentDiv>
      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Unos ID:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.unosID}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Case ID:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.caseID}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>First Name:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.firstName}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Last Name:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.lastName}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>DOB:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.dob}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Age Range:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>
          {patient.ageRange_id == null ? '' : PatientUtils.formatAgeRange(findOrThrow(ageRanges, (r) => r.id === patient.ageRange_id))}
        </StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Weight:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.weight}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>MRN:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.patientNumber}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Height:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.height}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Gender:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.gender}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Cross Clamp Time:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.crossClampDateTime}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Hospital:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.hospital}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledPropertyDiv>
        <StyledPropertyNameDiv>Active:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.active ? 'Yes' : 'No'}</StyledPropertyValueDiv>
      </StyledPropertyDiv>

      <StyledNotesPropertyDiv>
        <StyledPropertyNameDiv>Notes:</StyledPropertyNameDiv>
        <StyledPropertyValueDiv>{patient.notes}</StyledPropertyValueDiv>
      </StyledNotesPropertyDiv>
    </StyledComponentDiv>
  );
});

PatientGridDetail.displayName = 'PatientGridDetail';

const StyledComponentDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr));
`;

const StyledPropertyDiv = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column-end: span 2;
`;

const StyledPropertyNameDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: right;
  padding-right: ${({ theme }) => theme.space.spacing20};
`;

const StyledPropertyValueDiv = styled.div`
  flex: 0 0 fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledNotesPropertyDiv = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
`;
