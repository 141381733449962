import { FunctionComponent, memo, useEffect, useId, useState } from 'react';

import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ConnectionString, ContainerName, ServiceModel } from 'models';

import { RhfValidators, useFieldValueChangeHandler } from 'core/forms';
import { useAsyncCallback, useConst, useDataStream, useEvent, useEventStream } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { BreakpointSelectors, Button, ComponentSizes, DropdownField, ErrorMessage, FileInputField, InputField, ProgressBar, TextAreaField } from 'core/ui';
import { equalsInsensitive, findOrThrow } from 'core/utils';

import { apiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';
import { useUploadPipeline } from 'features/file';
import { BasicUploadView } from 'features/file/services';
import { useSessionLocation } from 'features/location';

import {
  UPLOAD_FILE_MODAL_CATEGORY_OPTIONS,
  UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES,
  UPLOAD_FILE_MODAL_PATHOLOGY_BIOPSY_TYPE_OPTIONS,
  UPLOAD_FILE_MODAL_PATHOLOGY_LATERALITY_OPTIONS,
  UPLOAD_FILE_MODAL_PATHOLOGY_ORGAN_OPTIONS,
  UPLOAD_FILE_MODAL_PATHOLOGY_SLIDE_PREPARATION_OPTIONS,
} from '../constants';
import { UploadFileCategory, UploadFileModalFormValues, UploadFileModalProps } from '../types';

const UploadFileModalInner = memo<UploadFileModalProps & { uploadView: BasicUploadView }>(({ patient, uploadView, onClose }) => {
  const { uploadPipeline } = useUploadPipeline();
  const { sessionLocation } = useSessionLocation();
  const { currentUser } = useCurrentUser(true);

  const formId = `${UploadFileModalInner.displayName}_${useId()}`;
  const [formValues, setFormValues] = useState<UploadFileModalFormValues>(UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES);
  const [allServices, setAllServices] = useState<ServiceModel[] | null>(null);
  const uploadSessionId = useConst(() => crypto.randomUUID());

  const uploadStatus = useDataStream(uploadView.streams.status);

  const uploadPercentage =
    uploadStatus.totalFiles === 0
      ? 100.0 // Hardcode to 100% if there are no queued files because the weighted upload size is susceptible to accumulating rounding errors.
      : Math.min(100.0, (100.0 * uploadStatus.uploadedBytes) / uploadStatus.totalBytes); // Cap at exactly 100.0 in case there are floating point rounding discrepancies.

  const rhfContext = useForm({
    defaultValues: formValues,
  });

  const { isValid, isSubmitted } = rhfContext.formState;

  const [initialize, isInitializing] = useAsyncCallback(async (signal) => {
    const services = await apiClient.servicesClient.getAllServices();

    if (signal.aborted) return;

    setAllServices(services);
  });

  const handleFormChange = useFieldValueChangeHandler(setFormValues, rhfContext);

  const handleSubmit: SubmitHandler<UploadFileModalFormValues> = useEvent(async (form) => {
    if (allServices == null) throw new Error('Cannot save exam and upload files when the allServices property is null.');
    if (sessionLocation == null) throw new Error('Cannot save exam and upload files when the session location is null.');

    const service = findOrThrow(
      allServices,
      (s) => equalsInsensitive(s.description, form.category.value),
      `Could not find service with description "${form.category.value}".`,
    );

    const isPathology = equalsInsensitive(form.category.value, UploadFileCategory.Pathology);
    const isLink = equalsInsensitive(form.category.value, UploadFileCategory.Link);

    if (!isLink && form.files == null) throw new Error('Cannot upload files when the form.files property is null or undefined.');
    const files = form.files ?? []; // Workaround for TS not able to infer that form.files is not null when isLink is false. (TypeScript 5.6.2)

    const newExamId = await apiClient.exams.saveExam({
      location_Id: sessionLocation.id,
      patient_Id: patient.id,
      serviceId: service.id,
      source: 'WEB',
      /* "Pathology" specific fields. */
      ...(isPathology
        ? {
            organ: form.organ?.value,
            laterality: form.laterality?.value,
            biopsyType: form.biopsyType?.value,
            slidePreparation: form.slidePreparation?.value,
          }
        : {}),
      /* "Link" specific field. */
      ...(isLink ? { notes: form.linkUrl } : {}),
    });

    // Begin the file uploads. Link "uploads" are not actually uploaded from the browser, so we can skip the upload process for those.
    if (!isLink) {
      let connectionString: ConnectionString;
      let containerName: ContainerName;
      let attachMode: 'pathology' | 'ekg' | 'other';

      if (form.category.value === UploadFileCategory.EKG) {
        connectionString = ConnectionString.StorageConnectionString;
        containerName = ContainerName.Files;
        attachMode = 'ekg';
      } else if (form.category.value === UploadFileCategory.Pathology) {
        connectionString = ConnectionString.DICOMStorageConnectionString;
        containerName = ContainerName.Pathology;
        attachMode = 'pathology';
      } else if (form.category.value === UploadFileCategory.Document) {
        connectionString = ConnectionString.StorageConnectionString;
        containerName = ContainerName.Files;
        attachMode = 'other';
      } else {
        throw new Error(`Unknown upload category: ${form.category.value}`);
      }

      if (connectionString != null && containerName != null) {
        uploadPipeline.scanNonDicomFiles(
          files.map((f) => ({
            fileId: f.fileId,
            file: f.file,
            connectionString,
            containerName,
            attachMode,
          })),
        );
      }

      for (const file of files) {
        uploadPipeline.setFileMetadata(file.fileId, {
          uploadSessionId: uploadSessionId,
          examId: newExamId,
          userId: currentUser.id,
          patientId: patient.id,
        });
      }
    }

    // Go ahead and close the dialog now for link uploads since there is not an actual file transfer between browser and server.
    if (isLink) {
      NotificationsService.displaySuccess('Successfully created exam.');
      onClose();
    }
  });

  useEventStream(uploadPipeline.fileScanner.streams.onParseComplete, (event) => {
    uploadPipeline.uploadFiles(event.fileId);
  });

  useEventStream(uploadView.streams.status, (status) => {
    if (status.totalFiles === 0) return;

    if (status.error > 0) return;

    if (status.attachedFiles === status.totalFiles) {
      NotificationsService.displaySuccess(`Successfully created exam and uploaded ${status.attachedFiles === 1 ? 'file' : 'files'}.`);
      onClose();
    }
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <FormProvider {...rhfContext}>
      <StyledWindow
        modal
        top={50}
        width={600}
        draggable={false}
        resizable={false}
        title="Upload File"
        onClose={onClose}
        minimizeButton={NullComponent}
        restoreButton={NullComponent}
        maximizeButton={NullComponent}
      >
        <StyledForm
          id={formId}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          <StyledFormFieldDiv>
            <DropdownField
              label="Category"
              name="category"
              data={UPLOAD_FILE_MODAL_CATEGORY_OPTIONS}
              dataItemKey="value"
              textField="name"
              onChange={handleFormChange}
            />
          </StyledFormFieldDiv>

          {formValues.category.value === UploadFileCategory.Pathology && (
            <>
              <StyledFormFieldDiv>
                <DropdownField
                  label="Organ"
                  name="organ"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_ORGAN_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </StyledFormFieldDiv>

              <StyledFormFieldDiv>
                <DropdownField
                  label="Laterality"
                  name="laterality"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_LATERALITY_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </StyledFormFieldDiv>

              <StyledFormFieldDiv>
                <DropdownField
                  label="Biopsy Type"
                  name="biopsyType"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_BIOPSY_TYPE_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </StyledFormFieldDiv>

              <StyledFormFieldDiv>
                <DropdownField
                  label="Slide Preparation"
                  name="slidePreparation"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_SLIDE_PREPARATION_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </StyledFormFieldDiv>
            </>
          )}

          {formValues.category.value === UploadFileCategory.Link && (
            <StyledFormFieldDiv>
              <InputField label="Link URL" name="linkUrl" required validator={RhfValidators.url} onChange={handleFormChange} />
            </StyledFormFieldDiv>
          )}

          <StyledFormFieldDiv>
            <TextAreaField label="Notes" name="notes" onChange={handleFormChange} />
          </StyledFormFieldDiv>

          {formValues.category.value !== UploadFileCategory.Link && (
            <StyledFormFieldDiv $span={2}>
              <FileInputField label="Files" name="files" required multiple onChange={handleFormChange} />
            </StyledFormFieldDiv>
          )}
        </StyledForm>

        <WindowActionsBar>
          {uploadStatus.totalFiles > 0 && <StyledProgressBar value={uploadPercentage} size={ComponentSizes.LARGE} labelResolver={progressText} />}
          {!isValid && isSubmitted && <ErrorMessage>Unable to save. Please correct issues above.</ErrorMessage>}
          <Button type="submit" form={formId} disabled={isInitializing}>
            Save & Upload
          </Button>
        </WindowActionsBar>
      </StyledWindow>
    </FormProvider>
  );
});

UploadFileModalInner.displayName = 'UploadFileModalInner';

export const UploadFileModal: FunctionComponent<UploadFileModalProps> = (props) => {
  const { uploadPipeline } = useUploadPipeline();

  const [uploadView, setUploadView] = useState<BasicUploadView | null>(null);

  useEffect(() => {
    const newUploadView = new BasicUploadView(uploadPipeline);
    setUploadView(newUploadView);

    return () => {
      newUploadView.destroy();
      setUploadView(null);
    };
  }, [uploadPipeline]);

  return uploadView == null ? null : <UploadFileModalInner {...props} uploadView={uploadView} />;
};

UploadFileModal.displayName = 'UploadFileModal';

const NullComponent = () => null;

function progressText(value: number | null | undefined) {
  return value == null ? '' : `${Math.floor(value)} %`;
}

const StyledWindow = styled(Window)`
  height: initial !important;

  ${BreakpointSelectors.Desktop} {
    max-height: calc(100dvh - 100px);
  }
`;

const StyledForm = styled.form`
  display: grid;
  overflow: hidden;
  grid-template-columns: 300px 100px;
`;

const StyledFormFieldDiv = styled.div<{ $span?: number }>`
  grid-column: 1 / span ${({ $span }) => $span ?? 1};
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 200px;
`;
