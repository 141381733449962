import { ChangeEvent, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { ErrorMessage } from '../ErrorMessage';
import { Label } from '../Label';
import { FileDescriptor } from './FileDescriptor';
import { FileInputDefaultFileItem } from './FileInputDefaultFileItem';
import { FileInputDefaultSelectButton } from './FileInputDefaultSelectButton';
import { FileInputFileItemContainer } from './FileInputFileItemContainer';
import { FileInputHandle } from './FileInputHandle';
import { FileInputProps } from './FileInputProps';
import { FileInputSelectButtonHandle } from './FileInputSelectButtonHandle';

export const FileInput = forwardRef<FileInputHandle, FileInputProps>(
  (
    {
      id,
      className,
      name,
      tabIndex,
      label,
      disabled = false,
      multiple = false,
      value,
      required = false,
      valid = true,
      validationMessage,
      selectButtonAs: SelectButtonAs = FileInputDefaultSelectButton,
      fileItemAs = FileInputDefaultFileItem,
      onChange,
    },
    ref,
  ) => {
    const isControlled = typeof value !== 'undefined';

    const selectButtonRef = useRef<FileInputSelectButtonHandle | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [uncontrolledValue, setUncontrolledValue] = useState<FileDescriptor[]>(value ?? []);

    const effectiveValue = isControlled ? value : uncontrolledValue;

    const handleSelectButtonClick = useEvent(() => {
      inputRef.current?.click();
    });

    const handleInputChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      const newValue: FileDescriptor[] = files == null && effectiveValue.length === 0 ? effectiveValue : [];

      if (!files) {
        if (!isControlled) {
          setUncontrolledValue(newValue);
        }

        onChange?.({
          name,
          value: newValue,
        });

        return;
      }

      for (const file of files) {
        const existingFile = effectiveValue.find((f) => f.file === file);

        newValue.push(
          existingFile ?? {
            fileId: crypto.randomUUID(),
            file,
          },
        );
      }

      if (!isControlled) {
        setUncontrolledValue(newValue);
      }

      onChange?.({
        name,
        value: newValue,
      });
    });

    const handleFileRemove = useEvent((fileId: string) => {
      if (inputRef.current == null) throw new Error('Cannot remove file when input is not mounted.');

      const newValue = effectiveValue.filter((f) => f.fileId !== fileId);

      if (!isControlled) {
        setUncontrolledValue(newValue);
      }

      // Sync the input value with the new value.
      const newRawInputValue = new DataTransfer();
      newValue.forEach((f) => newRawInputValue.items.add(f.file));
      inputRef.current.files = newRawInputValue.files;

      onChange?.({
        name,
        value: newValue,
      });
    });

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          selectButtonRef.current?.focus?.();
        },
        get value() {
          return effectiveValue;
        },
      }),
      [effectiveValue],
    );

    return (
      <StyledComponentDiv className={cn(className, { multiple, single: !multiple })}>
        <StyledHiddenInput ref={inputRef} type="file" multiple={multiple} onChange={handleInputChange} />

        <StyledLabel editorId={id} editorDisabled={disabled} required={required} editorValid={valid} editorRef={selectButtonRef}>
          {label}
        </StyledLabel>

        <SelectButtonAs ref={selectButtonRef} id={id} disabled={disabled} tabIndex={tabIndex} multiple={multiple} onClick={handleSelectButtonClick} />

        <StyledFileListDiv className="file-input__file-list">
          {value?.map((file) => (
            <FileInputFileItemContainer key={file.fileId} disabled={disabled} file={file} fileItemAs={fileItemAs} onRemove={handleFileRemove} />
          ))}
        </StyledFileListDiv>

        {hasText(validationMessage) && <StyledErrorMessage>{validationMessage}</StyledErrorMessage>}
      </StyledComponentDiv>
    );
  },
);

FileInput.displayName = 'FileInput';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: min-content 1fr;
`;

const StyledLabel = styled(Label)`
  grid-column: 1 / span 2;
`;

const StyledHiddenInput = styled.input`
  display: none;
`;

const StyledFileListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  grid-column: 1 / span 2;
`;
