import { DataResult } from 'core/api';

import { ExpandableRow } from './ExpandableRow';

/** Clone the provided data by creating and hydrating the `expanded` property.  @returns Copy of the data with an appended `expanded` property on each item in the data list. */
export function mapToExpandableRows<TData extends object>(data: DataResult<TData>, defaultExpanded = false): DataResult<ExpandableRow<TData>> {
  return {
    ...data,
    data: data.data.map((d) => ({ expanded: defaultExpanded, ...d })),
  };
}
