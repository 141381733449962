import { FunctionComponent, useCallback } from 'react';

import { Field, FieldContainer, Form, GridColumn, createStyledFormElement } from 'core/forms';
import { Button, Input, Label, Switch } from 'core/ui';

import { locationFormValidators as validators } from '../services';
import { NotificationsTabContentProps, NotificationsTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const NotificationsTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof NotificationsTabFormValues) => any;
  onChange: (values: NotificationsTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      overreadResultsMethod: valueGetter('overreadResultsMethod'),
      emailConfirmation: valueGetter('emailConfirmation'),
      emailConfirmationPDF: valueGetter('emailConfirmationPDF'),
      autoSendFinalReportFax: valueGetter('autoSendFinalReportFax'),
      autoSendFinalReportEmail: valueGetter('autoSendFinalReportEmail'),
      autoSendFinalReportIntegration: valueGetter('autoSendFinalReportIntegration'),
      emailNotificationSTATExam: valueGetter('emailNotificationSTATExam'),
      textNotificationSTATExam: valueGetter('textNotificationSTATExam'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="overreadResultsMethod">Overread Results Method</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="overreadResultsMethod"
            name="overreadResultsMethod"
            validator={validators.overreadResultsMethod}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="emailConfirmation">Email Confirmation</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="emailConfirmation" editorId="emailConfirmation" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="emailConfirmationPDF">Email Confirmation PDF</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="emailConfirmationPDF" editorId="emailConfirmationPDF" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="autoSendFinalReportFax">Auto Send Final Report via Fax</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="autoSendFinalReportFax" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="autoSendFinalReportEmail">Auto Send Final Report via Email</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="autoSendFinalReportEmail" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="autoSendFinalReportIntegration">Auto Send Final Report via Integration</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="autoSendFinalReportIntegration" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="emailNotificationSTATExam"> STAT Exam Email Notification</Label>
      </GridColumn>
      <GridColumn>
        <FieldContainer $hideLabel>
          <Field name="emailNotificationSTATExam" editorId="emailNotificationSTATExam" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="textNotificationSTATExam"> STAT Exam Text Notification</Label>
      </GridColumn>
      <GridColumn>
        <FieldContainer $hideLabel>
          <Field name="textNotificationSTATExam" editorId="textNotificationSTATExam" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

NotificationsTabContentInner.displayName = 'NotificationsTabContentInner';

export const NotificationsTabContent: FunctionComponent<NotificationsTabContentProps> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => <NotificationsTabContentInner valueGetter={valueGetter} onChange={onChange} />}
    />
  );
};

NotificationsTabContent.displayName = 'NotificationsTabContent';
