import { memo, useMemo } from 'react';

import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';
import { useEvent } from 'core/hooks';

import { FileInput } from './FileInput';
import { FileInputChangeEvent } from './FileInputChangeEvent';
import { FileInputFieldProps } from './FileInputFieldProps';

export const FileInputField = memo<FileInputFieldProps>(({ name, required, validator, onChange, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.required);
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [required, validator]);

  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({
    name,
    rules: {
      validate: validatorInternal,
    },
  });

  const handleChange = useEvent((event: FileInputChangeEvent) => {
    rhfOnChange(event.value);
    onChange?.(event);
  });

  return (
    <FileInput
      {...fieldRest}
      {...rest}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      required={required}
      validationMessage={error?.message}
      onChange={handleChange}
    />
  );
});

FileInputField.displayName = 'FileInputField';
