import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

import { PatientModel } from 'models';

import { findOrThrow } from 'core/utils';

import { SHARE_EXPIRATION_OPTIONS } from '../constants';
import { ShareEmailFormValues, ShareLinkFormValues } from '../types';
import { ShareExpirationType } from '../types/ShareExpirationType';

function calculateShareExpiration(expireType: ShareExpirationType, startDate: Dayjs = dayjs.utc()) {
  if (!startDate.isUTC()) {
    throw new Error('Parameter "startDate" must be in UTC timezone.');
  }

  let duration: Duration | null;

  switch (expireType) {
    case ShareExpirationType.Never:
      duration = null;
      break;
    case ShareExpirationType.OneDay:
      duration = dayjs.duration(1, 'days');
      break;
    case ShareExpirationType.SevenDays:
      duration = dayjs.duration(7, 'days');
      break;
    case ShareExpirationType.ThirtyDays:
      duration = dayjs.duration(30, 'days');
      break;
    case ShareExpirationType.SixtyDays:
      duration = dayjs.duration(60, 'days');
      break;
    case ShareExpirationType.NinetyDays:
      duration = dayjs.duration(90, 'days');
      break;
    case ShareExpirationType.OneYear:
      duration = dayjs.duration(1, 'years');
      break;
    default:
      throw new Error('Unable to determine expiration duration.');
  }

  return duration == null ? null : startDate.add(duration).tz('America/Los_Angeles');
}

function createShareLink(reactSharePage: boolean, linkId: string) {
  if (reactSharePage) {
    return `${window.location.origin}/share/${linkId}`;
  }
  return `${window.location.origin}/ExamStudy/${linkId}`;
}

function initializeLinkFormValues(patient: PatientModel, isOpo: boolean): ShareLinkFormValues {
  return {
    description: `${isOpo ? patient.unosID : patient.patientNumber}`,
    accessCode: '',
    expiration: findOrThrow(SHARE_EXPIRATION_OPTIONS, (o) => o.value === ShareExpirationType.ThirtyDays),
    ...(isOpo
      ? {
          unosId: patient.unosID ?? '',
          isUnosUnavailable: false,
        }
      : {}),
  };
}

function initializeEmailFormValues(patient: PatientModel, isOpo: boolean): ShareEmailFormValues {
  return {
    description: `${isOpo ? patient.unosID : patient.patientNumber}`,
    email: '',
    accessCode: '',
    expiration: findOrThrow(SHARE_EXPIRATION_OPTIONS, (o) => o.value === ShareExpirationType.ThirtyDays),
    ...(isOpo
      ? {
          unosId: patient.unosID ?? '',
          isUnosUnavailable: false,
        }
      : {}),
  };
}

export const ShareService = {
  calculateShareExpiration,
  initializeLinkFormValues,
  initializeEmailFormValues,
  createShareLink,
};
