import { CompositeFilterDescriptor, DataSourceRequestState, State } from '@progress/kendo-data-query';
import { DateFilter, Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { cloneDeep } from 'lodash';

import { ExamModel, PatientModel } from 'models';

import { addFiltersToDataState } from 'core/api/services/kendoMultiColumnFilter';
import { DEFAULT_DATA_TABLE_DATA_STATE, DateCell, TextCell } from 'core/ui';
import { hasOnlyDigits } from 'core/utils';

import { DEFAULT_GRID_COLUMNS_STATE } from '../constants';
import { PatientColumn } from '../types/PatientColumn';

const DEFAULT_FILTER: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};

const DEFAULT_FILTER_DATA_STATE: State & { filter: NonNullable<State['filter']>; sort: { field: string; dir: 'asc' | 'desc' }[] } = {
  ...DEFAULT_DATA_TABLE_DATA_STATE,
  filter: DEFAULT_FILTER,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: 30,
};

const PATIENT_COLUMNS: PatientColumn[] = [
  {
    cell: TextCell,
    field: 'unosID',
    filter: TextFilter,
    headerCellDescription: 'Patient UNOS ID',
    title: 'Unos ID',
    width: '80px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'caseID',
    filter: TextFilter,
    headerCellDescription: 'Patient Case ID',
    title: 'Case ID',
    width: '80px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'patientNumber',
    filter: TextFilter,
    headerCellDescription: 'Patient Number',
    title: 'MRN',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'lastName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Last Name',
    title: 'Last Name',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'firstName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient First Name',
    title: 'First Name',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: DateCell,
    field: 'dob',
    filter: DateFilter,
    operators: KendoOperators.date,
    format: 'MM/DD/YYYY',
    headerCellDescription: "Patient's Date of Birth",
    title: 'DOB',
    width: '100px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'age',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Age',
    title: 'Age',
    width: '50px',
    columnFilter: 'text',
    filterable: false,
  },
  {
    cell: TextCell,
    field: 'gender',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Gender',
    title: 'Gender',
    width: '50px',
    columnFilter: 'text',
  },
  {
    cell: DateCell,
    field: 'dateCreated',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date Patient Record was Created',
    title: 'Date Created',
    width: '65px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'hospital',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Hospital',
    title: 'Hospital',
    width: '200px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'notes',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Notes',
    title: 'Notes',
    columnFilter: 'text',
  },
];

const getPatientColumns = () => {
  return PATIENT_COLUMNS.filter((column) => !column.hideFromGrid);
};

const getSearchFilter = (searchText: string, locationId: number | null | undefined): CompositeFilterDescriptor => {
  const searchFields = ['patientNumber', 'firstName', 'lastName'];

  const orFilters: CompositeFilterDescriptor = {
    filters: searchFields.map((field) => ({
      field,
      operator: 'contains',
      value: searchText,
    })),
    logic: 'or',
  };

  if (locationId != null) {
    return {
      filters: [
        {
          field: 'Location_Id',
          operator: 'eq',
          value: locationId,
        },
        orFilters,
      ],
      logic: 'and',
    };
  }

  return orFilters;
};

function getSearchFilter2(searchText: string): CompositeFilterDescriptor {
  const searchColumns = PATIENT_COLUMNS.filter(({ search }) => search); // include only columns allowed in omni search
  const textFields = searchColumns.filter(({ filter }) => filter === TextFilter);
  const numericFields = searchColumns.filter(({ filter }) => filter === NumericFilter);

  if (hasOnlyDigits(searchText)) {
    return {
      filters: numericFields.map(({ field }) => ({
        field,
        operator: 'eq',
        value: searchText,
      })),
      logic: 'or',
    };
  }

  return {
    filters: textFields.map(({ field }) => ({
      field,
      operator: 'contains',
      value: searchText,
    })),
    logic: 'or',
  };
}

function getQueryDataState2(dataState: State, searchText: string, requireActive: boolean): State {
  const queryDataState = addFiltersToDataState(dataState, [getSearchFilter2(searchText), { field: 'active', operator: 'eq', value: requireActive }]);

  // In case the query somehow doesn't have paging options, add them.
  if (queryDataState.skip == null) {
    queryDataState.skip = DEFAULT_FILTER_DATA_STATE.skip;
  }
  if (queryDataState.take == null) {
    queryDataState.take = DEFAULT_FILTER_DATA_STATE.take;
  }

  return queryDataState;
}

function initializePatientGridState() {
  const defaultDataState = cloneDeep(DEFAULT_FILTER_DATA_STATE);
  const defaultColumnsState = cloneDeep(DEFAULT_GRID_COLUMNS_STATE);

  return {
    dataState: defaultDataState,
    columnsState: defaultColumnsState,
  };
}

const getDefaultPatientExamDataState = (patientId: number): DataSourceRequestState => {
  const filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
      {
        field: 'patientId',
        operator: 'eq',
        value: patientId as string | number | boolean,
      },
      {
        field: 'active',
        operator: 'eq',
        value: true as string | number | boolean,
      },
    ],
  };

  return {
    filter,
    skip: 0,
    sort: [{ field: 'id', dir: 'desc' }],
    take: 30,
  };
};

const initializePatientGrid = (exams: ExamModel[], isOpo: boolean, patient: PatientModel | undefined | null) => {
  return exams.map((exam) => {
    return {
      ...exam,
      selected: false,
      locationName: `${exam.locationCode}|${exam.locationName}`,
      hasFileAttachments: exam.files?.some((fileItem) => ['PDF', 'PNG', 'JPG'].includes(fileItem.fileType)),

      finalReportFile: exam.files?.find((fileItem: { fileName: string | null }) => fileItem.fileName?.includes('FinalReport')),
      isOpo: isOpo,
      unosID: patient?.unosID ?? undefined,
      caseID: patient?.caseID ?? undefined,
    };
  });
};

export const PatientGridService = {
  getSearchFilter,
  getSearchFilter2,
  getPatientColumns,
  getQueryDataState2,
  getDefaultPatientExamDataState,
  initializePatientGridState,
  initializePatientGrid,
};
