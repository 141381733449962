/** Simple event channel to subscribe to events. */
export type IEventStreamConsumer<T> = {
  readonly subscribe: (callback: (event: T) => void) => () => void;
};

/** Simple event channel that will execute all subscriber callbacks synchronously as soon as emit() is called. */
export class EventStream<TEventType> implements IEventStreamConsumer<TEventType> {
  static [Symbol.toStringTag]() {
    return 'EventStream';
  }

  /** Array of current subscribers to the event channel. */
  private subscribers: ((event: TEventType) => void)[] = [];

  private _debugName: string | null;

  constructor(debugName = null as string | null) {
    this.subscribe = this.subscribe.bind(this);
    this.emit = this.emit.bind(this);
    this.clear = this.clear.bind(this);
    this.emitInternal = this.emitInternal.bind(this);

    this._debugName = debugName;
  }

  /** Subscribe to events.  When events are emitted the subscriber callbacks are executed in order of who subscribed first.
   * @returns Unsubscribe callback.
   */
  public subscribe(callback: (event: TEventType) => void): () => void {
    this.subscribers.push(callback);

    return () => {
      const index = this.subscribers.findIndex((c) => c === callback);

      if (index >= 0) this.subscribers.splice(index, 1);
    };
  }

  /** Emit the event to all subscribed listeners.  When events are emitted the subscriber callbacks are executed in order of who subscribed first. */
  public emit(event: TEventType): void {
    for (const subscriber of this.subscribers) {
      // Intentionally NOT awaiting the subscriber callback.  This is to ensure that: 1) We can continue to execute the next subscriber callback if one fails, and 2) We don't have to silently eat unhandled exceptions (they will appear in the console).
      this.emitInternal(subscriber, event);
    }
  }

  private async emitInternal(subscriber: (event: TEventType) => void, event: TEventType): Promise<unknown> {
    await new Promise<void>((resolve) => resolve());
    return subscriber(event);
  }

  /** Clear the list of subscribers. */
  public clear() {
    this.subscribers = [];
  }
}
