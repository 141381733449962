import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import _map from 'lodash/map';

import { Field, FieldContainer, Form, GridColumn, createStyledFormElement } from 'core/forms';
import { Button, Dropdown, DropdownWithValuesField, Input, Label, Switch } from 'core/ui';

import { IntegrationSelectors } from 'features/integration';
import { useAppSelector } from 'features/main/hooks';

import { DefaultViewerOptions } from '../constants';
import { locationFormValidators as validators } from '../services';
import { IntegrationTabContentProps, IntegrationTabFormValues } from '../types';
import { IntegrationMultiSelect } from './IntegrationMultiSelect';
import { apiClient } from 'features/api';

const StyledFormElement = createStyledFormElement('min-content 400px');

const IntegrationTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof IntegrationTabFormValues) => any;
  onChange: (values: IntegrationTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const integrations = useAppSelector(IntegrationSelectors.getAll);
  const [examMatchServices, setExamMatchServices] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const fetchExamMatchServices = async () => {
      const services = await apiClient.exams.getExamMatchServices();
      setExamMatchServices([{ id: '', name: 'None' }, ...services.map((service) => ({ id: service, name: service }))]);
    };
    fetchExamMatchServices();
  }, []);

  const handleFormChange = useCallback(() => {
    onChange({
      facilityId: valueGetter('facilityId'),
      aeTitle: valueGetter('aeTitle'),
      uploadURL: valueGetter('uploadURL'),
      integrations: valueGetter('integrations'),
      defaultViewer: valueGetter('defaultViewer'),
      autoComplete: valueGetter('autoComplete'),
      customerAlias: valueGetter('customerAlias'),
      examMatchService: valueGetter('examMatchService'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="facilityId" description="Primary mapping key for this location">
          Facility Id
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="facilityId" editorId="facilityId" component={Input} validator={validators.facilityId} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="aeTitle" description="DICOM AE Title">
          AE Title
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="aeTitle" editorId="aeTitle" component={Input} validator={validators.aeTitle} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="customerAlias" description="May be used for mapping to the Institution Name">
          Customer Alias
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="customerAlias" editorId="customerAlias" component={Input} validator={validators.customerAlias} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="uploadURL">Upload URL</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="uploadURL" editorId="uploadURL" component={Input} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="integrations">Integrations</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field component={IntegrationMultiSelect} data={integrations} editorId="integrations" name="integrations" onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="defaultViewer">Default Viewer</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DropdownWithValuesField}
            data={_map(DefaultViewerOptions)}
            dataItemKey="value"
            isForPrimitiveValues
            editorId="defaultViewer"
            name="defaultViewer"
            valueField="value"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label description="Secondary Exam Match Service">Secondary Exam Match Service</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field component={Dropdown} data={examMatchServices} name="examMatchService" dataItemKey="id" textField="name" onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="autoComplete">Auto Complete</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="autoComplete"
            editorId="autoComplete"
            component={Switch}
            hint="Automatically mark exam as complete when a report is received."
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

IntegrationTabContentInner.displayName = 'IntegrationTabContentInner';

export const IntegrationTabContent: FunctionComponent<IntegrationTabContentProps> = ({ initialValues, onSubmit, onChange }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => <IntegrationTabContentInner valueGetter={valueGetter} onChange={onChange} />}
    />
  );
};

IntegrationTabContent.displayName = 'IntegrationTabContent';
