import { memo, useCallback, useRef, useState } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button } from 'core/ui/Button';

import { ActionIconProps } from './ActionIconProps';

export const ActionButton = memo<ActionIconProps>(({ action, dataItem, dataItemIndex, dataItemKey, testingPrefix }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [transientTitle, setTransientTitleInner] = useState<string | null>(null);

  const deferredTooltipEnteredRef = useRef<null | (() => void)>(null);
  const deferredTooltipExitedRef = useRef<null | (() => void)>(null);

  const isOverlayTriggerRendered = transientTitle != null || action.title != null;

  const setTransientTitle = useCallback(
    (newTitle: string) => {
      if (showTooltip) {
        // If the tooltip is already being displayed then we want to animate the tooltip closed with the action.title displayed.  Then we re-open
        // it with the transient title visible.
        setShowTooltip(false);

        deferredTooltipExitedRef.current = () => {
          setShowTooltip(true);
          setTransientTitleInner(newTitle);
        };
      } else {
        // The tooltip is not currently being displayed.  So we can immediately display it.
        setShowTooltip(true);
        setTransientTitleInner(newTitle);
      }
    },
    [showTooltip],
  );

  const handleTooltipToggle = useEvent((nextShow: boolean) => {
    setShowTooltip(nextShow);

    if (!nextShow && transientTitle != null) {
      deferredTooltipEnteredRef.current = null;
      deferredTooltipExitedRef.current = () => {
        setTransientTitleInner(null);
      };
    }
  });

  const handleTooltipEntered = useEvent(() => {
    if (deferredTooltipEnteredRef.current) {
      const enteredHandler = deferredTooltipEnteredRef.current;
      deferredTooltipEnteredRef.current = null;
      enteredHandler();
    }
  });

  const handleTooltipExited = useEvent(() => {
    if (deferredTooltipExitedRef.current) {
      const exitedHandler = deferredTooltipExitedRef.current;
      deferredTooltipExitedRef.current = null;
      exitedHandler();
    }
  });

  const buttonContent = (
    <StyledButton
      id={`${testingPrefix}_${dataItem[dataItemKey]}`}
      className={`${testingPrefix}_${action.key}`}
      data-key={dataItem[dataItemKey]}
      type="button"
      disabled={typeof action.disabled === 'function' ? action.disabled(dataItem) : action.disabled}
      onClick={action.onClick != null ? (event) => action.onClick?.(event, dataItem, dataItemIndex, setTransientTitle) : undefined}
    >
      {action.text}
    </StyledButton>
  );

  return isOverlayTriggerRendered ? (
    <OverlayTrigger
      placement="top"
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-expect-error
      overlay={<Tooltip>{transientTitle ?? action.title}</Tooltip>}
      show={showTooltip}
      onToggle={handleTooltipToggle}
      onEntered={handleTooltipEntered}
      onExited={handleTooltipExited}
      rootClose={transientTitle != null}
      trigger={transientTitle == null ? undefined : 'click'}
    >
      {buttonContent}
    </OverlayTrigger>
  ) : (
    buttonContent
  );
});

ActionButton.displayName = 'ActionButton';

const StyledButton = styled(Button)`
  &&&:hover,
  &&&:focus {
    background-color: ${({ theme }) => theme.colors.palette.blues[0]};
    color: ${({ theme }) => theme.colors.palette.blues[5]};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
