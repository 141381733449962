import { EventStream, IEventStreamConsumer, ParallelQueue } from 'core/utils';

import { apiClient } from 'features/api';

import { FileAttachedEvent, FileAttacherPathologyItem } from '../types';

export class FileAttacherPathology {
  static [Symbol.toStringTag]() {
    return 'FileAttacherPathology';
  }

  private _streams = {
    onFileAttached: new EventStream<FileAttachedEvent>(),
  };

  public get streams(): {
    onFileAttached: IEventStreamConsumer<FileAttachedEvent>;
  } {
    return this._streams;
  }

  private _queue: ParallelQueue<FileAttacherPathologyItem>;

  private _authMode: 'msal-required' | 'share-required' | null = null;

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.attach = this.attach.bind(this);
    this.run = this.run.bind(this);

    this._queue = new ParallelQueue({ run: this.run, maxRunners: 5 });
  }

  public initialize(authMode: 'msal-required' | 'share-required') {
    this._authMode = authMode;
  }

  public destroy() {
    this._authMode = null;
    this._queue.destroy();
    this._streams.onFileAttached.clear();
  }

  public attach(attachRequest: FileAttacherPathologyItem) {
    console.log('attach', attachRequest);
    this._queue.enqueue(attachRequest);
    this._queue.run();
  }

  private async run(request: FileAttacherPathologyItem) {
    if (this._authMode == null) throw new Error('Cannot attach pathology files without an authentication mode configured.');

    await apiClient.filesClient.completePathologyUpload(
      {
        examId: request.examId,
        fileName: request.fileName,
        serial: request.serial,
        originalFileName: request.originalFileName,
      },
      this._authMode,
    );

    this._streams.onFileAttached.emit({ fileId: request.fileId });
  }
}
